import React, { Component } from "react";

import { getUserId, login, redirectTo } from "js/base.js";
import { validUserTel, validUserEmail } from "js/utils.js";
import { getSet } from "locales/index.js";
import "css/login.css";
import background from "img/login_bg.webp";


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langPage: getSet("login"),
      langCommon: getSet("common"),
      onSubmit: false,
      telOrEmail: "", userColor: "", errUser: "",
      password: "", passwordColor: "", errPassword: "",
    }
  };

  // style: no-repeat center center fixed
  componentDidMount() {
    if (getUserId()) {
      redirectTo("/manage");
      return;
    }
    let langPage = this.state.langPage;
    document.title = langPage.title;
    document.body.style.background = "url(" + background + ") no-repeat center center fixed";
    document.body.style.backgroundSize = "cover";
  }

  submit() {
    let langPage = this.state.langPage;
    if (this.state.onSubmit) {
      return;
    }

    this.setState({"onSubmit": true});
    let data = {};

    let ok = true;
    if(validUserTel(this.state.telOrEmail)) { // this.state.tel.match(/^\d{11}$/)
      this.setState({userColor: "green", errUser: "", });
      data.tel = this.state.telOrEmail;
    } else if (validUserEmail(this.state.telOrEmail)) {
      this.setState({userColor: "green", errUser: "", });
      data.email = this.state.telOrEmail;
    }else {
      if (ok) {
        this.setState({userColor: "red", errUser: langPage["errPhoneOrEmail"]});
      }
      ok = false;
    }

    if(this.state.password.length >= 6) {
      this.setState({passwordColor: "green", errPassword: "", })
    } else {
      if (ok) {
        this.setState({passwordColor: "red", errPassword: langPage["errPassword"]});
      }
      ok = false;
    }

    if (!ok) {
      this.setState({"onSubmit": false});
      return;
    }

    data.password = this.state.password;

    login(data, res => {
      this.setState({"onSubmit": false});
      // return;
      let redirect = false;
      // console.log(`~~~ 1 ${document.referrer}, ${window.location.host}`);
      if (document.referrer) {
        let url = new URL(document.referrer);
        // console.log(`~~~ 2 ${url.host} ${window.location.host}`)
        if (url.host === window.location.host) {
          redirect = true;
        }
      }

      if (redirect) {
        window.location.href = document.referrer;
        return;
      }
      redirectTo("/manage"); // 进入管理页面
    }, ()=> this.setState({"onSubmit": false}));
  }

  updatePasword(event) { // hidden password from devtools inspect, <input> has no value attribute
    // let value = event.target.value;
    // let idx = event.target.selectionStart;
    //~~ you can forbid using browser remembered password or paste password by checking
    // this.password === "" && value.length > 1
    this.setState({password: event.target.value});
  }

  // align-items-center
  render() {
    let langPage = this.state.langPage;

    return(
      <div className="container centered">
        <div className="row justify-content-center">
          <div className="col-md-4 login-box justify-content-center">
            <div> {langPage.subtitle} </div>

            <input type="text" placeholder={langPage.enter}
              className="form-control login-input"
              style={{borderColor:this.state.userColor}} maxLength="128"
              name="telOrEmail" value={this.state.telOrEmail}
              onChange={ e => {
                this.setState({telOrEmail: e.target.value});
                this.value = e.target.value;
              }}
            />
            <small className="login-small">{this.state.errUser}</small>

            <input type="password" placeholder={langPage.password}
              className="form-control login-input"
              style={{borderColor:this.state.passwordColor}} maxLength="20"
              onChange={this.updatePasword.bind(this)}
            />
            <small className="login-small">{this.state.errPassword}</small>
            {' '}
            <button className="btn btn-primary" onClick={this.submit.bind(this)}>
              {langPage.login}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Login;
