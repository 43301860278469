let records = {
  sideNav: "组合管理",
  tableTitle: "组合列表",
  composeName: "组合名称",
  tipDelete: "删除组合 %1$s?",
  tipAlterStatus: "更改组合 %1$s 状态为 %2$s?",
  editCompose: "编辑组合",
};

export default records;
