import { message } from "antd";

import { httpGET, httpPOST } from "./base.js";
// import { time2DateStr } from "./utils.js";


const RoutGroup = "/api/web/v1/auth/admin/company";

export function newAccount(data, callback, onError) {
  httpPOST(`${RoutGroup}/acount/new_user`, data,
    res => {
      if (res.code > 0) message.error("创建用户失败");
      callback(res); //!!! 仍然进行 callback
    },
    onError,
  );
}

export function queryUser(data, callback) {
  let query = {...data};
  // if (query.startDate) query.startDate = time2DateStr(query.startDate);
  // if (query.endDate) query.endDate = time2DateStr(query.endDate);

  httpGET(`${RoutGroup}/account/query_user`, query,
    res => { // callback
      if (res.code === 0) {
        callback(res);
      } else if (res.code > 0) {
        message.error("查询用户失败！");
      }
    }
  );
}

export function deleteUser(id, callback) {
  httpPOST(`${RoutGroup}/account/delete_user?id=${id}`, null,
    res => {
      if (res.code === 0) {
        callback(res);
      } else if (res.code > 0) {
        message.error("删除用户失败！");
      }
    }
  );
}

export function updateUser(data, callback, onError) {
  httpPOST(`${RoutGroup}/account/update_user?id=${data.id}`, data,
    res => {
      if (res.code === 0) {
        callback(res);
      } else if (res.code > 0) {
        message.error("修改用户失败！");
      }
    },
    onError,
  );
}

export function browseNodeInfoQuery(callback) {
  httpGET(`${RoutGroup}/browse_node/info_query`, null,
    res => { // callback
      if (res.code === 0) {
        callback(res);
      } else if (res > 0) {
        message.error("获取模型类型失败！");
      }
    }
  );
}

export function companyAssetQuery(data, callback) {
  httpGET(`${RoutGroup}/asset/query`, data,
    res => {
      if (res.code === 0) {
        callback(res);
      } else {
        message.error("查询模型失败！");
      }
    }
  );
}

export function companyAssetUpdateStatus(data, callback) {
  httpPOST(`${RoutGroup}/asset/update_status?asset_id=${data.asset_id}&status=${data.status}`,
    null, res => callback(res),
  );
}

export function companyAssetUpdate(data, callback) {
  httpPOST(`${RoutGroup}/asset/update?assetId=${data.assetId}`,
    data, res => callback(res),
  );
}

export function designQuery(data, callback) {
  httpGET(`${RoutGroup}/design/query`, data,
    res => {
      if (res.code === 0) {
        callback(res);
      } else if (res.code > 0) {
        message.error("查询项目失败！");
      }
    }
  );
}

export function designCreate(data, callback, onError) {
  httpPOST(`${RoutGroup}/design/create`, data,
    res => {
      if (res.code === 0) {
        callback(res);
      } else if (res.code > 0) {
        message.error("创建项目失败！");
      }
    },
    onError,
  );
}

export function designUpdate(data, callback, onError) {
  httpPOST(`${RoutGroup}/design/update`, data,
    res => {
      if (res.code === 0) {
        callback(res);
      } else if (res.code > 0) {
        message.error("更新项目失败！");
      }
    },
    onError,
  );
}

export function designUpdateStatus(data, callback) {
  httpPOST(`${RoutGroup}/design/update_status?id=${data.id}&status=${data.status}`, null,
    res => callback(res),
  );
}

export function queryEventLog(data, callback) {
  httpGET(`${RoutGroup}/event_log/query`, data,
    res => callback(res),
  );
}

export function companyCommodityQuery(data, callback) {
  httpGET(`${RoutGroup}/commodity/query`, data,
    res => {
      if (res.code === 0) {
        callback(res);
      } else {
        message.error("查询商品失败！");
      }
    }
  );
}

export function companyCommodityNew(data, callback) {
  httpPOST(`${RoutGroup}/commodity/create`, data,
    res => {
      if (res.code >= 0) {
        callback(res);
      }
    }
  );
}

export function companyCommodityUpdate(data, callback) {
  httpPOST(`${RoutGroup}/commodity/update`, data,
    res => {
      if (res.code >= 0) {
        callback(res);
      }
    }
  );
}

export function companyCommodityDelete(data, callback) {
  httpPOST(`${RoutGroup}/commodity/delete`, data,
    res => {
      if (res.code >= 0) {
        callback(res);
      }
    }
  );
}

// company asset compoase
export function composeUpdate(data, callback, onError) {
  httpPOST(`${RoutGroup}/asset_compose/update`,
    data, res => { callback(res); }, onError
  )
}

export function composeUpdateStatus(data, callback, onError) {
  httpPOST(`${RoutGroup}/asset_compose/update_status?id=${data.id}&status=${data.status}`,
    data, res => { callback(res); }, onError
  )
}

export function composeQuery(data, callback, onError) {
  httpGET(`${RoutGroup}/asset_compose/query`,
    data, res => { callback(res); }, onError
  )
}
