import accessLogs from "./access_logs.js";
import accounts from './accounts.js';
import assetComposes from "./asset_composes.js";
import assets from "./assets.js";
import commodities from "./commodities.js";
import common from './common.js';
import login from './login.js';
import designs from "./designs.js";
import myProjects from "./my_projects.js";
import myComposes from "./my_composes.js";
import userAssets from "./user_assets.js";

let data = {
  accessLogs: accessLogs,
  accounts: accounts,
  assetComposes: assetComposes,
  assets: assets,
  commodities: commodities,
  common: common,
  login: login,
  designs: designs,
  myProjects: {...designs, ...myProjects},
  myComposes: myComposes,
  userAssets: userAssets,
}

export default data;
