let records = {
  sideNav: "商品维护",
  tableTitle: "商品列表",
  tipHasNoAreas: "%1$s 没有定义材质区域",
  tipHasNoMaterials: "%1$s 没有定义可替换材质",
  "软装": "软装",
  "主材": "主材",
  tipProductExists: "商品已经存在",
  relatedProduct: "关联商品",
  tipDelete: "确认删除商品: %1$s?",
  brand: "品牌",
  style: "风格",
  origin: "产地",
  price: "价格",
  currency: "币种",
  model: "型号",
  mainMaterial: "主体材质",
  unit: "单位",
  lossPerc: "损耗率(%)",
  purchaseURL: "购买连接",
  materialAreas: "材质区域",
  tipMaterialReplace: "材质替换项 (选择不同区域的材质 ID)",
  tipProductInfo: "模型商品信息 (信息用于在软件前端、报价单中展示)",
  unitsMap: {"个":"个", "棵":"棵", "片":"片", "组": "组", "m":"m", "㎡":"㎡", "㎥":"㎥"},
  editProduct: "编辑商品",
};

export default records;
