import React, { Component } from "react";
import { Modal, message } from "antd";
import { sprintf } from "sprintf-js";

import Edit from "./edit.jsx";
import Push2Company from "./push2company.jsx";
import { composeUpdateStatus } from "js/api4user.js";
import { toLocalDatetime } from "js/utils.js";
import { getSet } from "locales/index.js";
import NoThumbnail from "img/no_thumbnail.png";


class Table extends Component {
  constructor (props) {
    super(props); // items

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("myComposes"),
      isModalVisible: false,
      confirmMsg: "",
      confirmDo: null,
    };
  }

  updateStatus(item, status) {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    let confirmMsg = "";

    if (status === "deleted") {
      if (item.status === "deleted") {
        return;
      }
      confirmMsg = sprintf(langPage.tipDelete, item.name);
    } else if (item.status === "deleted") {
      status = "on";
      confirmMsg = sprintf(langPage.tipAlterStatus, item.name, langCommon.status[status]);
    } else if (item.status === "on") {
      status = "off";
      confirmMsg = sprintf(langPage.tipAlterStatus, item.name, langCommon.status[status]);
    } else if (item.status === "off") {
      status = "on";
      confirmMsg = sprintf(langPage.tipAlterStatus, item.name, langCommon.status[status]);
    } else {
      console.log(`??? updateStatus(${item.id}, ${item.name}, ${status})`);
      return;
    }

    let data = {"id": item.id, status: status};

    this.setState({
      isModalVisible: true,
      confirmTitle: langCommon.fields.warning,
      confirmMsg: confirmMsg,
      confirmDo: () => composeUpdateStatus(data, res => {
        this.setState({isModalVisible: false});
        if (res.code === 0) {
          this.props.updateItem(data);
        } else {
          message.error(res.message);
        }
      })
    });
  }

  renderRows() {
    let langCommon = this.state.langCommon;

    return this.props.items.map((item, idx) => {
      // let className = "btn btn-sm query-button btn-success";
      // if (item.status === "下架") className = "btn btn-sm query-button btn-secondary";
      return (
        <tr key={item.id} id={item.id} data-index={idx} name={item.name}>
          <td title={item.id}> {item.name} </td>
          <td> <img alt={langCommon.fields["thumbnail"]} src={item.thumbnail || NoThumbnail } /> </td>
          <td> {toLocalDatetime(item.createTime)} </td>
          <td> {toLocalDatetime(item.updateTime)} </td>

          <td>
            <span className="span-button"
              onClick={() => this.updateStatus(item)}
            >
              {langCommon.status[item.status]}
            </span>
          </td>

          <td>
            <Edit
              item={item}
              updateItem={this.props.updateItem}
            />

            <span className="span-seperator"> | </span>

            <span className="span-button"
              onClick={() => this.updateStatus(item, "deleted")}
            >
              {langCommon.actions["delete"]}
            </span>

            <span className="span-seperator"> | </span>

            <Push2Company
              item={item}
              composeTypeList={this.props.composeTypeList}
            />
          </td>
        </tr>
      )
    })
  }

  render() {
    let langCommon = this.state.langCommon;

    return (<>
      <Modal title={this.state.confirmTitle} visible={this.state.isModalVisible}
        onOk={() => this.state.confirmDo() }
        onCancel={() => this.setState({isModalVisible: false})}
        okText={langCommon.actions["confirm"]}
        cancelText={langCommon.actions["cancel"]}
      >
        <p style={{paddingLeft: "24px"}} textIndent="30px">{this.state.confirmMsg}</p>
      </Modal>

      <table className="table-content">
        <thead>
          <tr>
            <th style={{cursor:'pointer'}}> {langCommon.fields["name"]} </th>
            <th>{langCommon.fields["thumbnail"]}</th>
            <th style={{cursor:'pointer'}}> {langCommon.fields["createdAt"]} </th>
            <th style={{cursor:'pointer'}}> {langCommon.fields["updatedAt"]} </th>
            <th>{langCommon.fields["status"]}</th>
            <th>{langCommon.fields["action"]}</th>
          </tr>
        </thead>

        <tbody>
          {this.renderRows()}
        </tbody>
      </table>
    </>)
  }
}

export default Table;
