let records = {
  title: "Login - MetaEngine Management Console",
  subtitle: "MetaEngine Management Console",
  enter: "phone number or email address",
  password: "password",
  login: "Login",
  errPhoneOrEmail: "wrong phone numer or email address",
  errPassword: "wrong password",
};

export default records;
