import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
// import { LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';

import { getSet } from "locales/index.js";
import { getUserName, getCompanyId, logout, reLogin } from "js/base.js";
import { validPassword } from "js/utils.js";
import { userResetPassword } from "js/api4user.js";
import LogIcon from "img/logo@2x.png";
import UserIcon from "img/user_white@2x.png";
import LanguageIcon from "img/language.png";
import 'css/head.css';


class Head extends Component {
  constructor(props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      username: getUserName(),

      onSubmmit: false,
      showResetPassword: false,
      oldPassword: "",
      newPassword: "",
      cPassword: "",
      oldColor: "black",
      newColor: "black",
      cColor: "black",
      errMsg: "",

      languagesVisible: false,
    };
  }

  handleClick = (value) => {
    // console.log(value);
    switch (value) {
    case 'logoff':
      // if (window.confirm("确定退出?")) logout();
      // this.setState({showLogoutPrompt: true});
      logout();
      break;
    case "reset_password":
      this.setState({showResetPassword: true});
      break;
    default:
      // console.log(`handleChange do nothing`);
    }
  }

  clearInput = () => {
    this.setState({
      onSubmmit: false, showResetPassword:false,
      oldPassword:"", newPassword:"", cPassword:"",
      oldColor:"black", newColor:"black", cColor:"black", errMsg:"",
    });
  }

  handleSubmmit() {
    let langCommon = this.state.langCommon;
    if (this.state.onSubmmit) {
      return;
    }
    this.setState({onSubmmit: true});

    let ok = true;
    let oldPassword = this.state.oldPassword;
    let newPassword = this.state.newPassword;
    let cPassword = this.state.cPassword;

    if (!validPassword(oldPassword)) {
      if (ok) this.setState({errMsg: langCommon.tips["invalidOldPassword"]});
      this.setState({oldColor: "red"});
      ok = false;
    }

    if(!validPassword(newPassword)) {
      if (ok) this.setState({errMsg: langCommon.tips["validPassword"]});
      this.setState({newColor: "red"});
      ok = false;
    } else {
      this.setState({newColor: "black"});
      if(newPassword !== cPassword) {
        if (ok) this.setState({errMsg: langCommon.tips["passwordNotMatch"]});
        this.setState({cColor: "red"});
        ok = false;
      }
    }

    if (!ok) {
      this.setState({onSubmmit: false});
      return;
    }

    userResetPassword({oldPassword: oldPassword, newPassword:newPassword},
      res => {
        this.setState({onSubmmit: false});
        if (res.code === 0) {
          reLogin(langCommon.tips["relogin"]);
          return;
        }
      },
      () => this.setState({onSubmmit: false}),
    )
  }
  
  /*
            <p value="feedback" onClick={console.log("feedback unimplement!")}>
              <span>反馈意见</span>
            </p>
  */

  renderResetPassword() {
    let langCommon = this.state.langCommon;

    return (<Modal
      show={this.state.showResetPassword}
      onHide={this.clearInput}
      backdrop="static"
      keyboard={true}
      dialogClassName="modal-dialog-centered"
      size=""
    >
      <Modal.Header closeButton>
        <Modal.Title>{langCommon.actions["changePassword"]}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <table className="table-input">
          <tbody>
            <tr>
              <td>
                <span style={{color: 'red'}}> * </span>
                <span style={{color: this.state.oldColor}}>
                  {langCommon.fields["oldPassword"]}:
                </span>
              </td>
              <td>
                <input name="oldPassword" type="password" maxLength="20"
                  placeholder={langCommon.placeholder["enter"]}
                  className="modal-input"
                  value={this.state.name}
                  onChange={e => {
                    this.setState({oldPassword: e.target.value});
                    this.value = e.target.value;
                  }}
                />
              </td>
            </tr>

            <tr title={langCommon.tips["validPassword"]}>
              <td>
                <span style={{color: 'red'}}> * </span>
                <span style={{color: this.state.newColor}}>
                  {langCommon.fields["newPassword"]}:
                </span>
              </td>
              <td>
                <input name="newPassword" type="password" maxLength="20"
                  placeholder={langCommon.placeholder["enter"]}
                  className="modal-input"
                  value={this.state.name}
                  onChange={e => {
                    this.setState({newPassword: e.target.value});
                    this.value = e.target.value;
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>
                <span style={{color: 'red'}}> * </span>
                <span style={{color: this.state.cColor}}>
                  {langCommon.fields["passwordConfirmation"]}:
                </span>
              </td>
              <td>
                <input name="cPassword" type="password" maxLength="20"
                  placeholder={langCommon.placeholder["enter"]}
                  className="modal-input"
                  value={this.state.name}
                  onChange={e => {
                    this.setState({cPassword: e.target.value});
                    this.value = e.target.value;
                  }}
                />
              </td>
            </tr>
          </tbody>

          <tfoot className="modal-tfoot">
            <tr> <td colSpan="2"> {this.state.errMsg} </td> </tr>
          </tfoot>
        </table>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-outline-secondary btn-sm button-blank"
          onClick={this.clearInput}
        > {langCommon.actions["cancel"]}
        </button>

        <button className="btn btn-primary btn-sm btn-blue button-blue"
          onClick={this.handleSubmmit}
        > {langCommon.actions["confirm"]}
        </button>
      </Modal.Footer>
    </Modal>)
  }

  setLanguageVisible = (e) => {
    this.setState({languagesVisible: e});
  }

  handleLanguageClick = (e) => {
    let lang = localStorage.getItem("Language");;
    if (lang === e.key) {
      return;
    }
    localStorage.setItem("Language", e.key);
    this.setState({languagesVisible: false});
    window.location.reload(false);
  }

  render () {
     let lang = this.state.langCommon;
   // <select disabled selected> </select>

    const menu = (
      <Menu
        onClick={this.handleLanguageClick}
        items={[
          {label: "English", key: "en-US"},
          {label: "简体中文", key: "zh-CN"},
          {label: "日本語", key: "ja"},
        ]}
      />
    );

    return (
      <div className="d-flex pageHead" style={{zIndex: "1000"}}>
        <div className="mr-auto p-1" style={{fontSize: '16px', fontWeight: 'bold'}}>
          <img alt="logo" style={{width: '40px', marginRight: '8px', marginBottom: '2px'}}
            src={LogIcon}
          />

          {lang.subtitle}
        </div>

        <div>
          <Dropdown overlay={menu} onVisibleChange={this.setLanguageVisible}
            visible={this.state.languagesVisible}
          >
            <a href="aaaa" onClick={(e) => e.preventDefault()}>
              <Space>
                <img alt="logo" style={{width: '20px', marginRight: '8px', marginBottom: '2px'}}
                  src={LanguageIcon}
                />
              </Space>
            </a>
          </Dropdown>

          <div className="dropdown ml-auto p-1" style={{marginRight:'0px'}}>
            <img alt="icon" style={{width:'16px', marginBottom:'2px'}}
              title={getCompanyId()} src={UserIcon}
            />
            <span style={{marginLeft: "8px", fontSize: '16px'}}> {this.state.username} </span>

            <div className="dropdown-content">
              <p value="reset_password" onClick={() => this.handleClick("reset_password")}>
                <span>{lang.changePassword}</span>
              </p>

              <hr style={{margin: "0px 0px 5px 0px", borderColor: "white"}}></hr>
              <p value="logoff" onClick={() => this.handleClick("logoff")}>
                <span>{lang.logoff}</span>
              </p>
            </div>
          </div>

          {this.renderResetPassword()}
        </div>
      </div>
    )
  }
}

export default Head;
