let records = {
  subtitle: "引擎运维管理",
  changePassword: "修改密码",
  logoff: "退出登录",

  actions: {
    confirm: "确认",
    ok: "确认",
    cancel: "取消",
    reset: "重置",
    query: "查询",
    search: "查询",
    create: "创建",
    edit: "编辑",
    delete: "删除",
    pleaseSet: "请设置 %1$s",
    import: "导入",
    browse: "浏览",
    changePassword: "修改密码",
  },

  placeholder: {
    enter: "请输入",
    select: "请选择",
    chars: "%1$s 字符",
    add: "添加",
  },

  status: {
    "on": "上架",
    "off": "下架",
    "deleted": "删除",
    "上架": "上架",
    "下架": "下架",
    "待打包": "待打包",
    "预发布": "预发布",
  },

  fields: {
    beginning: "开始时间",
    end: "结束时间",
    username: "用户名",
    name: "名称",
    email: "电子邮箱",
    phoneOrEmail: "手机号码或邮箱",
    phoneNumberOrEmailAddress: "手机号码或邮箱",
    phoneNumber: "手机号码",
    emailAddress: "电子邮箱",
    password: "密码",
    role: "角色",
    creator: "创建者",
    status: "状态",
    createdAt: "创建时间",
    updatedAt: "更新时间",
    thumbnail: "缩略图",
    action: "操作",
    tips: "提示",
    warning: "警告",
    preview: "预览",
    clickToUpload: "点击上传",
    type: "类型",
    kind: "类别",
    filepath: "文件路径",
    description: "详情",
    oldPassword: "旧密码",
    newPassword: "新密码",
    passwordConfirmation: "确认密码",
    browse: "导航类目",
    category: "分类",
    assetId: "模型 ID",
    attribute: "属性",
  },

  page: {
    items: "第 %1$s-%2$s 条",
    total: "共 %1$s 条",
    pageSize: "条/页",
  },

  assetKinds: {
    SM: "静态网格体 (SM)",
    M: "材质 (M)",
    BSM: "蓝图模型 (BSM)",
    ESM: "粒子特效 (ESM)",
    ASM: "动画模型 (ASM)",
    PL: "点光源 (PL)",
    SL: "射灯 (SL)",
    RL: "面光源 (RL)",
    RC: "反射球 (RC)"
  },

  tips: {
    errGetSts: "获取 STS token 失败",
    enterName: "请输入名称",
    invalidName: "名称不合法",
    failedToUpload: "上传文件失败",
    failedToUpdate: "更新失败",
    failedToCreate: "创建失败",
    failedToChangeStatus: "修改状态失败",
    failedToDelete: "删除失败",
    selectFile: "请选择文件",
    fileSizeLargerThan: "文件大小不能超过 %1$s",
    emptyFile: "空文件",
    invalidFile: "文件不合法",
    missingField: "没有字段 %1$s",
    notNumber: "%1$s 不是数字",
    passwordNotMatch: "密码不匹配",
    invalidOldPassword: "旧密码不合法",
    validPassword: "密码只能包含 0-9, a-z, A-Z, 长度范围是 6-20",
    relogin: "请重新登录",
    currentLoggedIn: "当前登录用户是 %1$s, 3s 后重新登录...",
    separatedByComma: "逗号分隔",
    noOperationPermisson: "没有操作权限",
  }
};

export default records;
