let records = {
  sideNav: "Enterprise Projects",
  tableTitle: "Projects",
  tipDelete: "Are you sure you want to permanently delete this project: %1$s?",
  tipChangeStatus: "Are you sure you want to change the status of %1$s to %2$s?",
  tipEnterDescription: "please enter description",
  tipDescriptionLimit: "description length exceeds 255",
  tipGisLimit: "gis length exceeds 1024",
  tipInvalidGis: "gis must be json",
  editProject: "Edit project",
  gisData: "Gis data",
  tipEnterName: "please enter project name",
  tipNameLimit: "gis length exceeds 64",
  tipInvalidPakPath: "invalid pakPath",
  createProject: "Create project",
};

export default records;
