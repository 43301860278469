import { message } from "antd";

import { httpGET, httpPOST } from "./base.js";


const RoutGroup = "/api/web/v1/auth/user";

export function routGroup() {
	return RoutGroup;
};

// user
export function userResetPassword(data, callback, onError) {
  httpPOST(`${RoutGroup}/reset_password`,
    data, res => { callback(res); }, onError
  )
}

// user asset
export function userAssetQuery(data, callback) {
  httpGET(`${RoutGroup}/asset/query`, data, res => {
    if (res.code === 0) {
      callback(res);
    } else {
      message.error("获取 assets 失败！");
    }
  })
}

export function userAssetUpdateStatus(data, callback) {
   httpPOST(`${RoutGroup}/asset/update_status?id=${data.id}&status=${data.status}`,
     null, res => { callback(res); },
   )
}

export function userAssetCreate(data, callback, onError) {
  httpPOST(`${RoutGroup}/asset/create`,
    data, res => { callback(res); }, onError
  )
}

export function userAssetUpdate(data, callback, onError) {
  httpPOST(`${RoutGroup}/asset/update`,
    data, res => { callback(res); }, onError
  )
}

// user asset compoase
export function composeCreate(data, callback, onError) {
  httpPOST(`${RoutGroup}/asset_compose/create`,
    data, res => { callback(res); }, onError
  )
}

export function composeUpdate(data, callback, onError) {
  httpPOST(`${RoutGroup}/asset_compose/update`,
    data, res => { callback(res); }, onError
  )
}

export function composeUpdateStatus(data, callback, onError) {
  httpPOST(`${RoutGroup}/asset_compose/update_status?id=${data.id}&status=${data.status}`,
    data, res => { callback(res); }, onError
  )
}

export function composeQuery(data, callback, onError) {
  httpGET(`${RoutGroup}/asset_compose/query`,
    data, res => { callback(res); }, onError
  )
}

export function composePush2company(data, callback, onError) {
  let id = data.id;
  delete data.id;
  httpPOST(`${RoutGroup}/asset_compose/push2company?id=${id}`,
    data, res => { callback(res); }, onError
  )
}

export function composeTypeList(data, callback, onError) {
  httpGET(`${RoutGroup}/asset_compose/type_list`,
    data, res => { callback(res); }, onError
  )
}

export function designQuery(data, callback, onError) {
  httpGET(`${RoutGroup}/design/query`, data, res => {
    if (res.code !== 0) {
      return;
    }
    callback(res);
  }, onError);
}

export function designUpdate(data, callback, onError) {
  httpPOST(`${RoutGroup}/design/update`, data, res => {
    if (res.code !== 0) {
      return
    }
    callback(res);
  }, onError);
}
