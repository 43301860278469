let records = {
  subtitle: "MetaEngine Management Console",
  changePassword: "change password",
  logoff: "logout",

  actions: {
    confirm: "Confirm",
    ok: "Ok",
    cancel: "Cancel",
    reset: "Reset",
    query: "Query",
    search: "Search",
    create: "Create",
    edit: "Edit",
    delete: "Delete",
    pleaseSet: "Please set %1$s",
    import: "Import",
    browse: "Browse",
    changePassword: "Change password",
  },

  placeholder: {
    enter: "please enter",
    select: "please select",
    chars: "%1$s characters",
    add: "add",
  },

  status: {
    "on": "available",
    "off": "unavailable",
    "deleted": "deleted",
    "上架": "available",
    "下架": "unavailable",
    "待打包": "to be packaged",
    "预发布": "pre-release",
  },

  fields: {
    beginning: "Beginning",
    end: "End",
    username: "Username",
    name: "Name",
    email: "Email",
    phoneOrEmail: "Phone or email",
    phoneNumberOrEmailAddress: "Phone number or email address",
    phoneNumber: "Phone number",
    emailAddress: "Email address",
    password: "Password",
    role: "Role",
    creator: "Creator",
    status: "Status",
    createdAt: "Created",
    updatedAt: "Updated",
    thumbnail: "Thumbnail",
    action: "Action",
    tips: "Tips",
    warning: "Warning",
    preview: "Preview",
    clickToUpload: "Click to upload",
    type: "Type",
    kind: "Kind",
    filepath: "filepath",
    description: "Description",
    oldPassword: "Old password",
    newPassword: "New password",
    passwordConfirmation: "Password confirmation",
    browse: "Browse",
    category: "Category",
    assetId: "Asset ID",
    attribute: "Attribute",
  },

  page: {
    items: "entries %1$s-%2$s",
    total: "%1$s entries",
    pageSize: "entries/page"
  },

  assetKinds: {
    SM: "Static Mesh (SM)",
    M: "Material (M)",
    BSM: "Blueprint (BSM)",
    ESM: "Particles (ESM)",
    ASM: "Animation (ASM)",
    PL: "Point Light (PL)",
    SL: "Spot Light (SL)",
    RL: "Rect Light (RL)",
    RC: "Reflection Capture (RC)"
  },

  tips: {
    errGetSts: "failed to get STS token",
    enterName: "please enter name",
    invalidName: "invalid name",
    failedToUpload: "failed to upload file",
    failedToUpdate: "failed to update",
    failedToCreate: "failed to create",
    failedToChangeStatus: "failed to change status",
    failedToDelete: "failed to delete",
    selectFile: "please select a file",
    fileSizeLargerThan: "file size is larger than %1$s",
    emptyFile: "empty file",
    invalidFile: "invalid file",
    missingField: "missing field %1$s",
    notNumber: "%1$s is't a number",
    passwordNotMatch: "password don't match",
    invalidOldPassword: "invalid old password",
    validPassword: "password character types: 0-9, a-z, A-Z, length range 6-20",
    relogin: "please relogin",
    currentLoggedIn: "Current logged in user is %1$s, relogin in 3s...",
    separatedByComma: "separated by comma",
    noOperationPermisson: "no operation permisson",
  }
};

export default records;
