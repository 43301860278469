let records = {
  sideNav: "我的组合",
  tableTitle: "组合列表",
  tipDelete: "删除组合 %1$s?",
  tipAlterStatus: "更改组合 %1$s 状态为 %2$s?",
  editCompose: "编辑组合",
  push2company: "发布至企业库",
};

export default records;
