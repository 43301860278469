import React, { Component } from "react";


class Monitor extends Component {
  constructor (props) {
    super(props);

    this.state = {
      url: "http://47.117.115.165:9090/status",
    };
  }

  componentDidMount() {
    document.title = "实时监控 - 数字运维管理后台";
  }

  enterURL() {
    let frame = document.getElementById("monitorFrame");
    if (!frame) {
      return; // http://47.117.115.165:9090/status
    }

    if (!this.state.url.startsWith("http://") && !this.state.url.startsWith("https://")) {
      this.setState({url: "http://" + this.state.url});
    }
    frame.src = this.state.url;
  } 

  render() {
    return (<>
      <div className='user-query'>
        <div className="input-parameter">
          <span> 服务器链接: </span>
          <input style={{width: '272px'}} type="text" value={this.state.url}
             onChange={(e) => {
              this.setState({url: e.target.value});
              this.value = e.target.value;
            }}
          />
        </div>

        <div>
          <button
            className="btn btn-primary btn-sm query-button button-blue button-lm0"
            onClick={this.enterURL.bind(this)}> 确定
          </button>
        </div>
      </div>

      <div className="content-area" style={{height: '80.5vh'}}>
        <iframe id="monitorFrame" title="监控页面"
          style={{display:'block', width:'100%', height:'100%', border:'none'}}
          src="">
        </iframe>
      </div>
    </>);
  }
}

export default Monitor
