let records = {
  sideNav: "プロジェクト管理",
  tableTitle: "プロジェクトリスト",
  tipDelete: "本当にアイテム %1$s を削除しますか？",
  tipChangeStatus: "%1$s ステータスが %2$s に変更しますか？",
  tipEnterDescription: "詳細を入力ください",
  tipDescriptionLimit: "プロジェクトの説明長さは255まで超えることはできません",
  tipGisLimit: "gisの長さは1024まで超えることできない",
  tipInvalidGis: "gisデータは正しいJsonではない",
  editProject: "プロジェクト編集",
  gisData: "Gisデータ",
  tipEnterName: "プロジェクト名前を入力ください",
  tipNameLimit: "プロジェクトの名前は64文字を超えることできない",
  tipInvalidPakPath: "pakpath 正しいではない",
  createProject: "新規作成",
};

export default records;
