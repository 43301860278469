import { arrsEquals } from "./utils.js";

export function processAttrs(data) {
  let assetKinds = [];
  let browseNodes = [], subbrowseMap = {};
  let categories = [];
  let commodityCategories = [], commodityCategoryMap = {};

  data.assetKinds.forEach(e => {
    assetKinds.push({value: e.code, label: e.name + " " + e.code});
  });

  data.browseNodes.forEach(e => {
   let node = browseNodes.find(x => x.value === e.browseId);

    if (!node) {
      node = {
        value: e.browseId, label: e.browseName,
        icon: e.icon, libraryType: e.libraryType,
        children: [{value: e.subbrowseId, label: e.subbrowseName }],
      };
      browseNodes.push(node);
    } else {
      node.children.push({value: e.subbrowseId, label: e.subbrowseName });
    }

    subbrowseMap[e.subbrowseId] = e;
  });

  data.categories.forEach(e => {
    let node = categories.find(x => x.value === e.categoryId);

    if (!node) {
      node = {
        value: e.categoryId, label: e.categoryName,
        children: [{value: e.subcategoryId, label: e.subcategoryName }],
      };
      categories.push(node);
    } else {
      node.children.push({value: e.subcategoryId, label: e.subcategoryName});
    }
  });

  if (!data.commodityCategories) {
    data.commodityCategories = [];
  }

  data.commodityCategories.forEach(e => {
    let node = commodityCategories.find(x => x.value === e.categoryId);

    if (!node) {
      node = {
        value: e.categoryId, label: e.categoryName,
        children: [{value: e.subcategoryId, label: e.subcategoryName }],
      };
      commodityCategories.push(node);
    } else {
      node.children.push({value: e.subcategoryId, label: e.subcategoryName });
    }

    commodityCategoryMap[e.subcategoryId] = e;
  });

  return {
    browseNodes, subbrowseMap, categories, assetKinds,
    commodityCategories, commodityCategoryMap,
  }
}

export function commodityValid(commodity) {
  let keys = Object.keys(commodity);

  for (let i in keys) {
    let k = keys[i];
    if (["thumbnail"].indexOf(k) > -1) {
      continue;
    }

    if (typeof(commodity[k]) === 'string') {
      commodity[k] = commodity[k].trim();
      if (commodity[k].length > 64) {
        // return `${commodity[k]} 长度不能超过 64`;
        return `${k} length exceeds limit 64`;
      }
    }
  }

  // 2022-03-17, 产品要求填写任意一个商品字段，商品都是合法的
  // if (!commodity.subcategoryId || !commodity.band || isNaN(commodity.price)) {
  //    return "请完善商品信息！";
  //  }

  let price = 0;
  if (typeof(commodity.price) !== 'undefined') {
    price = parseFloat(commodity.price);
    if(isNaN(price)) {
      return "wrong price!";
    }
  }
  if (price < 0 ) {
    return "invalid price!";
  }
  commodity.price = Math.floor(price);

  let attritionRate = 0.0;
  if (typeof(commodity.attritionRate) !== 'undefined') {
    attritionRate = parseFloat(commodity.attritionRate);
    if(isNaN(attritionRate) || attritionRate < 0 || attritionRate >= 100) {
      return "invalid attritionRate!";
    }
  }
  commodity.attritionRate = Math.floor(attritionRate*100)/100;

  return null;
}

export function validAreas(areas) { // error, index
  if (areas.length > 5) {
    return ["number of areas exceeds 5!", -1];
  }

  for (let i=0; i< areas.length; i++) {
    if (areas[i].assetIds.length > 20) {
      return ["number of assets exceeds 20!", i];
    }

    if (!areas[i].name) {
      return ["area not named!", i];
    }
    areas[i].name = areas[i].name.trim();
    if (areas[i].name.length === 0) {
      return ["area not named!", i];
    }

    let x = areas[i].index.findIndex(e => !/^\d+$/.test(e.toString()));
    if (x > -1) {
      return [`invalid area index: "${areas[i].index[x]}"!`, i];
    }

    if (!areas[i].assetIds || areas[i].assetIds.length === 0) {
      return [`missing assetIds`, i];
    }

    if (areas[i].assetIds) {
      let invalid = areas[i].assetIds.find(x => !/^[a-zA-Z][0-9a-zA-Z_-]+$/.test(x))
      if (invalid) {
        return [`invalid assetId: ${invalid}!`, i];
      }
    }

    for (let j=i+1; j<areas.length; j++) {
      let arr1 = areas[i].index.map(e => e.toString());
      let arr2 = areas[j].index.map(e => e.toString());
      if (arrsEquals(arr1, arr2)) {
        return [`duplicaed area index: ${areas[i].index}`, j];
      }
    }
  }

  areas.forEach(e => {
    e.assetIds = e.assetIds ? e.assetIds.filter(e => e.length > 0) : [];
    e.index = e.index.map(e => parseInt(e));
  });

  return [null, -1];
}

export function defaultCommodity() {
  return {
    price: 0, currency: "", attritionRate: 0, areas: [],
    kind: commodityKinds()[0], unit: commodityUnits()[0],
    thumbnail: "",
  };
}

export function commodityKinds() {
  return ["软装", "主材"];
}

export function commodityUnits() {
  return ["个", "棵", "片", "组", "m", "㎡", "㎥"];
}

//export function composeTypeList() {
//   return ["sofa", "tv-cabinet", "table", "bedroom", "dressing-table", "desk", "others"];
//}
