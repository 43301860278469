import React, { Component } from "react";
import { Modal, message } from "antd";
import { sprintf } from "sprintf-js";

import Edit from "./edit.jsx";
import { getStaticAddr } from "js/base.js";
import { toLocalDatetime, fmtNumber } from "js/utils.js";
import { companyCommodityDelete } from "js/api4admin.js";
import { defaultCommodity } from "js/assets.js";
import { getSet } from "locales/index.js";

class Table extends Component {
  constructor (props) {
    super(props); // items, updateItem

    this.state = {
      langCommon: getSet("common"), langPage: getSet("commodities"),

      statusList: ["待打包", "预发布", "上架", "下架"],
      target: {},
      isModalVisible: false,
    };
  }

  componentDidMount() {
  }

  deleteCommodity = (commodity) => {
    companyCommodityDelete(commodity, (res) => {
      if (res.code === 0) {
        this.props.alterCommodity(commodity.assetId, commodity, -1);
        message.info(`commodity ${commodity.name} was deleted!`)
      } else {
        message.error(res.message);
      }
    })
  }

  renderCommodity(asset, commodity, idx) {
    let langCommon = this.state.langCommon;

    let cC = this.props.commodityCategoryMap[commodity.subcategoryId];
    cC = cC ? cC.categoryName + " / " + cC.subcategoryName : "";

    let areasJSON = commodity.areas ? JSON.stringify(commodity.areas) : null;
    let title = `id:${commodity.id}, areas:${areasJSON}`;

	// TODO: commodity.thumbnail
    return (<>
      <tr key={commodity.id} id={commodity.id} data-index={idx}>
        <td> - </td>
        <td> - </td>
        <td style={{cursor: "pointer"}} title={title}> {commodity.name || "-"} </td>
        <td> <img alt="thumbnail" src={commodity.thumbnail} /> </td>

        <td style={{cursor:"pointer"}}> {commodity.kind || "-"} </td>
        <td title={commodity.categoryId + "/" + commodity.subcategoryId}> { cC || "-"} </td>
        <td> {commodity.band || "-"} </td>
        <td> {commodity.style || "-"} </td>
        <td> {commodity.place || "-"} </td>
        <td> {fmtNumber(commodity.price/100, true)} </td>
        <td> {commodity.material || "-"} </td>
        <td> {fmtNumber(commodity.attritionRate, true)} </td>
        <td title={toLocalDatetime(commodity.updateTime)}>
          {toLocalDatetime(commodity.createTime)}
        </td>
        <td>
          <Edit
            commodityCategories={this.props.commodityCategories}
            asset={asset}
            commodity={commodity}
            alterCommodity={this.props.alterCommodity}
          />
          <span className="span-seperator"> | </span>
          <span className="span-button" onClick={() => {
              this.setState({isModalVisible: true, target: commodity});
            }
          }>
            {langCommon.actions["delete"]}
          </span>
        </td>
      </tr>
    </>)
  }

  renderRow(item) {
    let langPage = this.state.langPage;

    let browseNodeIds = [], browseNodeNames = [];
    let browseNodes = item.subbrowseIds.map(e => this.props.subbrowseMap[e]);
    // console.log("~~~", this.props.subbrowseMap, browseNodes);
    browseNodes.forEach(e => browseNodeIds.push(e ? e.browseId + "/" + e.subbrowseId : "??"));
    browseNodes.forEach(e => browseNodeNames.push(
      e ? e.browseName + "/" + e.subbrowseName : "??"
    ));

    if (!item.commodities || item.commodities.length === 0) {
      item.commodities = [{assetId:item.assetId, ...defaultCommodity()}]; // as default commodity
    }
    if (item.commodities[0].areas && item.commodities[0].areas.length > 0) {
      // !! the first item doesn't has a default commodity
      item.commodities = [{assetId: item.AssetId, ...defaultCommodity()}, ...item.commodities];
    }
	let commodity = item.commodities[0];

    let cC = this.props.commodityCategoryMap[commodity.subcategoryId];
    cC = cC ? cC.categoryName + "/" + cC.subcategoryName : "";

    let dir = ["待打包", "预发布"].indexOf(item.status) > -1 ?
      "/meshes_preview/Preview" : "/meshes/Preview";

    let thumbnail = getStaticAddr() + `${dir}/${item.assetId}_320x240.jpg`;
    let areasJSON = commodity.areas ? JSON.stringify(commodity.areas) : null;
    let title = `id:${commodity.id}, areas:${areasJSON}`;

    return (<>
      <tr key={item.assetId} id={item.assetId} name={item.assetName}>
        <td style={{cursor:'pointer'}} title={"name:" + item.assetName}> {item.assetId}</td>
        <td title={browseNodeIds.join(", ") || ""}> {browseNodeNames.join(", ") || "-"} </td>

        <td title={title} style={{cursor:"pointer"}}> {commodity.name || "-"} </td>
        <td> <img alt="thumbnail" src={thumbnail} /> </td>
        <td> {commodity.kind ? langPage[commodity.kind] : "-"} </td>
        <td title={commodity.categoryId + "/" + commodity.subcategoryId}> { cC || "-"} </td>
        <td> {commodity.band || "-"} </td>
        <td> {commodity.style || "-"} </td>
        <td> {commodity.model || "-"} </td>
        {/*<td> {commodity.place || "-"} </td>*/}
        <td> {fmtNumber(commodity.price/100, true)} </td>
        <td> {commodity.material || "-"} </td>
        <td> {fmtNumber(commodity.attritionRate, true)} </td>
        <td title={toLocalDatetime(commodity.updateTime)}>
          {toLocalDatetime(commodity.createTime)}
        </td>
        <td>
          <span>
            <Edit
              commodityCategories={this.props.commodityCategories}
              asset={item}
              commodity={commodity}
              alterCommodity={this.props.alterCommodity}
            />
          </span>

          <span className="span-seperator"> | </span>
          <span className="span-button" onClick={()=> this.props.addCommodity(item.assetId)}>
            {langPage["relatedProduct"]}
          </span>
        </td>
      </tr>

      {item.commodities.slice(1).map(commodity => this.renderCommodity(item, commodity))}
    </>)
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return (<>
      <Modal title={langCommon.fields["tips"]} visible={this.state.isModalVisible}
        onOk={() => {
          this.deleteCommodity(this.state.target);
          this.setState({isModalVisible: false});
        }}
        onCancel={() => this.setState({isModalVisible: false})}
        okText={langCommon.actions["confirm"]}
        cancelText={langCommon.actions["cancel"]}
      >
        <p textIndent="30px">{sprintf(langPage.tipDelete, this.state.target.name)}</p>
      </Modal>

      <table className="table-content">
        <thead>
          <tr>
            <th>{langCommon.fields["assetId"]}</th>
            <th>{langCommon.fields["browse"]}</th>
            <th>{langCommon.fields["name"]}</th>
            <th>{langCommon.fields["thumbnail"]}</th>
            <th>{langCommon.fields["kind"]}</th>
            <th>{langCommon.fields["attribute"]}</th>
            <th>{langPage["brand"]}</th>
            <th>{langPage["style"]}</th>
            <th>{langPage["model"]}</th>
            {/*<th>{langPage["origin"]}</th>*/}
            <th>{langPage["price"]}</th>
            <th>{langPage["mainMaterial"]}</th>
            <th>{langPage["lossPerc"]}</th>
            <th>{langCommon.fields["createdAt"]} </th>
            <th>{langCommon.fields["action"]}</th>
          </tr>
        </thead>

        <tbody>
          {this.props.items.map((item, idx) => this.renderRow(item, idx))}
        </tbody>
      </table>
    </>)
  }
}

export default Table;
