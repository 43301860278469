let records = {
  title: "登录 - 数字运维管理",
  subtitle: "引擎运维管理后台",
  enter: "请输入手机号码或电子邮箱",
  password: "请输入密码",
  login: "登录",
  errPhoneOrEmail: "手机号码或电子邮箱错误",
  errPassword: "错误密码",
};

export default records;
