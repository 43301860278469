import { message } from "antd";

import { httpGET, httpPOST } from "./base.js";
// import { time2DateStr } from "./utils.js";


const RoutGroup = "/api/web/v1/auth/company/artist";

export function assetGetAttrs(data, callback, onError) {
  httpGET(`${RoutGroup}/asset/get_attrs`, null,
    res => {
      if (res.code > 0) message.error("获取 asset attrs 失败");
      callback(res); //!!! 仍然进行 callback
    },
    onError,
  );
}

export function companyAssetQuery(data, callback) {
  httpGET(`${RoutGroup}/asset/query`, data,
    res => {
      if (res.code === 0) {
        callback(res);
      } else {
        message.error("查询模型失败！");
      }
    }
  );
}

export function companyAssetUpdate(data, callback) {
  httpPOST(`${RoutGroup}/asset/update?assetId=${data.assetId}`,
    data, res => callback(res),
  );
}

export function companyAssetUpdateStatus(data, callback) {
  httpPOST(`${RoutGroup}/asset/update_status?asset_id=${data.asset_id}&status=${data.status}`,
    null, res => callback(res),
  );
}

export function companyAssetCreate(data, callback) {
  httpPOST(`${RoutGroup}/asset/create`,
    data, res => callback(res),
  );
}

export function bepacketDataCreate(data, callback) {
  httpPOST(`${RoutGroup}/bepacket_data/create`,
    data, res => callback(res),
  );
}

export function companyAssetBatchUpdateStatus(data, callback) {
  let params = `status=${data.status}&status2=${data.status2}&` +
    data.targetIds.map(e => `targetIds=${e}`).join("&");

  httpPOST(`${RoutGroup}/asset/batch_update_status?${params}`,
    null, res => callback(res),
  );
}

export function companyAssetUpdateAreas(data, callback, onError) {
  httpPOST(`${RoutGroup}/asset/update_areas`, data, callback, onError);
}
