import React from "react";
import { Modal } from 'react-bootstrap';

import { getSet } from "locales/index.js";
import 'css/modal.css';
import 'css/head.css';


class Prompt extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      langCommon: getSet("common"),
    };
  }

  ok(event) {
    if (!this.props.ok) {
      return;
    }

    this.props.ok();
    this.props.close();
  }

  reject(event) {
    if (!this.props.reject) this.props.reject();
    this.props.close();
  }

  render () {
    let langCommon = this.state.langCommon;

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.close.bind(this)}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered modal-promot"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title> {langCommon.fields["tips"]} </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div> {this.props.message} </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.reject.bind(this)}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm button-blue"
            onClick={this.ok.bind(this)}
          > {langCommon.actions["ok"]}
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default Prompt;
