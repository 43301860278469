import React, { Component } from "react";

import { getSet } from "locales/index.js";
import { toLocalDatetime } from "js/utils.js";


class Table extends Component {
  constructor (props) {
    super(props); // items
    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("accessLogs"),
    };
  }

  renderRows() {
    if (!this.props.items) {
      return (<> </>)
    }

    // <td> {item.ip || "-"} </td>
    return this.props.items.map((item, idx) => {
      return (
        <tr key={item.id} id={item.id} data-index={idx}>
          <td> {item.username} </td>
          <td id={item.userId}> {item.userTel || "-"} </td>
          <td id={item.userEmail}> {item.userEmail || "-"} </td>
          <td> {item.kind} </td>
          <td> {item.action} </td>
          <td> {item.ip || "-"} </td>
          <td title={"id: " + item.targetId} id={item.targetId}> {item.targetName} </td>
          <td> {toLocalDatetime(item.millisec)} </td>
        </tr>
      )
    })
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return (<>
      <table className="table-content">
        <thead>
          <tr>
            <th>{langCommon.fields["username"]}</th>
            <th>{langCommon.fields["phoneNumber"]}</th>
            <th>{langCommon.fields["emailAddress"]}</th>
            <th>{langCommon.fields["type"]}</th>
            <th>{langCommon.fields["action"]}</th>
            <th>IP</th>
            <th>{langCommon.fields["name"]}</th>
            <th>{langPage["accessTime"]}</th>
          </tr>
        </thead>

        <tbody> {this.renderRows()} </tbody>
      </table>
    </>)
  }
}

export default Table;
