import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Cascader, Select, message } from "antd";
import { sprintf } from "sprintf-js";

import BrowseThumbnail from "components/browse_thumbnail.jsx";
import putObject from "components/put_object.jsx";
import { companyCommodityNew } from "js/api4admin.js";
import { commodityValid, defaultCommodity, commodityUnits, commodityKinds } from "js/assets.js"
import { arrsEquals, randStr } from "js/utils.js";
import { getSet } from "locales/index.js";
import "css/modal.css";

const { Option } = Select;


class New extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"), langPage: getSet("commodities"),

      commodity: defaultCommodity(),
      image: { file: null, ext: ""},
    };
  }

  componentDidMount() {
  }

  handleSubmit = () => {
     if (!this.state.image.file) {
       this.createCommodity();
       return;
     }

     let key = `meshes/Static/commodity_image/` +
       `${this.props.targetAsset.assetId}_${randStr(32)}.${this.state.image.ext}`;

     putObject("commodity_thumbnail", key, this.state.image.file, link => {
       let commodity = {...this.state.commodity};
       commodity.thumbnail = link;
       this.setState({commodity: commodity}, this.createCommodity);
     });
  }

  createCommodity = () => {
    let langPage = this.state.langPage;
    let commodity = this.state.commodity;
    let err = commodityValid(commodity);
    if (err) {
      message.error(err);
      return;
    }

    commodity.assetId = this.props.targetAsset.assetId;
    commodity.name = commodity.name || this.props.targetAsset.assetName;
    // console.log(`~~~ ${JSON.stringify(commodity)}`);
    commodity.areas = commodity.areas.filter(e => e.assetId);
    if (commodity.areas.length === 0) {
      message.warn(sprintf(langPage["tipHasNoAreas"], ""));
      return;
    }

    this.props.close();

    companyCommodityNew(commodity, res => {
      if (res.code === 0) {
        this.setState({commodity: defaultCommodity()});
        message.info("ok");
        commodity.id = res.data.id;
        this.props.alterCommodity( this.props.targetAsset.assetId, commodity, 1);
      } else if (res.code === 3) {
        message.warn(langPage["tipProductExists"]);
      } else {
        message.error("failed");
      }
    });
  }

  update(key, val) {
    let commodity = this.state.commodity;
    commodity[key] = val;
    this.setState({commodity: commodity});
  }

  setArea(index, val) {
    if (!index || index.length === 0) {
      return
    }

    let commodity = this.state.commodity;
    if (!commodity.areas) {
      commodity.areas = [];
    }

    let i = commodity.areas.findIndex(e => arrsEquals(e.index, index));
    if (i > -1) {
      if (val) { // update an area
        commodity.areas[i].assetId = val;
      } else { // delete an area
        commodity.areas = [...commodity.areas.slice(0, i), ...commodity.areas.slice(i+1)];
      }
    } else { // add an area
      commodity.areas.push({index, assetId: val});
    }

    // console.log(`~~~ ${index}, ${val}, ${JSON.stringify(commodity.areas)}`);
    this.setState({commodity: commodity});
  }

  renderArea(area) {
    let langCommon = this.state.langCommon;
    // let langPage = this.state.langPage;

    return(<tr>
      <td>
        <span title={JSON.stringify(area.index)}> {/*langPage.materialAreas*/} {area.name} </span>:
      </td>

      <td>
        <Select
          allowClear
          style={{ width: '272px' }}
          placeholder={langCommon.placeholder["select"]}
          defaultValue=""
          onChange={(value) => this.setArea(area.index, value)}
        >
        {area.assetIds.map((item) =>
          <Option key={item}> {item} </Option>
        )}
       </Select>
      </td>
    </tr>)
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    let asset = this.props.targetAsset;
    let areas = asset.areas ? asset.areas.filter(e => e.assetIds && e.assetIds.length > 0) : [];

    return(<>
      <Modal
        show={this.props.showNew}
        onHide={this.props.close}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title> {langPage["relatedProduct"]} </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{overflowY: "scroll"}}>
          <table className="table-input">
            <tbody>
             <tr>
               <td colSpan="2" style={{textAlign:"left", fontWeight:"bold"}}>
                 {langPage["tipMaterialReplace"]}
               </td>
             </tr>
             {areas.map((e) => this.renderArea(e))}

              <tr>
                <td colSpan="2" style={{textAlign:"left", fontWeight:"bold"}}>
                  {langPage["tipProductInfo"]}
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red', display: "none"}}> * </span>
                  <span>{langCommon.fields["name"]}: </span>
                </td>
                <td>
                  <input name="name" maxLength="64" placeholder="no more than 64 charaters"
                   className="modal-input"
                   value={this.state.commodity.name || this.props.targetAsset.assetName}
                   onChange={(event) => this.update("name", event.target.value)}
                 />
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red', display: "none"}}> * </span>
                  <span> {langCommon.fields["kind"]}: </span>
                </td>

                <td>
                  <Select
                    // mode="multiple"
                    style={{ width: '272px' }}
                    // allowClear
                    placeholder={langCommon.placeholder["select"]}
                    defaultValue="软装"
                    value={this.state.commodity.kind}
                    onChange={v => this.update("kind", v)}
                  >
                   {commodityKinds().map(e =>
                     <Option value={e} label={e}> {langPage[e]} </Option>
                   )}
                  </Select>
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red', display: "none"}}> * </span>
                  <span> {langCommon.fields["category"]}: </span>
                </td>

                <td>
                  <Cascader
                    // changeOnSelect
                    style={{width: "272px", color: "grey"}}
                    options={this.props.commodityCategories}
                    placeholder={langCommon.placeholder["select"]}
                    onChange={(v) => {
                      let commodity = {...this.state.commodity};
                      commodity.categoryId = v[0];
                      commodity.subcategoryId = v[1];
                      this.setState({commodity: commodity});
                    }}
                  />
                </td>
              </tr>

              <tr>
                 <td>
                   <span style={{color: 'red', display: "none"}}> * </span>
                   <span>{langPage["brand"]}:</span>
                 </td>
                 <td>
                   <input name="band" maxLength="64" placeholder="no more than 64 characters"
                    className="modal-input"
                    title={this.state.commodity.band}
                    value={this.state.commodity.band}
                    onChange={(event) => this.update("band", event.target.value)}
                  />
                 </td>
              </tr>

              <tr>
                 <td>
                   <span>{langPage["model"]}: </span>
                 </td>
                 <td>
                   <input name="model" maxLength="64" placeholder="no more than 64 characters"
                    className="modal-input"
                    title={this.state.commodity.model}
                    value={this.state.commodity.model}
                    onChange={(event) => this.update("model", event.target.value)}
                  />
                 </td>
              </tr>

              <tr>
                 <td>
                   <span>{langPage["style"]}: </span>
                 </td>
                 <td>
                   <input name="style" maxLength="64" placeholder="no more than 64 characters"
                    className="modal-input"
                    title={this.state.commodity.style}
                    value={this.state.commodity.style}
                    onChange={(event) => this.update("style", event.target.value)}
                  />
                 </td>
              </tr>

              <tr>
                 <td>
                   <span>{langPage["origin"]}:</span>
                 </td>
                 <td>
                   <input name="place" maxLength="64" placeholder="no more than 64 characters"
                    className="modal-input"
                    title={this.state.commodity.place}
                    value={this.state.commodity.place}
                    onChange={(event) => this.update("place", event.target.value)}
                  />
                 </td>
              </tr>

              <tr>
                 <td>
                   <span style={{color: 'red', display: "none"}}> * </span>
                   <span>{langPage["price"]}:</span>
                 </td>
                 <td>
                   <input name="price" type="number" step="0.01" maxLength="64"
                     style={{width: "120px"}}
                     placeholder=""
                     className="modal-input"
                     value={this.state.commodity.price/100 || 0}
                     onChange={(event) => {
                       let value = event.target.value;
                       let v = value === "" ? 0 : parseFloat(value);
                       if (isNaN(v) || v < 0) {
                         return;
                       }
                       this.update("price", Math.floor(v*100));
                     }}
                   />

                   <span>{" "}{langPage["currency"]}:{" "}</span>

                   <Select
                     allowClear
                     style={{with:"auto", minWidth: "80px"}}
                     // mode="multiple"
                     placeholder={langCommon.placeholder["select"]}
                     defaultValue=""
                     value={this.state.commodity.currency}
                     onChange={(v) => this.update("currency", v)}
                   >
                     {["USD", "CNY", "JPY"].map(e =>
                       <Option value={e} label={e}> {e} </Option>
                     )}
                   </Select>
                 </td>
              </tr>

              <tr>
                 <td>
                   <span>{langPage["mainMaterial"]}:</span>
                 </td>
                 <td>
                   <input name="material" maxLength="64" placeholder="no more than 64 characters"
                    className="modal-input"
                    title={this.state.commodity.material}
                    value={this.state.commodity.material}
                    onChange={(event) => this.update("material", event.target.value)}
                  />
                 </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red', display: "none"}}> * </span>
                  <span> {langPage["unit"]}: </span>
                </td>

                <td>
                  <Select
                    // mode="multiple"
                    style={{ width: '272px' }}
                    // allowClear
                    placeholder={langCommon.placeholder["select"]}
                    defaultValue="个"
                    value={this.state.commodity.unit}
                    onChange={(v) => this.update("unit", v)}
                  >
                   {commodityUnits().map(e =>
                     <Option value={e} label={e}> {langPage.unitsMap[e]} </Option>
                   )}
                  </Select>
                </td>
              </tr>

              <tr>
                 <td>
                   <span>{langPage["lossPerc"]}:</span>
                 </td>
                 <td>
                   <input name="attritionRate" maxLength="64" placeholder=""
                    className="modal-input"
                    title={this.state.commodity.attritionRate}
                    value={this.state.commodity.attritionRate}
                    onChange={(event) => this.update("attritionRate", event.target.value)}
                  />
                 </td>
              </tr>

              <tr>
                 <td>
                   <span>{langPage["purchaseURL"]}:</span>
                 </td>
                 <td>
                   <input name="purchaseLink" maxLength="255"
                     placeholder="no more than 255 characters"
                     className="modal-input"
                     title={this.state.commodity.purchaseLink}
                     value={this.state.commodity.purchaseLink}
                     onChange={(event) => this.update("purchaseLink", event.target.value)}
                   />
                 </td>
              </tr>

              <tr>
                <td> {langCommon.fields["thumbnail"]}: </td>

                <td>
                  <BrowseThumbnail
                    thumbnail={this.state.commodity.thumbnail}
                    updateImage={(val) => this.setState({image: val})}
                  />
                </td>
              </tr>
            </tbody>

           <tfoot className="modal-tfoot">
             <tr> <td colSpan="2"> {this.state.errMsg} </td> </tr>
           </tfoot>
         </table>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.props.close}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm btn-blue button-blue"
            onClick={this.handleSubmit}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default New;
