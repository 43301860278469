let records = {
  sideNav: "グループ管理",
  tableTitle: "グループリスト",
  composeName: "グループ名前",
  tipDelete: "グループ %1$s を削除しますか？",
  tipAlterStatus: "グループ %1$s のステータスを %2$s に変更しますか？",
  editCompose: "グループ編集",
};

export default records;
