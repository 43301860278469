import React, { Component } from "react";
import { message } from "antd";
import { BiSort, BiSortDown, BiSortUp } from "react-icons/bi";
import { sprintf } from "sprintf-js";

import Prompt from "components/prompt.jsx";
import Edit from "./edit.jsx";
import { userAssetUpdateStatus } from "js/api4user.js";
import { toLocalDatetime, fixStrLen } from "js/utils.js";
import { getSet } from "locales/index.js";
import NoThumbnail from "img/no_thumbnail.png";


class AssetTable extends Component {
  constructor (props) {
    super(props); // items, updateAsset, afterDelete, basename

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("userAssets"),

      target: null, promptMsg: "", promptDelete: false, promptAlertStatus: false,
    };
  }

  alertStatus(target) {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    let newStatus = target.status !== "上架" ? "上架" : "下架";
    // console.log(`~~~ ${JSON.stringify(target)}`);

    this.setState({
      target: target,
      promptMsg: sprintf(
        langPage["tipAlterStatus"], fixStrLen(target.name, 12), langCommon.status[newStatus],
      ),
      promptAlertStatus: true,
    });
  }

  renderSortIcon(name) {
    // console.log("--> renderOrder:", name, this.props.ascending);
    if (name !== this.props.query.orderBy || this.props.query.ascending === "") {
      return (<BiSort size={18} color="grey"/>)
    } else if (this.props.query.ascending === "true") {
      return (<BiSortUp size={18} color="grey"/>)
    } else {
      return (<BiSortDown size={18} color="grey"/>)
    }
  }

  // `删除模型 "${item.name}" 将无法回复!`
  renderRows() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return this.props.items.map((item, idx) => {
      // let className = "btn btn-sm query-button btn-success";
      // if (item.status === "下架") className = "btn btn-sm query-button btn-secondary";
      return (
        <tr key={item.id} id={item.id} data-index={idx} name={item.name}>
          <td> {item.name} </td>
          <td> <img alt={langCommon.fields["thumbnail"]} src={item.thumbnail || NoThumbnail } /> </td>
          <td> {toLocalDatetime(item.createTime)} </td>
          <td> {toLocalDatetime(item.updateTime)} </td>

          <td>
            <span className="span-button"
              onClick={() => this.alertStatus(item)}
            >
              {langCommon.status[item.status]}
            </span>
          </td>

          <td>
            <Edit
              id={item.id}
              name={item.name}
              link={item.link}
              thumbnail={item.thumbnail}
              updateAsset={this.props.updateAsset}
            />
            <span className="span-seperator"> | </span>
          
            <span className="span-button"
              onClick={() => {
                this.setState({
                  target: item, promptDelete: true,
                  promptMsg: sprintf(langPage.tipDelete, fixStrLen(item.name, 12)),
                });
              }}
            >
              {langCommon.actions["delete"]}
            </span>
          </td>
        </tr>
      )
    })
  }

  render() {
    let langCommon = this.state.langCommon;

    return (<>
      <table className="table-content">
        <thead>
          <tr>
            <th style={{cursor:'pointer'}}>
              {langCommon.fields["name"]}
              <span onClick={() => this.props.updateOrders("name")}>
                {this.renderSortIcon("name")}
              </span>
            </th>
            <th>{langCommon.fields["thumbnail"]}</th>
            <th style={{cursor:'pointer'}}>
              {langCommon.fields["createdAt"]}
              <span onClick={() => this.props.updateOrders("createTime")}>
                {this.renderSortIcon("createTime")}
              </span>
            </th>
            <th style={{cursor:'pointer'}}
              onClick={() => this.props.updateOrders("updateTime")}
            >
              {langCommon.fields["updatedAt"]} {this.renderSortIcon("updateTime")}
            </th>
            <th>{langCommon.fields["status"]}</th>
            <th>{langCommon.fields["action"]}</th>
          </tr>
        </thead>

        <tbody>
          {this.renderRows()}
        </tbody>
      </table>

      <Prompt
        show={this.state.promptAlertStatus}
        message={this.state.promptMsg}
        close={() => this.setState({promptAlertStatus: false})}
        reject={() => {}}

        ok={() => {
          let newStatus = this.state.target.status !== "上架" ? "上架" : "下架";

          userAssetUpdateStatus({id: this.state.target.id, status: newStatus},
            (res => {
              if(res.code === 0) {
                this.props.updateAsset({id: this.state.target.id, status: newStatus});
              } else {
                message.error(langCommon.tips["tipFaildToDelete"]);
              }
            })
          )
        }}
      />

      <Prompt
        show={this.state.promptDelete}
        message={this.state.promptMsg}
        close={() => this.setState({promptDelete: false})}
        reject={() => {}}

        ok={() => {
          userAssetUpdateStatus({id: this.state.target.id, status: "删除"}, res => {
            if (res.code === 0) {
              if (this.props.afterDelete) this.props.afterDelete();
            } else {
              message.error(langCommon.tips["tipFaildToDelete"]);
            }
          })
        }}
      />
    </>)
  }
}

export default AssetTable;
