import React, { Component } from 'react';
import { Select } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
// import LeftArrow from "img/left_arrow@2x.png";
// import RightArrow from "img/right_arrow@2x.png";
import { sprintf } from "sprintf-js";

import { getSet } from "locales/index.js";
import 'css/content.css';

const { Option } = Select;


class Page extends Component {
  constructor (props) {
    super(props);

    // page, pageSize, totalItems, updatePageNum, pageSizes, updatePageSize
    this.state = {
      langCommon: getSet("common"),
    }; //{page: this.props.page};
  }

  pageAdd(num) {
     let value = this.props.page + num;
     if (value < 1 || value > this.getTotalPages()) {
       return;
     }

     this.props.updatePageNum(value);
     // this.setState({page: value});
  }

  getTotalPages() {
    if (this.props.pageSize === 0) {
      return 0;
    }
    // console.log(this.props.totalItems, this.props.pageSize);
    return Math.ceil(this.props.totalItems/this.props.pageSize);
  }

  updatePageSize(event) {
    this.props.updatePageSize(event.target.value);
  }

  setPage(event) {
    this.setState({page: event.target.value});
  }

  enterPage(event) {
     // console.log(event.key);
     if(event.key !== 'Enter') return;

     if (event.target.value === "") { // 删除 input 中的数值
       this.setState({page: this.props.page});
       return;
     }
     let value = parseInt(event.target.value);
     if (!isFinite(value) || value <= 0) {
       this.setState({page: this.props.page});
       return;
     }

     if (value === this.props.page) {
       return;
     }

     let totalPages = this.getTotalPages();
     if (value > totalPages) {
       this.props.updatePageNum(totalPages);
       this.setState({page: totalPages});
       return;
     }

     this.props.updatePageNum(value);
     this.setState({page: value});
  }

  renderSelect() {
    let langCommon = this.state.langCommon;
    let pageSizes = this.props.pageSizes;

    if (!this.props.updatePageSize || !pageSizes || pageSizes.length === 0) {
      return (<></>)
    }

    function renderOption(size, idx) {
      return (
        <option style={{fontSize: '12px'}} key={idx} value={size}>
          {size} {langCommon.page["pageSize"]}
        </option>
      )
    }

    // select: appearance:'none'
    return (
      <select onChange={this.updatePageSize.bind(this)}>
        {pageSizes.map((size, idx) => renderOption(size, idx))}
      </select>
    )
  }

  renderSelect2() {
    let langCommon = this.state.langCommon;
    let pageSizes = this.props.pageSizes;

    if (!this.props.updatePageSize || !pageSizes || pageSizes.length === 0) {
      return (<></>)
    }

    return (
      <Select style={{textAlign:'left', fontSize:'14px', marginLeft:'5px'}}
        size="small" defaultValue={pageSizes[0]}
        value={this.props.pageSize}
        onChange={value => this.props.updatePageSize(value) }
      >
        {
          pageSizes.map((size, idx) => {
            return (<Option  key={idx} value={size}>
              {size} {langCommon.page["pageSize"]}
            </Option>)
          })
        }
      </Select>
    )
  }

  render () {
    let langCommon = this.state.langCommon;

    let n1 = (this.props.page -1)*this.props.pageSize + 1;
    if (this.props.totalItems === 0) n1 = 0;

    let n2 = this.props.page*this.props.pageSize;
    if (n2 > this.props.totalItems) n2 = this.props.totalItems;

    let nn = n1 > 0 ? sprintf(langCommon.page.items + "/", n1, n2) : "";

    return (<>
      <div className="page">
        <span> {nn}{sprintf(langCommon.page.total, this.props.totalItems)} </span>

        <LeftOutlined onClick={this.pageAdd.bind(this, -1)} />
        <span> {this.props.page} </span>
        <RightOutlined onClick={this.pageAdd.bind(this, 1)} />

        {this.renderSelect2()}
      </div>
    </>)
  }

  /* <input id="page" style={{cursor:"pointer"}} value={this.props.page}/> */
}

export default Page;
