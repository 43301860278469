import React, { Component } from "react";
import { Modal } from "react-bootstrap";
// import { message } from "antd";
// import OSS from "ali-oss/dist/aliyun-oss-sdk.js";
import { sprintf } from "sprintf-js";

import BrowseThumbnail from "components/browse_thumbnail.jsx";
import putObject from "components/put_object.jsx";
// import { getSts } from "js/api4public.js";
import { designUpdate } from "js/api4admin.js";
import { defaultInitGis, randStr } from "js/utils.js";
import { getSet } from "locales/index.js";
import "css/modal.css";


class EditDesign extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("designs"),
      onSubmit: false, show: false, errMsg: "",
      pakPathColor: "black", descColor: "black", initGisColor: "black",
      image: { file: null, ext: ""},

      design: {
        name: this.props.design.name, desc: this.props.design.desc || "",
        pakPath: this.props.design.pakPath, icon: this.props.design.icon,
        initGis: JSON.stringify(this.props.design.initGis, "", "  "),
      },
    };
  }

  resetInput() {
    this.setState({
      onSubmit: false, show: false, errMsg: "",
      pakPathColor: "black", descColor: "black", initGisColor: "black",
      image: { file: null, ext: ""},
      design: {
        name: this.props.design.name,
        desc: this.props.design.desc || "",
        pakPath: this.props.design.pakPath, icon: this.props.design.icon,
        initGis: JSON.stringify(this.props.design.initGis, "", "  "),
      },
    })
  }

  validGis(gisData) {
    let langCommon = this.state.langCommon;
    let keys = ["ueMin", "ueMax", "gisMin", "gisMax"];
    let xyz = ["x", "y", "z"];

    for (let i in keys) {
      if (!gisData.hasOwnProperty(keys[i])) {
        return sprintf(langCommon.missingField, keys[i]);
      }

      let d = gisData[keys[i]];

      for (let j in xyz) {
        if (!d.hasOwnProperty(xyz[j])) {
          return sprintf(langCommon.tips["missingField"], `${keys[i]}.${xyz[j]}`);
        }

        if (typeof(d[xyz[j]]) !== 'number') {
          return sprintf(langCommon.tips["notNumber"], `${keys[i]}.${xyz[j]}`);
        }
      }
    }

    return null;
  }

  handleSubmit = (event) => {
    let langPage = this.state.langPage;

    if (this.state.onSubmit) {
      return;
    }

    let ok = true;
    let {name, desc, pakPath, initGis} = this.state.design;
    let gisData = null;

    if (name.length > 64) {
      ok = false;
    }

//    if(!/^[\p{Script=Han}a-zA-Z0-9-_]+$/u.test(pakPath)) {
//      if (ok) this.setState({errMsg: "pakPath 不合法！"});
//      this.setState({pakPathColor: "red"});
//      ok = false;
//    } else
    if (pakPath.length > 255) {
      this.setState({pakPathColor: "red"});
      ok = false;
    } else {
      this.setState({pakPathColor: "black"});
    }

    if (desc.length > 255) {
      if (ok) this.setState({errMsg: langPage["tipDescriptionLimit"]});
      this.setState({descColor: "red"});
      ok = false;
    } else {
      this.setState({descColor: "black"});
    }

    if (initGis.length > 1024) {
      if (ok) this.setState({errMsg: langPage["tipGisLimit"]});
      this.setState({initGisColor: "red"});
      ok = false;
    } else {
      try {
         gisData = JSON.parse(initGis);
         let msg = this.validGis(gisData);
         if (msg) {
           if (ok) this.setState({errMsg: msg});
           this.setState({initGisColor: "red"});
           ok = false;
         } else {
           this.setState({initGisColor: "black"});
         }
      } catch (err) {
        // console.log("JSON.parse(initGis):", err);
        if (ok) this.setState({errMsg: langPage["tipInvalidGis"]});
        this.setState({initGisColor: "red"});
        ok = false;
      };
    }

    if (!ok) {
      return;
    }

    let data = {id: this.props.design.id, name, desc, pakPath, initGis: gisData};
    this.setState({onSubmit: true, errMsg: ""});

    if (!this.state.image.file) {
      this.sendUpdate(data);
      return;
    }

    let key = `meshes/Static/maps_image/${data.pakPath}_${randStr(32)}.${this.state.image.ext}`;

    putObject("design_thumbnail", key, this.state.image.file, link => {
      data.icon = link;
      this.sendUpdate(data);
    });

//    getSts({kind: "design_thumbnail", key: key}, res => {
//      if (res.code === 0) {
//        // TODO: res.data.provider
//        let sts = res.data.sts;

//        let client = new OSS({
//          accessKeyId: sts.accessKeyId,
//          accessKeySecret: sts.accessKeySecret,
//          region: "oss-" + sts.regionId,
//          bucket: sts.bucket,
//          secure: true,
//          stsToken: sts.securityToken,
//        });

//        client.put(key, this.state.image.file).then(res => {
//          data.icon = res.url;
//          this.sendUpdate(data);
//        }).catch(err => {
//          message.error("failed to upload image");
//          console.log(`!!! failed to upload image: ${err}`);
//        });
//      } else {
//        message.error("failed to get sts token");
//      }
//    });
  }

  sendUpdate = (data) => {
      designUpdate(data,
      res => {
        this.setState({onSubmit: false});
        if (res.code === 0 ) {
          if (this.props.updateItem) this.props.updateItem(data);
          this.resetInput();
        }
      },
      () => this.setState({onSubmit: false}),
    );
  }

  updateDesign(event) {
    let elem = event.target;
    let design = {...this.state.design};
    design[elem.name] = elem.value;
    this.setState({design: design});
  }

//  updateUser2(value, key) {
//    let user = {...this.state.user}
//    user[key] = value;
//    this.setState({user: user});
//  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return(<>
      <span className="span-button" onClick={()=>this.setState({show: true})}>
        {langCommon.actions["edit"]}
      </span>

      <Modal
        show={this.state.show}
        onHide={this.resetInput.bind(this)}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title>{langPage["editProject"]}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <table className="table-input">
            <tbody>
              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span style={{color: this.state.nameColor}}>{langCommon.fields["name"]}: </span>
                </td>

                <td>
                  <input name="name" maxLength="64"
                    placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.design.name} onChange={this.updateDesign.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span style={{color: this.state.pakPathColor}}>pakPath: </span>
                </td>

                <td>
                  <input name="pakPath" maxLength="255"
                    placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.design.pakPath} onChange={this.updateDesign.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: this.state.descColor}}>
                    {langCommon.fields["description"]}:
                  </span>
                </td>

                <td>
                  <textarea name="desc" cols="30" rows="7"
                    placeholder={langCommon.placeholder["enter"]}
                    className="modal-text"
                    value={this.state.design.desc} onChange={this.updateDesign.bind(this)}
                  ></textarea>
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span style={{color: this.state.initGisColor}}> {langPage["gisData"]}: </span>
                </td>

                <td>
                  <textarea name="initGis" cols="30" rows="7" className="modal-text"
                    placeholder={defaultInitGis()}
                    value={this.state.design.initGis} onChange={this.updateDesign.bind(this)}
                  ></textarea>
                </td>
              </tr>

              <tr>
                <td> {langCommon.fields["thumbnail"]}:</td>
                <td>
                  <BrowseThumbnail
                    thumbnail={this.state.design.icon}
                    updateImage={(val) => this.setState({image: val})}
                  />
                </td>
              </tr>
            </tbody>

           <tfoot className="modal-tfoot">
             <tr> <td colSpan="2"> {this.state.errMsg} </td> </tr>
           </tfoot>
         </table>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.resetInput.bind(this)}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm btn-blue button-blue"
            onClick={this.handleSubmit.bind(this)}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default EditDesign;
