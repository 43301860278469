let records = {
  sideNav: "アセット管理",
  tableTitle: "アセットリスト",
  tipCreatePackage: "一括パッケージの作成を確認しますか？",
  changeStatus: "ステータス修正",
  tipChangeStatusOfSelectedAsset: "%1$s で選択されたモデルのステータスを %2$s で指定します。",
  tipChangeStatusOfAsset: "本当に %1$s ステータスを %2$s に変更しますか？",
  areaIndx: "エリアindex",
  areaName: "エリア名前",
  variantId: "置換マテリアルID",
  variant: "置換マテリアル",
  tip1: "ソフトウェアでクリックして置換できます",
  tip2: "モデルはライブラリーで表示されます",
  tipSelectCategory: "カテゴリを選択ください",
  tipInvalidAssetName: "モデルの名前は正しいではない",
  editAsset: "モデル編集",
  assetBasics: "アセット基本情報",
  assetBasicsDetail: "モデル作成は基本情報が必要です",
  browseNode: "ブラウズノード",
  tipEnterAssetInfo: "モデル情報を入力ください",
  createAsset: "新規作成",
  package: "パッケージ",
  tipDelete: "%1$s を削除すると復元できません。モデルを削除しますか？",
};

export default records;
