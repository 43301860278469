let records = {
  sideNav: "My Groups",
  tableTitle: "Groups",
  tipDelete: "Are you sure you want to permanently delete this group: %1$s?",
  tipAlterStatus: "Are you sure you want to change the status of %1$s to %2$s?",
  editCompose: "Edit Group",
  push2company: "Push group to company library",
};

export default records;
