let records = {
  sideNav: "アカウント管理",
  tableTitle: "アカウント管理リスト",
  role: "役",
  "引擎开发": "開発者",
  "引擎美术": "3Dアーティスト",
  "引擎测试": "テストエンジニア",
  "交付工程师": "エンドエンジニア",
  accountType: "アカウントタイプ",
  editAccount: "アカウント編集",
  createAccount: "アカウント作成",
  wrongPhoneOrEmail: "携帯電話番号またはメールが間違っています",
  wrongDateRange: "日付の範囲が間違っています",
  editYes: "編集可能",
  editNo: "編集不可能",
  tipDelete: "%1$s を削除すると復元できません。本当にアカウントを削除しますか？",
  permissions: "権限",
  tipEnterName: "名前を入力してください",
  tipNameLength: "名前の長さは30文字を超えてはいけません",
  tipInvalidPhone: "正しい携帯電話番号ではありません",
  tipInvalidEmail: "正しいメールアドレスではありません",
  tipEnterPhoneOrEmail: "携帯電話番号または電子メールアドレスを設定してください",
  tipInvalidPassword: "パスワードに含めることができるのは0-9、a-z、A-Zだけで、長さの範囲は6-20です",
  tipPasswordNotMatch: "パスワードが一致しません",
  tipEnterRole: "役を入力してください",
  passwordConfirmation: "パスワードの確認",
};

export default records;
