import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { message } from "antd";

import BrowseThumbnail from "components/browse_thumbnail.jsx";
import putObject from "components/put_object.jsx";
import { composeUpdate } from "js/api4user.js";
import { randStr } from "js/utils.js";
import { getSet } from "locales/index.js";
import "css/modal.css";

class Edit extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("myComposes"),
      showEdit: false,
      image: { file: null, ext: ""},
      item: {...this.props.item},
    };
  }

  close = () => {
    this.setState({showEdit: false, imageData: null});
  }

  sendUpdate = (data) => {
    composeUpdate(data, res => {
      if (res.code === 0) {
        message.info("ok");
        this.props.updateItem(data);
      } else {
        message.error(res.message);
      }
    });
  }

  handleSubmit = () => {
    // if (Object.keys(data).length !== 0) {
    let data = {id: this.state.item.id, name: this.state.item.name};

    if (!this.state.image.file) {
      this.sendUpdate(data);
      this.close();
      return;
    }

    let key = `meshes/Static/assets_compose/${randStr(32)}.${this.state.image.ext}`;

    putObject("my_compose", key, this.state.image.file, link => {
      data.thumbnail = link;
      this.setState({showEdit: false}, () => this.sendUpdate(data));
    });
  }

  update = (key, val) => {
    let item = this.state.item;
    item[key] = val;
    this.setState({item: item});
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return(<>
      <span className="span-button" onClick={() => {
        this.setState({showEdit: true, item: this.props.item});
      }}>
        {langCommon.actions["edit"]}
      </span>

      <Modal
        show={this.state.showEdit}
        onHide={this.close}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title>{langPage["editCompose"]}</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{overflowY: "scroll"}}>
          <div className="upload-field-title"> {langCommon.fields["name"]}: {" "}
            <input name="material" maxLength="64" placeholder="64 characters"
              className="modal-input"
              value={this.state.item.name}
              onChange={(event) => this.update("name", event.target.value)}
            />
          </div>

          <br></br>
          <div className="upload-field-title">
            {langCommon.fields["thumbnail"]} (support jpeg and png format, file size limit is 2MB):
          </div>
          <BrowseThumbnail
            thumbnail={this.props.item.thumbnail}
            updateImage={(val) => this.setState({image: val})}
          />
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.close}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm btn-blue button-blue"
            onClick={this.handleSubmit.bind(this)}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default Edit;
