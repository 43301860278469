let records = {
  sideNav: "企业项目",
  tableTitle: "项目列表",
  tipDelete: "确定删除项目 %1$s?",
  tipChangeStatus: "确认修改 %1$s 状态为 %2$s?",
  tipEnterDescription: "请输入描述",
  tipDescriptionLimit: "项目描述长度不能超过 255",
  tipGisLimit: "gis 不能超过 1024",
  tipInvalidGis: "gis 数据不是合法 json",
  editProject: "编辑项目",
  gisData: "Gis 数据",
  tipEnterName: "请输入项目名称",
  tipNameLimit: "项目名称长度不能超过 64",
  tipInvalidPakPath: "pakPath 不合法",
  createProject: "新建项目",
};

export default records;
