import React, { Component } from "react";
// import Datetime from "react-datetime";
import { Cascader, message } from "antd"; // Select
// import { Modal } from 'react-bootstrap';
import { sprintf } from "sprintf-js";

import Page from "components/page2.jsx";
import Table from "./table.jsx";
import New from "./new.jsx";
import { processAttrs } from "js/assets.js";
import { assetGetAttrs } from "js/api4artist.js";
import { companyCommodityQuery } from "js/api4admin.js";
import { getSet } from "locales/index.js";
import "css/content.css";
import 'css/modal.css';
import 'css/head.css';

// const { Option } = Select;


class Commodities extends Component {
  constructor (props) {
     super(props);

     this.state = {
       langCommon: getSet("common"), langPage: getSet("commodities"),

       browseNodes: [],
       subbrowseMap: null,
       categories: [],
       assetKinds: [],
       commodityCategories: [],
       commodityCategoryMap: null,

       query: this.defaultQuery(),
       items: [], totalItems: 0,

       showNew: false,
       targetAsset: {},
     };
  }

  componentDidMount() {
    let langPage = this.state.langPage;
    document.title = langPage["sideNav"];

    assetGetAttrs(null, res => {
      if (res.code !== 0) {
        return;
      }
      let data = processAttrs(res.data);
      this.setState(data);
      this.queryCommodities();
    })
  }


  defaultQuery() {
    return {
      page: 1, pageSize: 10, name: "", browseId: "", subbrowseId: "", status: null,
    };
  }

  updateItems = (data) => {
    let x = {items: data.items};
    if (data.items.length === 0 || data.totalItems > 0) {
        x.totalItems = data.totalItems;
    };
    this.setState(x);
  }

  reset = () => {
    let query= this.defaultQuery();
    this.setState({query: query});

    companyCommodityQuery(query, res => {
      if (res.code === 0) {
        this.updateItems(res.data);
      }
    });
  }

  queryCommodities = () => {
    let query = {...this.state.query};
    query.name = query.name.trim();
    this.setState({query: query});

    companyCommodityQuery(query, res => {
      if (res.code !== 0) {
        return;
      }
      this.updateItems(res.data);
    });
  }

  update(key, val) {
    // console.log(`~~~ ${key}, ${JSON.stringify(val)}`);
    let query = {...this.state.query};

    if (key === "browse") {
      if (val.length === 0) {
        query.browseId = "";
        query.subbrowseId = "";
      } else if (val.length === 1) {
        query.browseId = val[0];
        query.subbrowseId = "";
      } else {
        query.browseId = val[0];
        query.subbrowseId = val[1];
      }
    } else {
      query[key] = val;
    }

    this.setState({query: query});
  }

  updatePage = (data) => {
    // console.log(`~~~ ${num}, ${size}`);
    let query = {...this.state.query};

    if (data.page) {
      query.page = data.page;
    }
    if(data.pageSize) {
      query.pageSize = data.pageSize;
    }

    companyCommodityQuery(query, res => {
      if (res.code === 0) {
        this.setState({query: query, items: res.data.items});
      }
    })
  }

  addCommodity = (assetId) => {
    let langPage = this.state.langPage;
    // console.log(`~~~ addCommodity: ${assetId}`);
    let asset = this.state.items.find(e => e.assetId === assetId);
    if (!asset) {
      // console.log(`!!! no match: ${assetId}`);
      return;
    }
    if (!asset.areas || asset.areas.length === 0) {
      message.warn(sprintf(langPage["tipHasNoAreas"], asset.assetId));
      return;
    }
    let areas = asset.areas.filter(e => e.assetIds && e.assetIds.length > 0);
    if (areas.length === 0){
      message.warn(sprintf(langPage["tipHasNoMaterials"], asset.assetId));
      return;
    }

    this.setState({showNew: true, targetAsset: asset});
  }

  alterCommodity = (assetId, commodity, code) => {
    // console.log(`~~~ alterCommodity: ${assetId}, ${commodity}, ${code}`);
    let items = this.state.items;
    let asset = items.find(e => e.assetId === assetId);
    if (!asset) {
      message.warn(`asset ${assetId} not found`);
      return;
    }

    if (!asset.commodities) {
      asset.commodities = [];
    }

    if (code === 1 || asset.commodities.length === 0) { // add commodity
      asset.commodities.push(commodity);
    } else if (code === 0) { // update commodity
      let index = asset.commodities.findIndex(e => e.id === commodity.id);
      if (index > -1) {
        asset.commodities[index] = commodity;
      } else if (!asset.commodities[0].id) {
        asset.commodities[0] = commodity;
      } else {
        // console.log(`??? ${JSON.stringify(asset.commodities)}`);
        message.warn(`commodity ${commodity.id} not found`);
        return;
      }
    } else if (code === -1) { // delete commodity
      let index = asset.commodities.findIndex(e => e.id === commodity.id);
      let commodities = asset.commodities;
      asset.commodities = [...commodities.slice(0, index), ...commodities.slice(index+1)];
    } else {
      console.log(`!!! alterCommodity: unknown code ${code}`)
    }

    this.setState({items: items});
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return (<>
      <div className='user-query'>
        <div className="input-parameter">
          <span> {langCommon.fields["name"]} </span>
          <input type="text" name="name"
            placeholder={langCommon.placeholder["enter"]}
            value={this.state.query.name}
            onChange={(event) => this.update("name", event.target.value)}
          />
        </div>

        <div className="input-parameter">
          <span> {langCommon.fields["browse"]}: </span>
          <Cascader
            changeOnSelect
            style={{textAlign: 'left', width: '180px'}}
            options={this.state.browseNodes}
            value={
              this.state.query.browseId ?
                [this.state.query.browseId, this.state.query.subbrowseId] : null
            }
            onChange={value => this.update("browse", value)}
            placeholder={langCommon.placeholder["select"]}
          />
        </div>

        {/*
        <div className="input-parameter">
          <span> {langCommon.fields["status"]}: </span>
          <Select
            style={{ width: 180, textAlign: 'left' }}
            name="status"
            allowClear
            placeholder={langCommon.placeholder["enter"]}
            value={this.state.query.status}
            onChange={value => this.update("status", value)}
          >
            <Option value="待打包">待打包 TODO:</Option>
            <Option value="预发布">预发布 TODO:</Option>
            <Option value="上架">{langCommon.status["上架"]}</Option>
            <Option value="下架">{langCommon.status["下架"]}</Option>
          </Select>
        </div>
        */}

        <div className="div-button-to-right">
          <button className="btn btn-secondary btn-sm button-blank query-button"
            onClick={this.reset}> {langCommon.actions["reset"]}
          </button>

          <button className="btn btn-primary btn-sm query-button button-blue"
            onClick={this.queryCommodities}> {langCommon.actions["search"]}
          </button>
        </div>
      </div>

      <div className="content-area">
        <div className="content-top">
          <div>{langPage["tableTitle"]}</div>
        </div>

        <Table
          items={this.state.items}
          subbrowseMap={this.state.subbrowseMap}
          commodityCategoryMap={this.state.commodityCategoryMap}
          commodityCategories={this.state.commodityCategories}
          addCommodity={this.addCommodity}
          alterCommodity={this.alterCommodity}
        />

        <New
          showNew={this.state.showNew}
          targetAsset={this.state.targetAsset}
          commodityCategories={this.state.commodityCategories}
          alterCommodity={this.alterCommodity}
          close={() => this.setState({showNew: false})}
        />

        <Page
          page={this.state.query.page}
          pageSize={this.state.query.pageSize}
          totalItems={this.state.totalItems}
          pageSizes={[10, 15, 20, 30]}
          updatePage={this.updatePage}
        />
      </div>
    </>)
  }
}

export default Commodities;
