let records = {
  title: "ログイン-アセット管理システム",
  subtitle: "バックエンド管理システム",
  enter: "携帯番号またはメールアドレスを入力ください",
  password: "パスワードを入力ください",
  login: "ログイン",
  errPhoneOrEmail: "正しい携帯番号またはメールアドレスではない",
  errPassword: "パスワード間違っています",
};

export default records;
