import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { message } from "antd";
// import OSS from "ali-oss/dist/aliyun-oss-sdk.js";
import { sprintf } from "sprintf-js";

import BrowseThumbnail from "components/browse_thumbnail.jsx";
import putObject from "components/put_object.jsx";
// import { getSts } from "js/api4public.js";
import { designCreate } from "js/api4admin.js";
import { defaultInitGis, randStr } from "js/utils.js";
import { getSet } from "locales/index.js";
import "css/modal.css";


class NewDesign extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("designs"),
      show: false,
      image: { file: null, ext: ""},
      design: {
        name: "", desc: "", pakPath: "",
        initGis: defaultInitGis(), icon: "",
      },
    };
  }

//  clearInput() {
//    this.setState({
//      onSubmit: false, show: false,
//      image: { file: null, ext: ""},
//      design: {
//        name: "", pakPath: "", desc: "",
//        initGis: defaultInitGis(), icon: "",
//      },
//    })
//  }

  validGis(gisData) {
    let langCommon = this.state.langCommon;

    let keys = ["ueMin", "ueMax", "gisMin", "gisMax"];
    let xyz = ["x", "y", "z"];

    for (let i in keys) {
      if (!gisData.hasOwnProperty(keys[i])) {
        return sprintf(langCommon.missingField, keys[i]);
      }

      let d = gisData[keys[i]];

      for (let j in xyz) {
        if (!d.hasOwnProperty(xyz[j])) {
          return sprintf(langCommon.missingField, `${keys[i]}.${xyz[j]}`);
        }

        if (typeof(d[xyz[j]]) !== 'number') {
          return sprintf(langCommon.tips["notNumber"], `${keys[i]}.${xyz[j]}`);
        }
      }
    }

    return null;
  }

  handleSubmit = () => {
    let langPage = this.state.langPage;
    let {name, desc, pakPath, initGis} = this.state.design;
    let gisData = null;

    if (name.length === 0) {
      message.warn(langPage["tipEnterName"]);
      return;
    }

    // !/^[\p{Script=Han}a-zA-Z0-9-_]{1,64}$/u.test(name)
    // if (!/^[\p{Script=Han}a-zA-Z0-9-_]+$/u.test(name)) {
    if (name.length > 64) {
      message.warn(langPage["tipNameLimit"]);
      return;
    }

    // if(!/^[\p{Script=Han}a-zA-Z0-9-_]+$/u.test(pakPath) || pakPath.length > 255) {
//    if(!/^[a-zA-Z0-9-_]+$/u.test(pakPath) || pakPath.length > 255) {
//      message.warn(langPage["tipInvalidPakPath"]);
//      return;
//    }

    if (desc.length > 255) {
      message.warn(langPage["tipDescriptionLimit"]);
      return;
    }

    if (initGis.length > 1024) {
      message.warn(langPage["tipGisLimit"]);
      return;
    } else {
      try {
         gisData = JSON.parse(initGis);
         let msg = this.validGis(gisData);
         if (msg) {
           message.warn(msg);
           return;
         }
      } catch (err) {
        // console.log("JSON.parse(initGis):", err);
        message.warn(langPage["tipInvalidGis"]);
        return
      };
    }

    let data = {name, desc, pakPath, initGis: gisData};

    if (!this.state.image.file) {
      this.createDesign(data);
      return;
    }

    let key = `meshes/Static/maps_image/${data.pakPath}_${randStr(32)}.${this.state.image.ext}`;

    putObject("design_thumbnail", key, this.state.image.file, link => {
       data.icon = link;
       this.createDesign(data);
    });

//    getSts({kind: "design_thumbnail", key:key}, res => {
//      if (res.code === 0) {
//        // TODO: res.data.provider
//        let sts = res.data.sts;

//        let client = new OSS({
//          accessKeyId: sts.accessKeyId,
//          accessKeySecret: sts.accessKeySecret,
//          region: "oss-" + sts.regionId,
//          bucket: sts.bucket,
//          secure: true,
//          stsToken: sts.securityToken,
//        });

//        client.put(key, this.state.image.file).then(res => {
//          data.icon = res.url;
//          this.createDesign(data);
//        }).catch(err => {
//          message.error("failed to upload image");
//          console.log(`!!! failed to upload image: ${err}`);
//        });
//      } else {
//        message.error("failed to get sts token");
//      }
//    });
  }

  createDesign = (data) => {
    this.setState({show: false});

    designCreate(data,
      res => {
        if (res.code === 0 ) {
          if (this.props.update) this.props.update();
        } else {
          message.error(res.message);
        }
      },
    );
  }

  updateDesign(event) {
    let elem = event.target;
    let design = {...this.state.design};
    design[elem.name] = elem.value;
    this.setState({design: design});
  }

//  updateUser2(value, key) {
//    let user = {...this.state.user}
//    user[key] = value;
//    this.setState({user: user});
//  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return(<>
      <div className="div-button-to-right">
        <button className="btn btn-primary btn-sm query-button button-blue"
          onClick={()=> {
            this.setState({
              show: true,
              image: { file: null, ext: ""},
              design: {
                name: "", pakPath: "", desc: "",
                initGis: defaultInitGis(), icon: "",
              },
            });
         }}
        > {langCommon.actions["create"]}
        </button>
      </div>

      <Modal
        show={this.state.show}
        onHide={() => this.setState({show: false})}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title> {langPage["createProject"]}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <table className="table-input">
            <tbody>
              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span>{langCommon.fields["name"]}: </span>
                </td>

                <td>
                  <input name="name" maxLength="64" placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.design.name} onChange={this.updateDesign.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span>pakPath: </span>
                </td>

                <td>
                  <input name="pakPath" maxLength="255"
                    placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.design.pakPath} onChange={this.updateDesign.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span>{langCommon.fields["description"]}: </span>
                </td>

                <td>
                  <textarea name="desc" cols="30" rows="7"
                    placeholder={langCommon.placeholder["enter"]}
                    className="modal-text"
                    value={this.state.design.desc} onChange={this.updateDesign.bind(this)}
                  ></textarea>
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span>{langPage["gisData"]}: </span>
                </td>

                <td>
                  <textarea name="initGis" cols="30" rows="7"
                    placeholder={langCommon.placeholder["enter"]}
                    className="modal-text"
                    value={this.state.design.initGis} onChange={this.updateDesign.bind(this)}
                  ></textarea>
                </td>
              </tr>

              <tr>
                <td> {langCommon.fields["thumbnail"]}:</td>
                <td>
                  <BrowseThumbnail
                    thumbnail={this.state.design.icon}
                    updateImage={(val) => this.setState({image: val})}
                  />
                </td>
              </tr>
            </tbody>

           <tfoot className="modal-tfoot">
             <tr> <td colSpan="2"> {this.state.errMsg} </td> </tr>
           </tfoot>
         </table>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={() => this.setState({show: false})}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm btn-blue button-blue"
            onClick={this.handleSubmit.bind(this)}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default NewDesign;
