import React, { Component } from 'react';
import { Select } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { sprintf } from "sprintf-js";

import { getSet } from "locales/index.js";
import 'css/content.css';

const { Option } = Select;


class Page extends Component {
  constructor (props) {
    super(props); // page, pageSize, totalItems, pageSizes, updatePage

    this.state = { langCommon: getSet("common") };
  }

  pageAdd(num) {
     let value = this.props.page + num;
     if (value < 1 || value > this.getTotalPages()) {
       return;
     }

     this.props.updatePage({page: value});
  }

  getTotalPages() {
    if (this.props.pageSize === 0) {
      return 0;
    }
    return Math.ceil(this.props.totalItems/this.props.pageSize);
  }

  renderSelect() {
    let langCommon = this.state.langCommon;
    let pageSizes = this.props.pageSizes;

    if (!this.props.updatePage || !pageSizes || pageSizes.length === 0) {
      return (<></>)
    }

    return (
      <Select style={{textAlign:'left', fontSize:'14px', marginLeft:'5px'}}
        size="small" defaultValue={pageSizes[0]}
        value={this.props.pageSize}
        onChange={value => this.props.updatePage({pageSize: value}) }
      >
        {
          pageSizes.map((size, idx) =>
            <Option key={idx} value={size}> {size} {langCommon.page["pageSize"]} </Option>
          )
        }
      </Select>
    )
  }

  render () {
    let langCommon = this.state.langCommon;

    let n1 = (this.props.page -1)*this.props.pageSize + 1;
    if (this.props.totalItems === 0) n1 = 0;

    let n2 = this.props.page*this.props.pageSize;
    if (n2 > this.props.totalItems) n2 = this.props.totalItems;

    let nn = n1 > 0 ? sprintf(langCommon.page.items + "/", n1, n2) : "";

    return (<>
      <div className="page">
        <span> {nn}{sprintf(langCommon.page.total, this.props.totalItems)} </span>

        <LeftOutlined onClick={this.pageAdd.bind(this, -1)} />
        <span> {this.props.page} </span>
        <RightOutlined onClick={this.pageAdd.bind(this, 1)} />

        {this.renderSelect()}
      </div>
    </>)
  }
}

export default Page;
