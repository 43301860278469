import enUS from "./en-US/index.js"; // "./en-US";
import zhCN from "./zh-CN/index.js"; // "./zh-CN";
import ja from "./ja/index.js";      // "./ja";
import { setHeader } from "js/base.js";

let _Language = {};

export function loadLang(lang) {
  // console.log(`~~~ navigator.language: ${lang}, ${navigator.language}`);
  switch (lang) {
  case "zh-CN":
    setHeader("X-Language", "Chinese");
    _Language = zhCN;
    break;
  case "ja":
    setHeader("X-Language", "Japanese");
    _Language = ja;
    break;
  default: // "en-US"
    setHeader("X-Language", "English");
    _Language = enUS;
  }

  return _Language;
}

export function getSet(key) {
  return _Language[key] || {};
}
