import React, { Component } from "react";
// import Datetime from "react-datetime";
import { Select, Cascader, message } from "antd";
import { Modal } from 'react-bootstrap';
import { sprintf } from "sprintf-js";

import Page from "components/page.jsx";
import Table from "./table.jsx";
import New from "./new.jsx";
import { getSet } from "locales/index.js";
import { processAttrs } from "js/assets.js";
import { assetGetAttrs, companyAssetQuery, bepacketDataCreate } from "js/api4artist.js";
import { companyAssetBatchUpdateStatus } from "js/api4artist.js";
import "css/content.css";
import 'css/modal.css';
import 'css/head.css';

const { Option } = Select;


class Assets extends Component {
  constructor (props) {
     super(props);

     this.state = {
       langCommon: getSet("common"),
       langPage: getSet("assets"),
       browseNodes: [],
       subbrowseMap: null,
       categories: [],
       assetKinds: [],
       commodityCategories: [],
       commodityCategoryMap: null,
       showUpdateStatus: false,

       query: {
         page: 1,  pageSize: 10, name: "", browseId: "", subbrowseId: "",
         subcategoryId: "", status: null, orderBy: "", ascending: "",
       },
       items: [], totalItems: 0,
       pageSizes: [10, 15, 20, 30],
       updateStatusData: {targetIds: [], status: "", status2: "", status2List: []},
       selectAll: false,

       queryAsset: this.queryAsset.bind(this),
       updatePageNum: this.updatePageNum.bind(this),
       updatePageSize: this.updatePageSize.bind(this),
       updateOrders: this.updateOrders.bind(this),
       selectType: this.selectType.bind(this),
       updateItem: this.updateItem.bind(this),
       resetQuery: this.resetQuery.bind(this),
     };

     this.createPack = this.createPack.bind(this);
     this.selectItem = this.selectItem.bind(this);
     this.batchUpdateStatus = this.batchUpdateStatus.bind(this);
  }

  resetQuery() {
    let query = {
       page: 1,  pageSize: 10, name: "", browseId: "", subbrowseId: "",
       subcategoryId: "", status: null, orderBy: "", ascending: "",
    };

    this.setState({
       query: query,
       updateStatusData: {targetIds: [], status: "", status2: "", status2List: []},
       selectAll: false,
     });

    companyAssetQuery(query, res => {
      if (res.code === 0) {
        this.processItems(res.data.items);
        this.setState({items: res.data.items});

        if (res.data.items.length === 0 || res.data.totalItems > 0) {
          this.setState({totalItems: res.data.totalItems});
        }
      }
    })

    let list = ["browseNodeSel", "categorySel"];

    list.forEach(id => {
      let elem = document.getElementById(id);
      if (elem && elem.previousSibling) {
        elem.previousSibling.innerText = "";
      }
    })
  }

  componentDidMount() {
    let langPage = this.state.langPage;
    document.title = langPage["sideNav"];

    /*
    let fn1 = async () => {
      browseNodeInfoQuery(res => {
        if (res.code === 0) this.setState({browseNodes: res.data.items});
      });
    };

    let fn2 = async () => this.queryAsset();

    fn1(); fn2();
    */
    // 避免账号禁用导致 2 次提示用户禁止登录
    assetGetAttrs(null, res => {
      if (res.code === 0) {
        // this.setState({browseNodes: res.data.items});
        let data = processAttrs(res.data);
        this.setState(data);
        this.queryAsset();
      }
    })
  }

  updateItem(data) {
    // console.log(`~~~ ${JSON.stringify(data)}`);
    // {"assetId":"SM_planter_1000001","browseId":"10014","subbrowseId":["100140008"]}
    let items = this.state.items;
    let idx = items.findIndex(e => e.assetId === data.assetId);
    if (idx === -1) {
      return;
    }

    let item = {...items[idx], ...data};
    if (data.subbrowseIds) {
      item.browseNodes = data.subbrowseIds.map(e => this.state.subbrowseMap[e]);
    }
    items[idx] = item;
    this.setState({items: items});
  }

  updateQuery(event) {
    let query = {...this.state.query};
    query[event.target.name] = event.target.value;
    this.setState({query: query});
  }

  updateQuery2(value, name) {
    // console.log(`~~~ ${value}, ${name}`);
    let query = {...this.state.query};
    query[name] = value;
    this.setState({query: query});
  }

  selectType(value) {
    let query = {...this.state.query};
    if (value.length > 1) {
      query.browseId = value[0];
      query.subbrowseId = value[1];
    } else if (value.length === 1){
      query.browseId = value[0];
      query.subbrowseId = "";
    } else {
      query.browseId = "";
      query.subbrowseId = "";
    }

    this.setState({query: query});
  }

  processItems(items) {
    // let list = ["预发布", "上架", "下架"];

    items.forEach(item => {
      // item._selected = list.indexOf(item.status) > -1 ? -1 : 0;
      item._selected = -1;
    });
  }

  updatePageSize(size) {
    if (size <= 0 || size === this.state.query.pageSize) {
      return;
    }

    let query = {...this.state.query};
    query.pageSize = size;
    this.setState({query: query});

    companyAssetQuery(query, res => {
      if (res.code === 0) {
        this.processItems(res.data.items);
        this.setState({items: res.data.items, selectAll: false});
      }
    })
  }

  updatePageNum(num) {
    let query = {...this.state.query}
    query.page = num;
    companyAssetQuery(query, res => {
      if (res.code === 0) {
        this.processItems(res.data.items);
        this.setState({query: query, items: res.data.items, selectAll: false});
      }
    })
  }

  queryAsset() {
    // console.log(this.state.query);
    let query = {...this.state.query, page: 1};
    query.name = query.name.trim();
    this.setState({query: query});

    companyAssetQuery(query, res => {
      if (res.code === 0) {
        this.processItems(res.data.items);

        this.setState({items: res.data.items});
        if (res.data.items.length === 0 || res.data.totalItems > 0) {
          this.setState({totalItems: res.data.totalItems});
        };
      }
    })
  }

//  selectItem(assetId) {
//    let items = this.state.items;
//    let selectAll = this.state.selectAll;

//    if (assetId === "_ALL") {
//      selectAll = !selectAll;
//      items.forEach(e => {
//        if (e._selected !== 0) {
//          e._selected = selectAll ? 1 : -1;
//          // alert(`${e.assetId}, ${e._selected}`);
//        }
//      });
//    } else {
//      let idx = items.findIndex(x => x.assetId === assetId);
//      if (idx === -1) {
//        return;
//      }
//      if (items[idx]._selected === -1) {
//        items[idx]._selected = 1;
//      } else if (items[idx]._selected === 1) {
//        items[idx]._selected = -1;
//      } else {
//        return;
//      }
//      // alert(`${assetId}, ${items[idx]._selected}`);
//    }

//    this.setState({items: items, selectAll: selectAll});
//  }

  selectItem(assetId) {
    if (assetId === "_ALL") {
      this.selectItemAll();
    } else {
      this.selectItemConcrete(assetId)
    }
  }

  selectItemAll() {
    let items = this.state.items;
    if (items.length === 0) {
      return
    }

    let item = items.find(e => e._selected === 1), targets = [];

    if (item) {
      items.forEach(e => {
        if (e.status === item.status) { targets.push(e); }
      });
    } else {
      items.forEach(e => {
        if (e.status === items[0].status) { targets.push(e); }
      });

      if (targets.length !== items.length) { // not all items share the same status
        // message.warn("请先选择一个特定状态的模型!");
        return;
      }
    }

    if (this.state.selectAll === true) {
      items.forEach(e => e._selected = -1 ); // for e._selected === 0
      this.setState({selectAll: false, items: items});
    } else {
      targets.forEach(e => e._selected = 1 );
      this.setState({selectAll: true, items: items});
    }
  }

  selectItemConcrete(assetId) {
    let items = this.state.items;
    let selectAll = this.state.selectAll;

    if (items.length === 0) {
      return
    }

    let idx = items.findIndex(x => x.assetId === assetId);
    if (idx === -1) {
      return;
    }

    let status = items[idx].status;

    if (items[idx]._selected === 0) {
      return;
    } else if (items[idx]._selected === -1) {
      items[idx]._selected = 1;
      items.forEach(e => { if (e.status !== status) e._selected = 0;});
    } else { // items[idx]._selected === 1
      items[idx]._selected = -1;
      selectAll = false;
      if (!items.find(e => e._selected === 1)) {
        items.forEach(e => e._selected = -1);
      }
    };

    // alert(`${assetId}, ${items[idx]._selected}`);

    this.setState({items: items, selectAll: selectAll});
  }

  updateOrders(name) {
    if (name === "") {
      return
    }

    let query = {...this.state.query};

    // "" -> "true" -> "false" -> ""
    if (query["orderBy"] !== name) {
      query["orderBy"] = name;
      query["ascending"] = "true";
    } else if (query["ascending"] === "") {
      query["ascending"] = "true";
    } else if (query["ascending"] === "true") {
      query["ascending"] = "false";
    } else {
      query["ascending"] = "";
    }

    this.setState({query: query}, this.state.queryAsset);
  }

//          <select name="browsenodes" style={{ width: 180, textalign: 'left' }}
//            defaultvalue="请选择" value={this.state.query.browseid}
//            onchange={(value) => this.updatequery2(value, "browseid") }
//          >
//            <option value="">请选择</option>
//            {
//              this.state.browsenodes.map(item => {
//                return (<option key={item.browseid}> {item.browsename} </option>)
//              })
//            }
//          </select>

//  createPack() {
//    // let targetIds = this.state.items.filter(e => e._selected === 1).map(e => e.assetId);
//    let items = this.state.items;
//    let idxList = [], targetIds = [];

//    items.forEach((e, idx) => {
//      if (["预发布", "上架", "下架"].indexOf(e.status) > -1 && e._selected === 1) {
//        targetIds.push(e.assetId);
//        idxList.push(idx);
//      }
//    });

//    if (targetIds.length > 0) {
//      if (!window.confirm(`确认将模型 ${targetIds.join(", ")} 修改为 "待打包" 状态？`)) {
//        return;
//      }
//    } else {
//      if (!window.confirm(`确认创建批量打包？`)) {
//        return;
//      }
//    }

//    bepacketDataCreate({targetIds: targetIds},
//      res => {
//        if (res.code === 0 ) {
//          this.setState({lastPacket: res.data.item.createTimestamp});
//          if (targetIds.length > 0) {
//            idxList.forEach(idx => {
//              items[idx].status = "待打包";
//              items[idx]._selected = 0;
//            });
//            this.setState({items: items});
//            alert(`设置重新打包成功！`);
//          } else {
//            alert(`创建打包成功！`);
//          }
//        } else {
//          alert(res.message);
//        }
//      },
//    );
//  }

  createPack() {
    let langPage = this.state.langPage;
    if (!window.confirm(langPage["tipCreatePackage"])) {
      return;
    }

    bepacketDataCreate(null,
      res => {
        if (res.code === 0 ) {
          message.success("ok");
        } else {
          message.error(res.message);
        }
      },
    );
  }

  batchUpdateStatus() {
    let items = this.state.items.filter(e => e._selected === 1);
    if (items.length === 0) {
      return;
    }

    let status = items[0].status, status2List = [];
    if (status === "待打包") {
      items = this.state.items;
      items.map(e => e._selected = -1);
      this.setState({items: items, selectAll: false});
      // message.warn('不能修改 "待打包" 状态的模型！');
      return
    }

    let targetIds = items.map(e => e.assetId)
    // console.log(`status: ${status}, items: ${JSON.stringify(targetIds)}`);

    if (status === "预发布") {
      status2List = ["待打包", "上架"];
    } else if (status === "上架") {
      status2List = ["待打包", "下架"];
    } else if (status === "下架") {
      status2List = ["待打包", "上架"];
    }

    this.setState({updateStatusData: {targetIds, status, status2List}, showUpdateStatus: true});
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;
    // console.log(this.state.query);
    let data = this.state.updateStatusData;

    return (<>
      <Modal
        show={this.state.showUpdateStatus}
        onHide={() => {
          let updateStatusData = this.state.updateStatusData;
          updateStatusData.status2 = "";
          this.setState({
            showUpdateStatus: false, updateStatusData: updateStatusData,
          });
       }}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered modal-promot"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title> {langPage["changeStatus"]} </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{marginTop: "30px", marginButtom: "20px"}}>
            <p style={{fontSize: "16px"}} title={data.targetIds.join(", ")}>
              {" " + sprintf(
                langPage["tipChangeStatusOfSelectedAsset"],
                data.targetIds.length,
                langCommon.status[data.status],
              )}
            </p>

            {this.state.updateStatusData.status2List.map((e) =>
              <p style={{marginLeft: "20px"}}>
                <input type="radio" value="{e}" name="{e}" onClick={() => {
                  data.status2 = e;
                  this.setState({updateStatusData: data});
                }}/>
                {/*" " + e + ( e === "待打包" ? "（请先上传打包资源）" : " ")*/}
                {" " + langCommon.status[e] }
              </p>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={() => {
              let updateStatusData = this.state.updateStatusData;
              updateStatusData.status2 = "";
              this.setState({
                showUpdateStatus: false, updateStatusData: updateStatusData,
              })
            }}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm button-blue"
            onClick={() => {
              let data = this.state.updateStatusData;
              if (!data.status2 || data.targetIds.length === 0) {
                return;
              }

              this.setState({showUpdateStatus: false}, () => {
                companyAssetBatchUpdateStatus(data, res => {
                  if (res.code === 0) {
                    let items = this.state.items;
                    items.forEach( e => {
                      if (data.targetIds.indexOf(e.assetId) > -1) {
                        e.status = data.status2;
                      }
                      e._selected = -1;
                    });

                    this.setState({items: items, selectAll: false});

                    message.success("ok");
                  } else {
                    message.error("failed");
                  }
                })
              })
            }}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>

      <div className='user-query'>
        <div className="input-parameter">
          <span> {langCommon.fields["name"]}: </span>
          <input type="text" name="name"
            placeholder={langCommon.placeholder["enter"]}
            value={this.state.query.name}
            onChange={this.updateQuery.bind(this)}
          />
        </div>

        <div className="input-parameter">
          <span> {langCommon.fields["browse"]}: </span>
          <Cascader
            id="browseNodeSel"
            changeOnSelect
            style={{textAlign: 'left', width: '180px'}}
            value={
              this.state.query.browseId ?
                [this.state.query.browseId, this.state.query.subbrowseId] :null
            }
            options={this.state.browseNodes}
            onChange={this.state.selectType}
            placeholder={langCommon.placeholder["select"]}
          />
        </div>

        <div className="input-parameter">
          <span> {langCommon.fields["category"]}: </span>
          <Cascader
            id="categorySel"
            changeOnSelect
            style={{textAlign: 'left', width: '180px'}}
            value={
              this.state.query.categoryId ?
                [this.state.query.categoryId, this.state.query.subcategoryId] : null
            }
            options={this.state.categories}
            onChange={(value) => {
              // console.log("~~~", value);
              let query = {...this.state.query};
              if (value.length > 1) {
                query.categoryId = value[0];
                query.subcategoryId = value[1];
              } else if (value.length === 1){
                query.categoryId = value[0];
                query.subcategoryId = "";
              } else {
                query.categoryId = "";
                query.subcategoryId = "";
              }
              this.setState({query: query});
            }}
            placeholder={langCommon.placeholder["select"]}
          />
        </div>

        <div className="input-parameter">
          <span> {langCommon.fields["status"]}: </span>
          <Select
            style={{ width: 180, textAlign: 'left' }}
            name="status"
            allowClear
            placeholder={langCommon.placeholder["select"]}
            value={this.state.query.status}
            onChange={value => this.updateQuery2(value, "status")}
          >
            {["待打包", "预发布", "上架", "下架"].map(e => (
              <Option value={e}>{langCommon.status[e]}</Option>
            ))}
           </Select>
        </div>

        <div className="div-button-to-right">
          <button className="btn btn-secondary btn-sm button-blank query-button"
            onClick={this.resetQuery.bind(this)}> {langCommon.actions["reset"]}
          </button>

          <button className="btn btn-primary btn-sm query-button button-blue"
            onClick={this.queryAsset.bind(this)}> {langCommon.actions["search"]}
          </button>
        </div>
      </div>

      <div className="content-area">
        <div className="content-top">
          <div>{langPage["tableTitle"]}</div>
          <div className="div-button-to-right">
            <New
              browseNodes={this.state.browseNodes}
              subbrowseMap={this.state.subbrowseMap}
              categories={this.state.categories}
              assetKinds={this.state.assetKinds}
              commodityCategories={this.state.commodityCategories}
              resetQuery={this.state.resetQuery}
              createPack={this.createPack}
              batchUpdateStatus={this.batchUpdateStatus}
            />
          </div>
        </div>

        <Table
          items={this.state.items}
          selectAll={this.state.selectAll}
          browseNodes={this.state.browseNodes}
          commodityCategories={this.state.commodityCategories}
          commodityCategoryMap={this.state.commodityCategoryMap}
          subbrowseMap={this.state.subbrowseMap}
          updateOrders={this.state.updateOrders}
          query={this.state.query}
          updateItem={this.state.updateItem}
          afterDelete={() => {
            this.state.queryAsset();
            this.setState({totalItems: this.state.totalItems - 1});
          }}
          selectItem={this.selectItem}
//          updateAreas={(assetId, areas) => {
//             let items = this.state.items;
//             let idx = items.findIndex(e => e.assetId === assetId);
//             if (idx < 0) {
//               return;
//             }
//             items[idx].areas = areas;
//             this.setState({items: items});
//          }}
        />

        <Page
          page={this.state.query.page}
          pageSize={this.state.query.pageSize}
          totalItems={this.state.totalItems}
          updatePageNum={this.state.updatePageNum}
          pageSizes={this.state.pageSizes}
          updatePageSize={this.state.updatePageSize}
        />
      </div>
    </>)
  }
}

export default Assets;
