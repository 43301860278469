import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Cascader, Select, message } from "antd";

import Areas from "./areas.jsx";
import { getSet } from "locales/index.js";
import { companyAssetCreate } from "js/api4artist.js";
import { validAreas } from "js/assets.js";
// import { toLocalDatetime } from "js/utils.js";
import "css/modal.css";

const { Option } = Select;


class New extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("assets"),
      onSubmit: false, show: false, errMsg: "",
      asset: {
        assetName: "", assetCode: "", categoryId: "", subcategoryId: "",
        browseIds: [], subbrowseIds:[], areas: [],
      },
    };
  }

  componentDidMount() {
  }


  clearInput() {
    this.setState({
      onSubmit: false, show: false, errMsg: "",
      asset: {
        assetName: "", assetCode: "", categoryId: "", subcategoryId: "",
        browseIds: [], subbrowseIds:[], areas: [],
      }
    })
  }

  handleSubmit() {
    if (this.state.onSubmit) {
      return;
    }

    let langPage = this.state.langPage;
    let data = {...this.state.asset};

    if (!data.assetCode || !data.subcategoryId || data.subbrowseIds.length === 0) {
      message.warn(langPage["tipEnterAssetInfo"]);
      return;     
    }

    data.assetName = data.assetName.trim();
    // if (!/^[\p{Script=Han}a-zA-Z0-9-_ ]{1,64}$/u.test(data.assetName)) {
    if (data.assetName.length === 0 || data.assetName.length > 64) {
      message.warn(langPage["tipInvalidAssetName"]);
      return;
    }

    let res = validAreas(this.state.asset.areas); // [err, p]
    if (res[0]) {
      // console.log(`!!! validAreas: ${err}, ${p}`);
      message.warn(res[0]);
      return;
    }

    // console.log("+++", this.state.asset);
    this.setState({onSubmit: true, errMsg: ""});

    companyAssetCreate(data,
      res => {
        this.setState({onSubmit: false});
        if (res.code === 0 ) {
          this.clearInput();
          this.props.resetQuery();
          message.success("ok");
        } else {
          message.error(res.message);
        }
      },
      () => this.setState({onSubmit: false}),
    )
  }

  updateAsset(event) {
    let elem = event.target;
    let asset = {...this.state.asset};
    asset[elem.name] = elem.value;
    this.setState({asset: asset});
  }

  updataBrowseNode(value) {
    let asset = {...this.state.asset};
    let browseIds = value.map(
      e => this.props.subbrowseMap[e] ? this.props.subbrowseMap[e].browseId : "??"
    );

    asset.subbrowseIds = [...value];
    asset.browseIds = browseIds;
    this.setState({asset: asset});
  }

  updateItem = (key, val) => {
    // console.log(`~~~ ${key}, ${JSON.stringify(val)}`);
    let asset = {...this.state.asset};
    asset[key] = val;
    this.setState({asset});
  }

  renderBrrowseNodes() {
    let langCommon = this.state.langCommon;

    let options = this.state.asset.subbrowseIds.map(e => {
      let node = this.props.subbrowseMap[e];
      let label = node ? node.browseName + " / " + node.subbrowseName : "?/?";
      return (<Option value={e} label={label}> {label} </Option>)
    })

    return (<tr>
      <td></td>
      <td>
        <Select
          mode="multiple"
          style={{ width: '272px' }}
          allowClear
          placeholder={langCommon.placeholder["select"]}
          defaultValue={[]}
          value={this.state.asset.subbrowseIds}
          onChange={this.updataBrowseNode.bind(this)}
        >
          {options}
        </Select>
      </td>
    </tr>)
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return(<>
      <div className="div-button-to-right">
        <button className="btn btn-primary btn-sm query-button button-blue"
          onClick={this.props.batchUpdateStatus}> {langPage["changeStatus"]}
        </button>

        <button className="btn btn-primary btn-sm query-button button-blue"
          onClick={this.props.createPack}> {langPage["package"]}
        </button>

        <button className="btn btn-primary btn-sm query-button button-blue"
          onClick={()=> {
            this.setState({show: true});
          }}
        > {langCommon.actions["create"]}
        </button>
      </div>

      <Modal
        show={this.state.show}
        onHide={this.clearInput.bind(this)}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title>{langPage["createAsset"]}</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{overflowY: "scroll"}}>
          <table className="table-input">
            <tbody>
              <tr>
                <td colSpan="2" style={{textAlign:"left"}}>
                  <span style={{fontWeight:"bold"}} >{langPage["assetBasics"]}</span>
                  {" "}({langPage["assetBasicsDetail"]})
                </td>
              </tr>

              <tr>
                 <td>
                   <span style={{color: 'red'}}> * </span>
                   <span>{langCommon.fields["attribute"]}: </span>
                 </td>
                 <td>
                 <Select
                  // mode="multiple"
                  style={{ width: '272px' }}
                  // allowClear
                  placeholder={langCommon.placeholder["select"]}
                  // defaultValue={[]}
                  // value={this.state.asset.subbrowseIds}
                  onChange={(v) => {
                    let asset = {...this.state.asset}
                    asset.assetCode = v;
                    this.setState({asset: asset})
                  }}
                 >
                   {/*this.props.assetKinds.map(e => {
                     return (<Option value={e.value} label={e.label}> {e.label} </Option>)
                   })*/}
                   {this.props.assetKinds.map(e =>
                     <Option value={e.value}
                       label={langCommon.assetKinds[e.value]}>
                       {langCommon.assetKinds[e.value]}
                     </Option>
                   )}
                  </Select>
                </td>
              </tr>
            
              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span>{langCommon.fields["category"]}: </span>
                </td>

                <td>
                  <Cascader
                    // changeOnSelect
                    style={{width: "272px", color: "grey"}}
                    options={this.props.categories}
                    placeholder={langCommon.placeholder["select"]}
                    onChange={(v) => {
                      let asset = {...this.state.asset}
                      asset.categoryId = v[0];
                      asset.subcategoryId = v[1];
                      this.setState({asset: asset});
                    }}
                  />
                </td>
              </tr>

              <tr>
                 <td>
                   <span style={{color: 'red'}}> * </span>
                   <span>{langCommon.fields["name"]}: </span>
                 </td>
                 <td>
                   <input name="assetName" maxLength="64" placeholder="1~64 charaters"
                    className="modal-input"
                    title={this.state.asset.assetName}
                    value={this.state.asset.assetName}
                    onChange={this.updateAsset.bind(this)}
                  />
                 </td>
              </tr>


              <tr>
                <td colSpan="2" style={{textAlign:"left"}}>
                  <span style={{fontWeight:"bold"}}>
                    {langCommon.fields["browse"]}
                  </span> ({langPage["tip2"]})
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span>{langPage["browseNode"]}: </span>
                </td>
                <td>
                  <Cascader
                    // changeOnSelect
                    style={{width: "272px", color: "grey"}}
                    options={this.props.browseNodes}
                    placeholder={langCommon.placeholder["add"]}
                    onChange={value => {
                      // console.log("~~~", value);
                      if (value.length === 0) {
                        return;
                      }
                      if (this.state.asset.subbrowseIds.indexOf(value[1]) !== -1) {
                        return;
                      }

                      let asset = {...this.state.asset};
                      asset.subbrowseIds.push(value[1]);
                      if (asset.browseIds.indexOf(value[0]) === -1) {
                        asset.browseIds.push(value[0]);
                      }
                      this.setState({asset: asset});
                    }}
                  />
                </td>
              </tr>

              {this.renderBrrowseNodes()}

              <Areas
                assetId={this.state.asset.assetId}
                areas={this.state.asset.areas}
                updateItem={this.updateItem}
              />

            </tbody>
         </table>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.clearInput.bind(this)}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm btn-blue button-blue"
            onClick={this.handleSubmit.bind(this)}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default New;
