let records = {
  sideNav: "账户管理",
  tableTitle: "账户列表",
  role: "角色类型",
  "引擎开发": "引擎开发",
  "引擎美术": "引擎美术",
  "引擎测试": "引擎测试",
  "交付工程师": "交付工程师",
  accountType: "用户级别",
  editAccount: "编辑帐号",
  createAccount: "创建帐号",
  wrongPhoneOrEmail: "手机号码或邮箱错误",
  wrongDateRange: "起始日期大于终止日期",
  editYes: "可编辑",
  editNo: "不可编辑",
  tipDelete: "删除 %1$s 将无法恢复，确认要删除该账号吗?",
  permissions: "权限",
  tipEnterName: "请输入姓名",
  tipNameLength: "姓名长度不能超过 30",
  tipInvalidPhone: "手机号码不合法",
  tipInvalidEmail: "电子邮箱不合法",
  tipEnterPhoneOrEmail: "请设置手机号码或者电子邮箱",
  tipInvalidPassword: "密码只能包含 0-9, a-z, A-Z, 长度范围是 6-20",
  tipPasswordNotMatch: "密码不匹配",
  tipEnterRole: "请置角色类型",
  passwordConfirmation: "确认密码",
};

export default records;
