import React, { Component } from "react";
import { message } from "antd";
import { Modal } from "react-bootstrap";
import { sprintf } from "sprintf-js";

import path from "path";
import BrowseThumbnail from "components/browse_thumbnail.jsx";
import putObject from "components/put_object.jsx";
import { getUserId } from "js/base.js";
import { userAssetUpdate } from "js/api4user.js";
import { randStr } from "js/utils.js";
import { getSet } from "locales/index.js";
import "css/modal.css";


class EditAsset extends Component {
  constructor (props) {
    super(props); // id, name, link, thumbnail, updateAsset

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("userAssets"),

      onSubmit: false, show: false,
      asset: {
        id: this.props.id,
        name: this.props.name,
        thumbnail: this.props.thumbnail,
      },
      image: { file: null, ext: ""},
    };
  }

  clearInput() {
    this.setState({
      onSubmit: false, show: false, tip: "",
      asset: {
        id: this.props.id,
        name: this.props.name,
        thumbnail: this.props.thumbnail,
      },
    });
  }

  handleSubmit() {
    let langCommon = this.state.langCommon;

    if (this.state.onSubmit) {
      return;
    }

    let data = {id: this.props.id}, changed = false;

    if (this.state.asset.name.length === 0) {
      this.setState({tip: langCommon.tips["enterName"]});
      return;
    }
//    if (!/^[\p{Script=Han}a-zA-Z0-9-_]+$/u.test(this.state.asset.name)) {
//      this.setState({tip: "模型名称只可包含汉字、字母、数字、下划线（_）、减号（-）！"});
//      return;
//    }

    let l = this.state.asset.name.length;
    if (l === 0 || l > 64) {
      message.warn(langCommon.tips["invalidName"]);
      return;
    }

    ///
    if (this.state.asset.name !== this.props.name) {
      data["name"] = this.state.asset.name;
      changed = true;
    }

    if (this.state.image.file) {
      changed = true;
    }

    if (!changed) { // name 和 thumbnail 都没有变动
      this.setState({show: false});
      return;
    }

    ///
    if (!this.state.image.file) {
      this.updateAsset();
      return;
    }

    let basename = path.basename(this.props.link).split(".")[0]; // this.props.packagePath
    let suffix = "." + this.state.image.file.type.split("/").pop();
    // 避免使用相同 url 导致更新 asset 时不更新显示图片
    let key = `meshes/PrivateModels/${getUserId()}/` + basename +`_${randStr(16)}` + suffix;

    putObject("user_asset", key, this.state.image.file, link => {
      let asset = {...this.state.asset};
      asset.thumbnail = link;
      this.setState({asset: asset}, this.updateAsset);
    });
  }

  updateAsset() {
    let langCommon = this.state.langCommon;

    this.setState({tip: "", onSubmit: true}, userAssetUpdate(this.state.asset,
      res => {
        this.setState({onSubmit: false, tip: ""});
        if (res.code === 0 ) {
          this.props.updateAsset(this.state.asset); // 先后顺序不能反
          this.clearInput();
        } else {
          message.error(langCommon.tips["failedToUpdate"]);
        }
      },
      () => this.setState({onSubmit: false}),
    ))
  }

  updateValue(event) {
    let elem = event.target;
    let asset = {...this.state.asset};
    asset[elem.name] = elem.value;
    this.setState({asset: asset});
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return(<>
      <span className="span-button" onClick={() => this.setState({show: true})}>
        {langCommon.actions["edit"]}
      </span>

      <Modal
        show={this.state.show}
        onHide={this.clearInput.bind(this)}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title>{langPage["editAsset"]}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="upload-field-title"> {langCommon.fields["name"]}</div>
          <div>
            <input type="text" maxLength="15"
              placeholder={sprintf(langCommon.placeholder["chars"], 15)}
              className="modal-input modal-input-fname"
              name="name"
              value={this.state.asset.name}
              title={this.state.asset.name}
              onChange={this.updateValue.bind(this)}
            />
          </div>

          <br></br>
          <div className="upload-field-title">
            {langCommon.fields["thumbnail"]} (support jpeg and png format, file size limit is 2MB):
          </div>

          <BrowseThumbnail
            thumbnail={this.state.asset.thumbnail}
            updateImage={(val) => this.setState({image: val})}
          />
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.clearInput.bind(this)}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm btn-blue button-blue"
            onClick={this.handleSubmit.bind(this)}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default EditAsset;
