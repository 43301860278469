import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';

import Login from './pages/Login.jsx';
import Manage from './pages/Manage.jsx';
import packageJson from '../package.json';
import { newPath } from "js/base.js";
import { loadLang } from "locales/index.js";
import './App.css';

function App() {
  // console.log(`~~ ${localStorage.getItem("Language")}, ${navigator.language}`);

  let lang = localStorage.getItem("Language") || navigator.language; // "zh-CN";
  window.UILanguage = loadLang(lang);

  return (
    <div className="App"
      data-app-version={packageJson.version}
      data-app-env={process.env.REACT_APP_ENV}
      data-build-time={process.env.REACT_APP_BuildTime}
    >
      <Router basename={newPath()}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/manage" component={Manage} />
          <Route path="/" component={Login} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
