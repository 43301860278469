import React, {Component} from "react";
import { message } from "antd";

import EmptyThumbnail from "img/empty_thumbnail.png";
import { getSet } from "locales/index.js";


class BrowseThumbnail extends Component {
  constructor (props) {
    super(props); // updateImage({}), id, thumbnail

    this.state = {
      langCommon: getSet("common"),
      imageData: null,
    };
  }

  componentDidMount() {
    // console.log("~~~ componentDidMount");
  }

  componentDidUpdate() {
    // console.log("~~~ componentDidUpdate");
  }

  handleThumbnail = (event) => {
    let files = event.target.files;
    if (files.length === 0 ) {
      return;
    }
    let target = files[0];
    if (target.size === 0) {
      message.warn("empty image file");
      return;
    }
    if (target.size > 2*1024*1024) {
      message.warn("thumbnnail file size exceeds 2MB");
      return;
    }

    if (!["image/png", "image/jpeg", "image/webp"].includes(target.type)) {
      message.warn("please use a png, jpeg or webp image");
      return;
    }

    let fr = new FileReader();
    fr.readAsDataURL(target);
    fr.onload = () => {
      this.setState({imageData: fr.result});
      this.props.updateImage({file: files[0], ext: target.type.replace("image/", "")});
    }
  }

  render() {
    let langCommon = this.state.langCommon;

    return (<div>
      <input style={{display: "none"}} id={this.props.id} className="modal-input" type="file"
        onChange={this.handleThumbnail.bind(this)}
      />

      <img width="160px"
        title={langCommon.fields["clickToUpload"]}
        alt={langCommon.fields["clickToUpload"]}
        src={this.state.imageData || this.props.thumbnail || EmptyThumbnail}
        onClick={(event) => event.target.previousSibling.click()}
      />
    </div>)
  }
}

export default BrowseThumbnail;
