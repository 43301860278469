import React, { Component } from "react";
import { message } from "antd";
import { BiSort, BiSortDown, BiSortUp } from "react-icons/bi";
import { sprintf } from "sprintf-js";

import Prompt from "components/prompt.jsx";
import Edit from "./edit.jsx";
import { getSet } from "locales/index.js";
import { designUpdateStatus } from "js/api4admin.js";
import { toLocalDatetime, fixStrLen } from "js/utils.js"; // trimString


class Table extends Component {
  constructor (props) {
    super(props); // items, updateItem, updateOrders

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("designs"),
      target: null, promptMsg: "", promptDelete: false,
      // promptAlertStatus: false,
    };
  }

  alertStatus(target) {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;
    let newStatus = target.status !== "上架" ? "上架" : "下架";

    this.setState({
      target: target, promptAlertStatus: true,
      promptMsg: sprintf(langPage["tipChangeStatus"], target.name, langCommon.status[newStatus]),
    });
  }

  renderSortIcon(name) {
    // console.log("--> renderOrder:", name, this.props.ascending);
    if (name !== this.props.query.orderBy || this.props.query.ascending === "") {
      return (<BiSort size={18} color="grey"/>)
    } else if (this.props.query.ascending === "true") {
      return (<BiSortUp size={18} color="grey"/>)
    } else {
      return (<BiSortDown size={18} color="grey"/>)
    }
  }

  renderRows() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return this.props.items.map((item, idx) =>
      <tr key={item.id} id={item.id} data-index={idx} name={item.name}>
        <td style={{cursor:'pointer'}} title={"id:" + item.id}> {item.name} </td>
        <td> <img alt="thumbnail" src={item.icon} /> </td>
        <td> {item.pakPath} </td>
        <td title={"init_gis data: " + JSON.stringify(item.initGis)}> {item.desc} </td>
        {/*<td> {item.creatorName || "-"} </td>*/}
        <td> {toLocalDatetime(item.createTime)} </td>
        <td> {toLocalDatetime(item.updateTime)} </td>
        <td> {langCommon.status[item.status]} </td>

        <td>
          <Edit design={item} updateItem={this.props.updateItem}/>
          <span className="span-seperator"> | </span>

          <span className="span-button" onClick={() =>
            this.setState({
              promptDelete: true,
              target: item,
              promptMsg: sprintf(langPage["tipDelete"], fixStrLen(item.name, 12)),
            })
          }>
            {langCommon.actions["delete"]}
          </span>
        </td>
      </tr>
     )
  }

  render() {
    let langCommon = this.state.langCommon;

    return (<>
      <table className="table-content">
        <thead>
          <tr>
            <th>{langCommon.fields["name"]}</th>
            <th>{langCommon.fields["thumbnail"]}</th>
            <th style={{cursor:'pointer'}}> pakPath </th>
            <th>{langCommon.fields["description"]}</th>
            {/*<th>{langCommon.fields["creator"]}</th>*/}
            <th style={{cursor:'pointer'}}>
              {langCommon.fields["createdAt"]}
              <span onClick={() => this.props.updateOrders("createTime")}>
                {this.renderSortIcon("createTime")}
              </span>
            </th>
            <th>{langCommon.fields["updatedAt"]}</th>
            <th>{langCommon.fields["status"]}</th>
            <th>{langCommon.fields["action"]}</th>
          </tr>
        </thead>

        <tbody>
          {this.renderRows()}
        </tbody>
      </table>

      <Prompt
        show={this.state.promptDelete}
        message={this.state.promptMsg}
        close={() => this.setState({promptDelete: false})}
        ok={() => {
          designUpdateStatus({id: this.state.target.id, status: "删除"}, (res => {
            if(res.code !== 0) {
              message.error(langCommon.tips["failedToDelete"]);
              return;
            };
            if (this.props.afterDelete) this.props.afterDelete();
          }))
        }}
        reject={() => {}}
       />

      <Prompt
        show={this.state.promptAlertStatus}
        message={this.state.promptMsg}
        close={() => this.setState({promptAlertStatus: false})}
        ok={() => {
          let newStatus = this.state.target.status !== "上架" ? "上架" : "下架";

          designUpdateStatus({id: this.state.target.id, status: newStatus}, (res => {
            if(res.code === 0) {
              message.error(langCommon.tips["failedToChangeStatus"]);
              return;
            }
            this.props.updateItem({id: this.state.target.id, status: newStatus});
          }))
        }}
        reject={() => {}}
       />
    </>)
  }
}

export default Table;
