let records = {
  sideNav: "商品情報",
  tableTitle: "商品リスト",
  tipHasNoAreas: "%1$s doesn't have areas",
  tipHasNoMaterials: "%1$s マテリアルエリアは設定されていません",
  "软装": "デコレーション",
  "主材": "建材",
  tipProductExists: "商品はすでに存在します",
  relatedProduct: "関連商品",
  tipDelete: "%1$s を完全に削除してもよろしいですか?",
  brand: "ブランド",
  style: "スタイル",
  origin: "産地",
  price: "価額",
  currency: "通貨",
  model: "品番",
  mainMaterial: "メイン素材",
  unit: "単位",
  lossPerc: "ロス率(%)",
  purchaseURL: "購入リンク",
  materialAreas: "マテリアルエリア",
  tipMaterialReplace: "マテリアル置換（異なる領域のマテリアルIDを選択）",
  tipProductInfo: "モデル商品情報（ソフトウェアの見積書に表示するための情報）",
  unitsMap: {"个":"個", "棵":"株", "片":"個", "组": "セット", "m":"m", "㎡":"㎡", "㎥":"㎥"},
  editProduct: "商品編集",
};

export default records;
