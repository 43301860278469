import { message } from "antd";

import { httpGET } from "./base.js";


const RoutGroup = "/api/web/v1/auth/public";

export function getSts(data, callback) {
  httpGET(`${RoutGroup}/sts`, data, res => { callback(res); });
}

export function categoryQuery(callback) {
  httpGET(`${RoutGroup}/category/query`, null,
    res => { // callback
      if (res.code === 0) {
        callback(res);
      } else if (res > 0) {
        message.error("获取模型类型失败！");
      }
    }
  );
}
