import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Select } from "antd";

import { getUserId, reLogin } from "js/base.js";
import { arrsEquals } from "js/utils.js";
import { updateUser } from "js/api4admin.js";
import { getSet } from "locales/index.js";
import "css/modal.css";

const {Option} = Select;


class EditAccount extends Component {
  constructor (props) {
    super(props); // user, updateUser

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("accounts"),
      onSubmit: false,
      show: false,
      nameColor: "black",     telColor: "black",
      passwordColor: "black", cpasswordColor: "black",
      rolesColor: "black",
      errMsg: "",

      user: {
        name: this.props.user.name,
        tel: this.props.user.tel,
        roles: this.props.user.roles, // this.props.user.roles ? JSON.parse(this.props.user.roles) : [],
        password: "",
        cpassword: "",
        editable: this.props.user.editable,
      }
    };
  }

  componentDidMount() {
    // console.log(this.state.user);
  }

  componentDidUpdate() {
    let roles = this.state.user.roles;
    if (!roles) roles = [];
    let elem = document.getElementById("rolesSelect");
    if (!elem) {
      return;
    }

    for (let i=1; i < elem.options.length; i++) {
      let opt = elem.options[i];
      if (roles.indexOf(opt.value) > -1) {
        opt.innerText = opt.innerText.split(" ")[0];
        opt.innerText += "  *";
      }
    }
  }

  resetInput() {
    this.setState({
      show: false,
      nameColor: "black",     telColor: "black",
      passwordColor: "black", cpasswordColor: "black",
      rolesColor: "black",
      errMsg: "",

      user: {
        name: this.props.user.name,
        tel: this.props.user.tel,
        roles: this.props.user.roles, //this.props.user.roles ? JSON.parse(this.props.user.roles) : [],
        password: "",
        cpassword: "",
        editable: this.props.user.editable,
      }
    })
  }

  //!! 注意 this.setState 是异步操作
  handleSubmit() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;
    if (this.state.onSubmit) {
      return;
    }

    let ok = true, changed = false;
    let data = {id: this.props.user.id};
    let {name, password, cpassword, roles, editable} = this.state.user;

    if (name.length === 0) {
      if (ok) this.setState({errMsg: langPage["tipEnterName"], nameColor: "red"});
      ok = false;
    }
    // !/^[\p{Script=Han}a-zA-Z0-9-_\.]{1,30}$/u.test(name)
//    if (!validUsername(name)) {
//      if (ok) this.setState({errMsg: "姓名可包含汉字、字母、数字、下划线（_）、减号（-）和点（.）！"});
//      this.setState({nameColor: "red"});
//      ok = false;
//    } else
    if (name.length > 30) {
      if (ok) this.setState({errMsg: langPage["tipNameLength"]});
      this.setState({nameColor: "red"});
      ok = false;
    } else {
      if (name !== this.props.user.name) {
        data.name = this.state.user.name;
        changed = true;
      }
      this.setState({nameColor: "black"});
    }

    if (password) {
      if(password !== cpassword) {
        if (ok) this.setState({errMsg: langPage["tipPasswordNotMatch"]});
        this.setState({cpasswordColor: "red"});
        ok = false;
      } else {
        if (password) {
          data.password = this.state.user.password;
          changed = true;
        }
        this.setState({cpasswordColor: "black"});
      }
    }

    if (roles.length === 0) {
      if (ok) {
        this.setState({errMsg: langPage["tipEnterRole"]});
      }
      this.setState({rolesColor: "red"});
      ok = false;
    } else {
      // let propsRoles = this.props.user.roles ? JSON.parse(this.props.user.roles) : [];
      let propsRoles = this.props.user.roles ? this.props.user.roles : [];
      if (!arrsEquals(roles, propsRoles)) {
        data.roles = roles; // JSON.stringify(roles);
        changed = true;
      }
      this.setState({rolesColor: "black"});
    }

    if (editable !== this.props.user.editable) {
      data.editable = editable;
      changed = true;
    }

    if (!ok) {
      return;
    }

    if (!changed) {
      this.setState({show: false});
      return;
    }
    // console.log(data);
    this.setState({onSubmit: true, errMsg: ""});

    updateUser(data,
      res => {
        if (res.code === 0 && getUserId() === data.id && data.password) { // 修改自己的密码
          this.setState({show: false});
          reLogin(langCommon["logoff"]);
          return;
        }

        if (res.code === 0) {
          data.password = "";
          data.cpassword = "";
          this.props.updateItem(data);
          this.resetInput();
          // alert(`成功修改用户 ${this.props.user.name}!`);
        }

        this.setState({onSubmit: false}); // 不管 res.code 是否等于 0
      },
      () => this.setState({onSubmit: false}),
    )
  }

  handleUpdate(event) {
    let elem = event.target;
    let user = {...this.state.user};
    user[elem.name] = elem.value;
    this.setState({user: user});
  }

  updateUser2(value, key) {
    let user = {...this.state.user}
    user[key] = value;
    this.setState({user: user});
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    let yes = {value: "yes", text: langPage["editYes"]};
    let no = {value: "no", text: langPage["editNo"]};
    let arr = [yes, no];
    if (this.state.user.editable === "no") arr = [no, yes];

    return(<>
      <span className="span-button"
        onClick={()=>this.setState({show: true})}> {langCommon.actions["edit"]}
      </span>

      <Modal
        show={this.state.show}
        onHide={this.resetInput.bind(this)}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title>{langPage["editAccount"]}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <table className="table-input">
            <tbody>
              <tr>
                <td>
                  <span style={{color: this.state.nameColor}}> {langCommon.fields["name"]} </span>
                </td>

                <td>
                  <input name="name" maxLength="30" placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.user.name} onChange={this.handleUpdate.bind(this)}
                  />
                </td>
              </tr>

              <tr style={{display: this.props.user.tel ? "": "none"}}>
                <td> <span>{langCommon.fields["phoneNumber"]} </span> </td>
                <td> {this.props.user.tel} </td>
              </tr>

              <tr style={{display: this.props.user.email ? "": "none"}}>
                <td> <span>{langCommon.fields["emailAddress"]}: </span> </td>
                <td> {this.props.user.email} </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: this.state.passwordColor}}>
                    {langCommon.fields["password"]}:
                  </span>
                </td>

                <td>
                  <input name="password" type="password" maxLength="20"
                    placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.user.password} onChange={this.handleUpdate.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: this.state.cpasswordColor}}>
                    {langPage["passwordConfirmation"]}:
                  </span>
                </td>

                <td>
                  <input name="cpassword" type="password" maxLength="20"
                    placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.user.cpassword}
                    onChange={this.handleUpdate.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: this.state.rolesColor}}>
                    {langCommon.fields["role"]}
                  </span>
                </td>

                <td>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '272px' }}
                    placeholder={langCommon.placeholder["select"]}
                    defaultValue={[]}
                    value={this.state.user.roles}
                    onChange={(value) => this.updateUser2(value, "roles")}
                  >
                    {["引擎开发", "引擎美术", "引擎测试", "交付工程师"].map((e) => {
                      return (<Option key={e}> {langPage[e]} </Option>)
                    })}
                  </Select>
                </td>
              </tr>

              <tr>
                <td> <span> {langPage["permissions"]}: </span> </td>

                <td>
                  <Select name="editable" style={{ width:272, textAlign:'left' }}
                    defaultValue={this.state.user.editable}
                    onChange={(value) => {
                      let user = {...this.state.user};
                      user.editable = value;
                      this.setState({user: user});
                    }}
                  >
                    <Option value={arr[0].value}>{arr[0].text}</Option>
                    <Option value={arr[1].value}>{arr[1].text}</Option>
                  </Select>
                </td>
              </tr>
           </tbody>

           <tfoot className="modal-tfoot">
             <tr> <td colSpan="2"> {this.state.errMsg} </td> </tr>
           </tfoot>
         </table>

        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.resetInput.bind(this)}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm button-blue"
            onClick={this.handleSubmit.bind(this)}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default EditAccount;
