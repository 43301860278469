export function time2DateStr(t) {
  let m = (t.getMonth() + 1).toString();
  let d = t.getDate().toString();
  return `${t.getFullYear()}-${ m.length === 1 ? "0" + m : m }-${d.length === 1 ? "0" + d : d}`;
}

export function toDatetime(at=null) {
  if (!at) at = new Date();
  function padH0 (value, len=2) { return value.toString().padStart(len, '0')}

  function timezoneOffset(offset) {
    if (offset === 0) {
      return "Z";
    }

    let hour = padH0(Math.floor(Math.abs(offset) / 60));
    let minute = padH0(Math.abs(offset) % 60);
    return `${(offset < 0) ? "+" : "-"}${hour}:${minute}`;
  }

  at.date = `${at.getFullYear()}-${padH0(at.getMonth() + 1)}-${padH0(at.getDate())}`;
  at.time = `${padH0(at.getHours())}:${padH0(at.getMinutes())}:${padH0(at.getSeconds())}`;
  at.ms = padH0(at.getMilliseconds(), 3);
  at.tz = timezoneOffset(at.getTimezoneOffset());

  at.datetime = `${at.date}T${at.time}`;
  at.rfc3339 = at.datetime + `${at.tz}`;
  at.rfc3339ms = at.datetime + `.${at.ms}${at.tz}`;

  return at
}

export function toLocalDatetime(str) {
  if (!str) {
    return "-";
  }

  let at = toDatetime(new Date(str));
  return at.date + " " + at.time;
}

// golang time.Time.AddDate(year, month, days int)
export function addDate(at, years, months, days) {
    if (!at) at = new Date();
    at.setDate(at.getDate() + days);
    at.setMonth(at.getMonth() + months);
    at.setFullYear(at.getFullYear() + years);
    at = toDatetime(at);
    return at;
}

/*
function datetime() {
  let at = new Date();
  return toDatetime(at);
}
*/

var crypto = require("crypto");
export function randStr(length) {
//  let result   = [];
//  let chars    = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//  let charsLen = chars.length;

//  for ( let i = 0; i < length; i++ ) {
//    result.push(chars.charAt(Math.floor(Math.random() * charsLen)));
//  }

//  return result.join('');
  return crypto.randomBytes(Math.round(length/2)).toString('hex').slice(0, length);
}

export function validUsername(name) {
  return /^[\p{Script=Han}a-zA-Z0-9-_.]+$/u.test(name);
}

export function validUserTel(tel) {
  return /^1[3456789]\d{9}$/.test(tel);
}

export function validUserEmail(email) {
  if (email.length > 128) {
    return false;
  }
  // return /^\w+@[0-9a-zA-Z_]+?\.[a-zA-Z]{2,3}$/u.test(email);
  return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/u.test(email);
}

export function validPassword(password) {
  return password.match(/^[a-zA-Z0-9-_.]{6,20}$/);
}

export function trimString(str, len) {
  if (str.length <= len) {
    return str;
  } else {
    return str.slice(0, len) + "...";
  }
}

export function defaultInitGis() {
  return `{
    "ueMin":{"x":0,"y":0,"z":0},
    "ueMax":{"x":0,"y":0,"z":0},
    "gisMin":{"x":0,"y":0,"z":0},
    "gisMax":{"x":0,"y":0,"z":0}
  }`;
}

export function arrsEquals(arr1, arr2) {
  if (typeof(arr1) === 'undefined' || typeof(arr2) === 'undefined') {
    return false;
  } else if (arr1 === null && arr2 === null) {
    return true;
  } else if (arr1 === null || arr2 === null) {
    return false;
  }

  // console.log(arr1, arr2);
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i=0; i <arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}


export function fixStrLen(str, len=12) {
  return str.length > len ? str.slice(0, len-3) + "..." : str;
}

export function fmtNumber(val, useDefault) {
  if (val === null || val === undefined) {
    if (useDefault) {
      return "0";
    }
    return "-";
  }
  if (isNaN(val) && useDefault) {
    return "0";
  }
  // NaN => "NaN"
  return val.toString();
}
