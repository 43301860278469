let records = {
  sideNav: "マイアセット",
  tableTitle: "アセットリスト",
  tipAlterStatus: "本当に %1$s ステータスを %2$s に変更しますか？",
  tipDelete: "%1$s を削除すると復元できません。モデルを削除しますか？",
  editAsset: "アセット編集",
  importAsset: "アセットインポート",
};

export default records;
