import React, { Component } from "react";
// import Datetime from "react-datetime";
import { Select, message } from "antd";

import Page from "components/page.jsx";
import Table from "./table.jsx";
import { composeQuery, composeTypeList } from "js/api4user.js";
import { getSet } from "locales/index.js";
import "css/content.css";

const { Option } = Select;


class MyComposes extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("myComposes"),
      composeTypeList: [],
      query: this.defaultQuery(),
      items: [], totalItems: 0,
      pageSizes: [10, 15, 20, 30],
    };
  }
  
  componentDidMount() {
    let langPage = this.state.langPage;
    document.title = langPage["sideNav"];

    composeTypeList({}, res => {
      if (res.code === 0) {
        this.setState({composeTypeList: res.data.items});
      } else {
        message.error(res.message);
      }
    });

    this.queryComposes();
  }

  queryComposes() {
     let query = {...this.state.query};
     query.name = query.name.trim();

     composeQuery(query, res => {
        this.setState({items: res.data.items});
        if (this.state.query.page === 1 || res.data.totalItems > 0) {
          this.setState({totalItems: res.data.totalItems});
        }
     })
  }

  defaultQuery() {
    return { page: 1,  pageSize: 10, name: "", status: "", orderBy: "", ascending: "" };
  }

  resetQuery() {
    let query = this.defaultQuery();
    // console.log(`~~ ${JSON.stringify(query)}`);
    this.setState({query: query}, this.queryComposes);
  }

  updateQuery(key, val) {
    let query = {...this.state.query};
    query[key] = val;
    this.setState({query: query});
  }

  updatePageSize = (size) => {
    if (size <= 0 || size === this.state.query.pageSize) {
      return;
    }

    let query = {...this.state.query};
    query.page = 1;
    query.pageSize = size;
    this.setState({query: query}, this.queryComposes);
  }

  updatePageNum = (num) => {
    let query = {...this.state.query}
    query.page = num;
    this.setState({query: query}, this.queryComposes);
  }

  updateItem = (item) => {
    let items = this.state.items;
    let idx = items.findIndex(e => e.id === item.id);
    if (idx < 0) {
      return;
    }

    items[idx] = {...items[idx], ...item};
    this.setState({items: items});
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;
    return (<>
      <div className='user-query'>
        <div className="input-parameter">
          <span> {langCommon.fields["name"]}: </span>
          <input type="text" name="name" placeholder={langCommon.placeholder["enter"]}
            maxLength="64"
            value={this.state.query.name}
            onChange={event => this.updateQuery("name", event.target.value)}
          />
        </div>

        <div className="input-parameter">
          <span> {langCommon.fields["status"]}: </span>

          <Select
            style={{ width: 180, textAlign: 'left' }}
            name="status"
            allowClear
            placeholder={langCommon.placeholder["select"]}
            value={this.state.query.status}
            onChange={(value) => this.updateQuery("status", value)}
          >
            <Option value="on">{langCommon.status["on"]}</Option>
            <Option value="off">{langCommon.status["off"]}</Option>
            <Option value="deleted">{langCommon.status["deleted"]}</Option>
          </Select>
        </div>

        <div className="div-button-to-right">
          <button className="btn btn-secondary btn-sm button-blank query-button"
            onClick={this.resetQuery.bind(this)}> {langCommon.actions["reset"]}
          </button>

          <button className="btn btn-primary btn-sm query-button button-blue"
            onClick={this.queryComposes.bind(this)}>
            {langCommon.actions["search"]}
          </button>
        </div>
      </div>

      <div className="content-area">
        <div className="content-top">
          <div>{langPage.tableTitle}</div>
        </div>

        <Table
          items={this.state.items}
          composeTypeList={this.state.composeTypeList}
          updateItem={this.updateItem}
        />

        <Page
          page={this.state.query.page}
          pageSize={this.state.query.pageSize}
          totalItems={this.state.totalItems}
          pageSizes={this.state.pageSizes}

          updatePageNum={this.updatePageNum}
          updatePageSize={this.updatePageSize}
        />
      </div>
    </>)
  }
}

export default MyComposes;
