import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Select, message } from "antd";

import { newAccount } from "js/api4admin.js";
import { validUserTel, validUserEmail, validPassword } from "js/utils.js";
import { getSet } from "locales/index.js";
import "css/modal.css";

const { Option } = Select;


class NewAccount extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("accounts"),
      onSubmit: false,
      show: false,
      nameColor: "black", telColor: "black", emailColor: "black",
      passwordColor: "black", cpasswordColor: "black",
      rolesColor: "black",
      errMsg: "",
     
      user: {
        name: "", tel: "", email: "", password: "",
        cpassword: "", roles: [], editable: "no",
      }
    };
  }

  clearInput() {
    this.setState({
      onSubmit: false,
      show: false,
      nameColor: "black", telColor: "black", emailColor: "black",
      passwordColor: "black", cpasswordColor: "black",
      rolesColor: "black",
      errMsg: "",
      
      user: {
        name: "", tel: "", email: "", password: "",
        cpassword: "", roles: [], editable: "no",
    }})
  }

  handleSubmit() {
    let langPage = this.state.langPage;
    if (this.state.onSubmit) {
      return;
    }

    let ok = true;
    // console.log(this.state.user);
    let {name, tel, email, password, cpassword, roles, editable} = this.state.user;

    if (name.length === 0) {
      if (ok) this.setState({errMsg: langPage["tipEnterName"], nameColor: "red"});
      ok = false;
    }
    // !/^[\p{Script=Han}a-zA-Z0-9-_\.]{1,30}$/u.test(name)
//    if (!validUsername(name)) {
//      if (ok) this.setState({errMsg: "姓名可包含汉字、字母、数字、下划线（_）、减号（-）和点（.）！"});
//      this.setState({nameColor: "red"});
//      ok = false;
//    } else
    if (name.length > 30) {
      if (ok) this.setState({errMsg: langPage["tipNameLength"]});
      this.setState({nameColor: "red"});
      ok = false;
    } else {
      this.setState({nameColor: "black"});
    }

    if (tel.length > 0) {
      if(!validUserTel(tel)) { // !tel.match(/^\d{11}$/)
        if (ok) this.setState({errMsg: langPage["tipInvalidPhone"]});
        this.setState({telColor: "red"});
        ok = false;
      } else {
        this.setState({telColor: "black"});
      }
    } else if(email.length > 0) {
      if(!validUserEmail(email)) {
        if (ok) this.setState({errMsg: langPage["tipInvalidEmail"]});
        this.setState({emailColor: "red"});
        ok = false;
      } else {
        this.setState({emailColor: "black"});
      }
    } else {
      if (ok) this.setState({errMsg: langPage["tipEnterPhoneOrEmail"]});
    }

    if(!validPassword(password)) {
      if (ok) this.setState({errMsg: langPage["tipInvalidPassword"]});
      this.setState({passwordColor: "red"});
      ok = false;
    } else {
      this.setState({passwordColor: "black"});
    }

    if(password !== cpassword) {
      if (ok) this.setState({errMsg: langPage["tipPasswordNotMatch"]});
      this.setState({cpasswordColor: "red"});
      ok = false;
    } else {
      this.setState({cpasswordColor: "black"});
    }

    if (roles.length === 0) {
      if (ok) this.setState({errMsg: langPage["tipEnterRole"]});
      this.setState({rolesColor: "red"});
      ok = false;
    } else {
      this.setState({rolesColor: "black"});
    }

    if (!ok) {
      return;
    }

    let data = {
        name: name,
        tel: tel,
        email: email,
        password: password,
        roles: roles, // JSON.stringify(roles),
        editable: editable,
    }

    this.setState({onSubmit: true, errMsg: ""});

    newAccount(data,
      res => {
        this.setState({onSubmit: false});
        // res.code < 0 时, 在 base.js request 中已经 alert
        // 无论是 res.code 大于 0 还是小于 0，都在这里处理 this.setState({onSubmit: false});
        if (res.code === 0 ) {
          this.clearInput();
          // alert(`成功创建用户 ${data.name}!`);
          if (this.props.update) this.props.update();
          // window.location.reload();
        } else {
          this.setState({show: false}, () => message.error(res.message));
        }
      },
      () => this.setState({onSubmit: false}),
    )
  }

  updateUser(event) {
    let elem = event.target;
    let user = {...this.state.user};
    user[elem.name] = elem.value;
    this.setState({user: user});
  }

  updateUser2(value, key) {
    let user = {...this.state.user}
    user[key] = value;
    this.setState({user: user});
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return(<>
      <div className="div-button-to-right">
        <button className="btn btn-primary btn-sm query-button button-blue"
          onClick={()=>this.setState({show: true})}
        > {langCommon.actions["create"]}
        </button>
      </div>

      <Modal
        show={this.state.show}
        onHide={this.clearInput.bind(this)}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title>{langPage["createAccount"]}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <table className="table-input">
            <tbody>
              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span style={{color: this.state.nameColor}}> {langCommon.fields["name"]}: </span>
                </td>

                <td>
                  <input name="name" maxLength="30" placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.user.name} onChange={this.updateUser.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span> </span>
                  <span style={{color: this.state.telColor}}>
                    {langCommon.fields["phoneNumber"]}:
                  </span>
                </td>

                <td>
                  <input name="tel" maxLength="11" placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.user.tel} onChange={this.updateUser.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span> </span>
                  <span style={{color: this.state.emailColor}}>
                    {langCommon.fields["emailAddress"]}:
                  </span>
                </td>

                <td>
                  <input name="email" maxLength="128" placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.user.email} onChange={this.updateUser.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span style={{color: this.state.passwordColor}}>
                    {langCommon.fields["password"]}:
                  </span>
                </td>

                <td>
                  <input name="password" type="password" maxLength="20"
                    placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.user.password} onChange={this.updateUser.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span style={{color: this.state.cpasswordColor}}>
                    {langPage["passwordConfirmation"]}:
                  </span>
                </td>

                <td>
                  <input name="cpassword" type="password" maxLength="20"
                    placeholder={langCommon.placeholder["enter"]}
                    className="modal-input"
                    value={this.state.user.cpassword}
                    onChange={this.updateUser.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red'}}> * </span>
                  <span style={{color: this.state.rolesColor}}>
                    {langPage["role"]}:
                  </span>
                </td>

                <td>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '272px' }}
                    placeholder={langCommon.placeholder["select"]}
                    defaultValue={[]}
                    value={this.state.user.roles}
                    onChange={(value) => this.updateUser2(value, "roles")}
                  >
                    {["引擎开发", "引擎美术", "引擎测试", "交付工程师"].map((e) => {
                      return (<Option key={e}> {langPage[e]} </Option>)
                    })}
                  </Select>
                </td>
              </tr>

              <tr>
                <td> <span> {langPage["permissions"]}: </span> </td>

                <td>
                  <Select name="editable" defaultValue={langPage["editNo"]}
                    style={{ width: '272px', textAlign:'left' }}
                    onChange={(value) => {
                      let user = {...this.state.user};
                      user.editable = value;
                      this.setState({user: user});
                    }}
                  >
                    <Option value="no">{langPage["editNo"]}</Option>
                    <Option value="yes">{langPage["editYes"]}</Option>
                  </Select>
                </td>
              </tr>
            </tbody>

           <tfoot className="modal-tfoot">
             <tr> <td colSpan="2"> {this.state.errMsg} </td> </tr>
           </tfoot>
         </table>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.clearInput.bind(this)}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm btn-blue button-blue"
            onClick={this.handleSubmit.bind(this)}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default NewAccount;
