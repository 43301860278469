import React, { Component } from "react";
import { BiSort, BiSortDown, BiSortUp } from "react-icons/bi";
import { sprintf } from "sprintf-js";

import Edit from "./edit.jsx";
import Prompt from "components/prompt.jsx";
import { deleteUser } from "js/api4admin.js";
import { getUserId, reLogin } from "js/base.js";
import { toLocalDatetime, fixStrLen } from "js/utils.js";
import { getSet } from "locales/index.js";


class AccountTable extends Component {
  constructor (props) {
    super(props); // items, updateItem, afterDelete

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("accounts"),
      targetId: "",
      promptMsg: "",
      promptDelete: false,
    };
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  removeUser() {
    let langCommon = this.state.langCommon;
    deleteUser(this.state.targetId, (res => {
      if(res.code === 0) {
        if (getUserId() === this.state.targetId) {
          reLogin(langCommon["logOff"]);
          return;
        }

        if (this.props.afterDelete) this.props.afterDelete();
        // window.location.reload();
        // let e = document.getElementById(id);
        // e.remove();
      }
    }))
  }

  renderSortIcon(name) {
    // console.log("--> renderOrder:", name, this.props.ascending);
    if (name !== this.props.query.orderBy || this.props.query.ascending === "") {
      return (<BiSort size={18} color="grey"/>)
    } else if (this.props.query.ascending === "true") {
      return (<BiSortUp size={18} color="grey"/>)
    } else {
      return (<BiSortDown size={18} color="grey"/>)
    }
  }

  // `删除账号 "${item.name}" 后将无法恢复!`
  renderRows() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return this.props.items.map((item, idx) => {
      let roles = item.roles || [];
      return (
        <tr key={item.id} id={item.id} data-index={idx}>
          <td> {item.name} </td>
          <td> {item.tel || "-"} </td>
          <td> {item.email || "-"} </td>
          <td data-raw={roles}>
            {roles ? roles.map(e => langPage[e]).join('、') : "-"}
          </td>
          <td data-raw={item.editable}>
            { item.editable === "yes" ? langPage.editYes : langPage.editNoe}
          </td>
          <td> {item.userLevel || "-" } </td>
          <td title={langCommon.fields["updatedAt"] + ": " + toLocalDatetime(item.updateTimestamp)}>
            {toLocalDatetime(item.createTimestamp)}
          </td>

          <td>
            <Edit user={item} updateItem={this.props.updateItem}/>
            <span className="span-seperator"> | </span>

            <span className="span-button"
              onClick={() => {
                this.setState({
                  promptDelete: true,
                  targetId: item.id,
                  promptMsg: sprintf(langPage.tipDelete, fixStrLen(item.name, 12)),
                });
              }}
            >
              {langCommon.actions["delete"]}
            </span>
          </td>
        </tr>
      )
    })
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return (<>
      <table className="table-content">
        <thead>
          <tr>
            <th>{langCommon.fields["name"]}</th>
            <th style={{cursor:'pointer'}}>
              {langCommon.fields["phoneNumber"]}
              <span onClick={() => this.props.updateOrders("tel")}>
                {this.renderSortIcon("tel")}
              </span>
            </th>
            <th>{langCommon.fields["emailAddress"]}</th>
            <th>{langCommon.fields["role"]}</th>
            <th>{langPage["permissions"]}</th>
            <th>{langPage["accountType"]}</th>
            <th style={{cursor:'pointer'}}>
              {langCommon.fields["createdAt"]}
              <span onClick={() => this.props.updateOrders("createTimestamp")}>
                {this.renderSortIcon("createTimestamp")}
              </span>
            </th>
            <th>{langCommon.fields["action"]}</th>
          </tr>
        </thead>

        <tbody>
          {this.renderRows()}
        </tbody>
      </table>

      <Prompt
        show={this.state.promptDelete}
        message={this.state.promptMsg}
        close={() => this.setState({promptDelete: false})}
        ok={() => {
          deleteUser(this.state.targetId, (res => {
            if(res.code === 0) {
              if (getUserId() === this.state.targetId) {
                reLogin(langCommon.actions["logoff"]);
                return;
              }

              if (this.props.afterDelete) this.props.afterDelete();
            }
          }))
        }}
        reject={() => {}}
       />
    </>)
  }
}

export default AccountTable;
