import React, { Component } from "react";
// import Datetime from "react-datetime";
import { Select } from "antd";

import Page from "components/page.jsx";
import Table from "./table.jsx";
import New from "./new.jsx";
import { userAssetQuery } from "js/api4user.js";
import { getSet } from "locales/index.js";
import "css/content.css";

const {Option} = Select;


class UserAssets extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("userAssets"),

      query: {
        page: 1,  pageSize: 10, name: "", status: null,
        orderBy: "", ascending: "",
      },
      items: [], totalItems: 0,
      pageSizes: [10, 15, 20, 30],

      getUserAssets: this.getUserAssets.bind(this),
      queryAsset: this.queryAsset.bind(this),
      updatePageNum: this.updatePageNum.bind(this),
      updatePageSize: this.updatePageSize.bind(this),
      updateAsset: this.updateAsset.bind(this),
      updateOrders: this.updateOrders.bind(this),
    };
  }
  
  componentDidMount() {
    let langPage = this.state.langPage;
    document.title = langPage["sideNav"];
    this.getUserAssets();
  }

  getUserAssets() {
     userAssetQuery(this.state.query, res => {
        this.setState({items: res.data.items});
        if (res.data.items.length === 0 || res.data.totalItems > 0) {
          this.setState({totalItems: res.data.totalItems});
        }
     })
  }

  resetQuery() {
    let query = {
      page: 1,  pageSize: this.state.query.pageSize, name: "", status: null,
      orderBy: "", ascending: ""
    };
    this.setState({query: query});

    userAssetQuery(query, res => {
      if (res.code === 0) {
        this.setState({items: res.data.items});
        if (res.data.items.length === 0 || res.data.totalItems > 0) {
          this.setState({totalItems: res.data.totalItems});
        }
      }
    })
  }

  updateQuery(event) {
    let query = {...this.state.query};
    query[event.target.name] = event.target.value;
    this.setState({query: query});
  }

  updateQuery2(value, name) {
    let query = {...this.state.query};
    query[name] = value;
    this.setState({query: query});
  }

  updatePageSize(size) {
    if (size <= 0 || size === this.state.query.pageSize) {
      return;
    }

    let query = {...this.state.query};
    query.pageSize = size;
    this.setState({query: query});

    userAssetQuery(query, res => {
      if (res.code === 0) {
        this.setState({items: res.data.items});
      }
    })
  }

  updatePageNum(num) {
    let query = {...this.state.query}
    query.page = num;
    userAssetQuery(query, res => {
      if (res.code === 0) {
        this.setState({query: query, items: res.data.items});
      }
    })
  }

  queryAsset() {
    // console.log(this.state.query);
    let query = {...this.state.query, page: 1};
    query.name = query.name.trim();
    this.setState({query: query});

    userAssetQuery(query, res => {
      if (res.code === 0) {
        this.setState({items: res.data.items});
        if (res.data.items.length === 0 || res.data.totalItems > 0) {
          this.setState({totalItems: res.data.totalItems});
        }
      }
    })
  }

  updateAsset(data) {
    let items = this.state.items;
    let idx = items.findIndex(e => e.id === data.id);
    if (idx === -1) {
      return;
    }

    items[idx] = {...items[idx], ...data};
    this.setState({items: items});
  }

  updateOrders(name) {
    // console.log("~~~", name);
    if (name === "") {
      return
    }

    let query = {...this.state.query};

    // "" -> "true" -> "false" -> ""
    if (query["orderBy"] !== name) {
      query["orderBy"] = name;
      query["ascending"] = "true";
    } else if (query["ascending"] === "") {
      query["ascending"] = "true";
    } else if (query["ascending"] === "true") {
      query["ascending"] = "false";
    } else {
      query["ascending"] = "";
    }

    this.setState({query: query}, this.state.queryAsset);
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return (<>
      <div className='user-query'>
        <div className="input-parameter">
          <span> {langCommon.fields["name"]}: </span>
          <input type="text" name="name" maxLength="64"
            placeholder={langCommon.placeholder["enter"]}
            value={this.state.query.name}
            onChange={this.updateQuery.bind(this)}
          />
        </div>

        <div className="input-parameter">
          <span> {langCommon.fields["status"]}: </span>

          <Select
            style={{ width: 180, textAlign: 'left' }}
            name="status"
            allowClear
            placeholder={langCommon.placeholder["select"]}
            value={this.state.query.status}
            onChange={(value) => this.updateQuery2(value, "status")}
          >
            <Option value="上架">{langCommon.status["上架"]}</Option>
            <Option value="下架">{langCommon.status["下架"]}</Option>
          </Select>
        </div>

        <div className="div-button-to-right">
          <button className="btn btn-secondary btn-sm button-blank query-button"
            onClick={this.resetQuery.bind(this)}> {langCommon.actions["reset"]}
          </button>

          <button className="btn btn-primary btn-sm query-button button-blue"
            onClick={this.queryAsset.bind(this)}> {langCommon.actions["search"]}
          </button>
        </div>
      </div>

      <div className="content-area">
        <div className="content-top">
          <div>{langPage["tableTitle"]}</div>
          <div className="div-button-to-right">
            <New
              update={this.state.getUserAssets}
            />
          </div>
        </div>

        <Table
          items={this.state.items}
          updateAsset={this.state.updateAsset}
          updateOrders={this.state.updateOrders}
          query={this.state.query}
          afterDelete={() => {
            this.getUserAssets();
            this.setState({totalItems: this.state.totalItems - 1});
          }}
        />

        <Page
          page={this.state.query.page}
          pageSize={this.state.query.pageSize}
          totalItems={this.state.totalItems}
          updatePageNum={this.state.updatePageNum}
          pageSizes={this.state.pageSizes}
          updatePageSize={this.state.updatePageSize}
        />
      </div>
    </>)
  }
}

export default UserAssets;
