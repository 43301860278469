import React, { Component } from "react";
import { DatePicker, Space, message } from "antd";
import moment from "moment";

import Page from "components/page.jsx";
import Table from "./table.jsx";
import { getSet } from "locales/index.js";
import { queryEventLog } from "js/api4admin.js";
import { toDatetime, addDate, validUserTel, validUserEmail } from "js/utils.js";
import "css/content.css";

const { RangePicker } = DatePicker;


class AccessLogs extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("accessLogs"),
      langAccounts: getSet("accounts"),

      query: {
        page: 1,  pageSize: 10, startDate: "", endDate: "",
        kind: "access_design", targetName: "", telOrEmail: "",
      },
      items: [], totalItems: 0,

      queryBase: this.queryBase.bind(this),
      queryLog: this.queryLog.bind(this),

      updatePageNum: this.updatePageNum.bind(this),
      pageSizes: [10, 15, 20, 30],
      updatePageSize: this.updatePageSize.bind(this),
    };
  }
  
  componentDidMount() {

    let query = {...this.state.query};
    let now = toDatetime(new Date());
    query.endDate = now.date;
    addDate(now, 0, 0, -7);
    query.startDate = now.date;
    // let query = {...this.state.query, startDate: startDate, endDate: endDate};

    this.setState({query: query}, this.queryLog);
  }

  resetQuery() {
    let now = toDatetime(new Date());

    let query = {
      page: 1, pageSize: this.state.query.pageSize,
      kind: "access_design", targetName: "", telOrEmail: "",
    };

    query.endDate = now.date;
    addDate(now, 0, 0, -7);
    query.startDate = now.date;

    this.setState({query: query}, this.qeuryLog);
  }

  queryBase(query) {
     query.targetName = query.targetName.trim();
     query.telOrEmail = query.telOrEmail.trim();
     if (query.telOrEmail !== "") {
       if (!validUserTel(query.telOrEmail) && !validUserEmail(query.telOrEmail)) {
         message.warn(this.state.langAccounts["tipEnterPhoneOrEmail"]);
         return;
       }
     }

     queryEventLog(query, res => {
        this.setState({items: res.data.items, query: query});
        if (res.data.items.length === 0 || res.data.totalItems > 0) {
          this.setState({totalItems: res.data.totalItems});
        }
     })
  }

  queryLog() {
     let query = {...this.state.query, page: 1};
     this.queryBase(query);
  }

  updateState(event) {
    let query = {...this.state.query};
    query[event.target.name] = event.target.value;
    this.setState({query: query});
  }

  updatePageSize(size) {
    if (size <= 0 || size === this.state.query.pageSize) {
      return;
    }

    let query = {...this.state.query};
    query.pageSize = size;
    this.setState({query: query}, this.queryLog);
  }

  updatePageNum(num) {
    let query = {...this.state.query}
    query.page = num;

    this.setState({query: query}, () => this.queryBase(query));
  }

  updateRangePicker = (dates, dateStrings) => {
    let query = {...this.state.query};
    if (!dateStrings) {
      query.startDate = "";
      query.endDate = "";
    } else {
      query.startDate = dateStrings[0];
      query.endDate = dateStrings[1];
    }
    this.setState({query: query});
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;
    // console.log(this.state.query);
    return (<>
      <div className='user-query'>
        <div className="input-parameter">
          <span> {langCommon.fields["name"]}: </span>
          <input type="text" name="targetName" placeholder={langCommon.placeholder["enter"]}
            value={this.state.query.targetName}
            onChange={this.updateState.bind(this)}
          />
        </div>

        <div className="input-parameter">
          <span> {langCommon.fields["phoneOrEmail"]}: </span>
          <input type="text" name="telOrEmail" placeholder={langCommon.placeholder["enter"]}
            value={this.state.query.telOrEmail}
            onChange={this.updateState.bind(this)}
          />
        </div>

        <div className="input-parameter">
          <span> {langPage["accessTime"]}: </span>
          <Space direction="vertical" size={12}>
            <RangePicker
              id="datePicker"
              placeholder={[langCommon.fields["beginning"], langCommon.fields["end"]]}
              value={
                this.state.query.startDate !== "" ?
                [moment(this.state.query.startDate), moment(this.state.query.endDate)] :
                ["", ""]
              }
              onChange={this.updateRangePicker}
            />
          </Space>
        </div>

        <div className="div-button-to-right">
          <button className="btn btn-secondary btn-sm button-blank query-button"
            onClick={this.resetQuery.bind(this)}> {langCommon.actions["reset"]}
          </button>

          <button className="btn btn-primary btn-sm query-button button-blue"
            onClick={this.queryLog.bind(this)}> {langCommon.actions["search"]}
          </button>
        </div>
      </div>

      <div className="content-area">
        <div className="content-top">
          <div>{langPage["sideNav"]}</div>
        </div>

        <Table
          items={this.state.items}
        />

        <Page
          page={this.state.query.page}
          pageSize={this.state.query.pageSize}
          totalItems={this.state.totalItems}
          updatePageNum={this.state.updatePageNum}
          pageSizes={this.state.pageSizes}
          updatePageSize={this.state.updatePageSize}
        />
      </div>
    </>)
  }
}

export default AccessLogs;
