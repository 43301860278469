let records = {
  sideNav: "我的导入",
  tableTitle: "模型列表",
  tipAlterStatus: "确定修改 %1$s 状态为 %2$s 吗?",
  tipDelete: "删除 %1$s 将无法恢复，确认要删除该模型吗?",
  editAsset: "编辑模型",
  importAsset: "导入模型",
};

export default records;
