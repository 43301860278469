import React, { Component } from "react";
import { message } from "antd";
// import { withRouter } from "react-router";
// import { Nav, Button, Card, Form } from "react-bootstrap";
// import { Container, Row, Col } from "react-bootstrap";
import { sprintf } from "sprintf-js";

import Head from "components/head.jsx";
import Accounts from "./accounts/accounts.jsx";
import Designs from "./designs/designs.jsx";
import Assets from "./assets/assets.jsx";
import Commodities from "./commodities/commodities.jsx";
import AssetComposes from "./asset_composes/asset_composes.jsx";
import MyProjects from "./my_projects/my_projects.jsx";
import UserAssets from "./user_assets/user_assets.jsx";
import MyComposes from "./my_composes/my_composes.jsx";
import Monitor from "./monitor/monitor.jsx";
import AccessLogs from "./access_logs/access_logs.jsx";

import { getUserId, getUserLevel, getUserRoles, getUserTel, getUserEmail } from "js/base.js";
import { reLogin, redirectTo, logout, newPath } from "js/base.js";
import { getSet } from "locales/index.js";

import "css/manage.css";
import AccountKI from "img/account_black@2x.png";
import AccountBI from "img/account_blue@2x.png";
import DesignKI from "img/design_black@2x.png";
import DesignBI from "img/design_blue@2x.png";
import AssetKI from "img/asset_black@2x.png";
import AssetBI from "img/asset_blue@2x.png";
import CommodityKI from "img/commodity_black.png";
import CommodityBI from "img/commodity_blue.png";
// import MonitorKI from "img/monitor_black@2x.png";
// import MonitorBI from "img/monitor_blue@2x.png";
import AccessLogsKI from "img/access_logs_black@2x.png";
import AccessLogsBI from "img/access_logs_blue@2x.png";
import MyProjectsKI from "img/my_projects_black.png";
import MyProjectsBI from "img/my_projects_blue.png";
import UserAssetsKI from "img/user_assets_black@2x.png";
import UserAssetsBI from "img/user_assets_blue@2x.png";
import MyComposesKI from "img/my_compose_black.png";
import MyComposesBI from "img/my_compose_blue.png";
import AssetComposesKI from "img/asset_compose_black.png";
import AssetComposesBI from "img/asset_compose_blue.png";


class Manage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: <div></div>,
      navMap: {
        accounts: <Accounts />,
        designs: <Designs />,
        assets: <Assets />,
        commodities: <Commodities />,
        asset_composes: <AssetComposes />,
        my_projects: <MyProjects />,
        my_composes: <MyComposes />,
        user_assets: <UserAssets />,
        monitor: <Monitor />,
        access_logs: <AccessLogs />,
      },
    };
  }

  componentDidMount() {
    if (!getUserId()) {
      redirectTo("/login");
      return;
    }

    let langCommon = getSet("common");

    // 对于 url 跳转打开, 验证已登录用户和目的用户 (UE 端) 是否相同
    let urlParams = new URLSearchParams(window.location.search);
    let userId = urlParams.get("userid");

    if (userId) {
      if (userId !== getUserId()) { // 已登录用户与 UE 端用户不同
        // message.warn(`未登录, 2s 后跳转登录页面...`);
        message.warn(sprintf(
          langCommon.tips.currentLoggedIn, getUserTel() || getUserEmail(),
        ));
        setTimeout(logout, 3*1000);
        return;
      }

      // console.log(`~~~ ${window.location.href.replace("[?&]userid=")}`);
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search);
      params.delete("userid");
      url.search = params;
      // console.log(`~~ ${url}`);
      window.history.pushState({}, document.title, url);
    }

    let pathArr = window.location.pathname.split("/");
    let nav = pathArr.length > 2 ? pathArr[2] : "";

    if (nav !== "") {
      let e = document.getElementById(nav);

      if (e) {
        e.click();
        return;
      }
    }

    let items = document.getElementsByClassName("nav-item");
    for (let e of items) {
      e.click();
      break;
    }
  }

  componentDidUpdate() {
    if (!getUserId()) reLogin("");
  }

  renderNavItems () {
    let userLevel = getUserLevel();
    let roles = getUserRoles();

    let isAdmin = userLevel === "admin";
    let isArtist = roles.indexOf("引擎美术") > -1;
    // console.log(`~~~ ${adminOrArtist}`);

    if (isAdmin) {
      return this.render4Admin();
    } else if (isArtist) {
      return this.render4Artist();
    } else {
      return this.render4User();
    }
  }

  render4Artist = () => {
    let myProjects = getSet("myProjects")["sideNav"];
    let assets = getSet("assets")["sideNav"];
    let userAssests = getSet("userAssets")["sideNav"];
    let myComposes = getSet("myComposes")["sideNav"];

    return (<>
      <p className="nav-item" id="my_projects" onClick={this.handleClick}>
        <img className="nav-icon" alt={myProjects} src={MyProjectsKI} data-alt-src={MyProjectsBI}/>
        {myProjects}
      </p>

      <p className="nav-item" id="assets" onClick={this.handleClick}>
        <img className="nav-icon" alt={assets} src={AssetKI} data-alt-src={AssetBI}/>
        {assets}
      </p>

      <p className="nav-item" id="user_assets" onClick={this.handleClick}>
        <img className="nav-icon" alt={userAssests} src={UserAssetsKI} data-alt-src={UserAssetsBI}/>
        {userAssests}
      </p>

      <p className="nav-item" id="my_composes" onClick={this.handleClick}>
        <img className="nav-icon" alt={myComposes} src={MyComposesKI} data-alt-src={MyComposesBI}/>
        {myComposes}
      </p>
    </>)
  }

  render4User = () => {
    let myProjects = getSet("myProjects")["sideNav"];
    let userAssests = getSet("userAssets")["sideNav"];
    let myComposes = getSet("myComposes")["sideNav"];

    return (<>
      <p className="nav-item" id="my_projects" onClick={this.handleClick}>
        <img className="nav-icon" alt={myProjects} src={MyProjectsKI} data-alt-src={MyProjectsBI}/>
        {myProjects}
      </p>

      <p className="nav-item" id="user_assets" onClick={this.handleClick}>
        <img className="nav-icon" alt={userAssests} src={UserAssetsKI} data-alt-src={UserAssetsBI}/>
        {userAssests}
      </p>

      <p className="nav-item" id="my_composes" onClick={this.handleClick}>
        <img className="nav-icon" alt={myComposes} src={MyComposesKI} data-alt-src={MyComposesBI}/>
        {myComposes}
      </p>
    </>)
  }

  render4Admin = () => {
    let accounts = getSet("accounts")["sideNav"];
    let designs = getSet("designs")["sideNav"];
    let assets = getSet("assets")["sideNav"];
    let commodities = getSet("commodities")["sideNav"];
    let assetComposes = getSet("assetComposes")["sideNav"];
    let accessLogs = getSet("accessLogs")["sideNav"];

    let myProjects = getSet("myProjects")["sideNav"];
    let userAssests = getSet("userAssets")["sideNav"];
    let myComposes = getSet("myComposes")["sideNav"];

    return (<>
      <p className="nav-item" id="accounts" onClick={this.handleClick}>
        <img className="nav-icon" alt={accounts} src={AccountKI} data-alt-src={AccountBI}/>
        {accounts}
      </p>

      <p className="nav-item" id="designs" onClick={this.handleClick}>
        <img className="nav-icon" alt={designs} src={DesignKI} data-alt-src={DesignBI}/>
        {designs}
      </p>

      <p className="nav-item" id="assets" onClick={this.handleClick}>
        <img className="nav-icon" alt={assets} src={AssetKI} data-alt-src={AssetBI}/>
        {assets}
      </p>

      <p className="nav-item" id="commodities" onClick={this.handleClick}>
        <img className="nav-icon" alt={commodities} src={CommodityKI} data-alt-src={CommodityBI}/>
        {commodities}
      </p>

      <p className="nav-item" id="asset_composes" onClick={this.handleClick}>
        <img className="nav-icon" alt={assetComposes} src={AssetComposesKI}
          data-alt-src={AssetComposesBI}
        />
        {assetComposes}
      </p>

      <p className="nav-item" id="my_projects" onClick={this.handleClick}>
        <img className="nav-icon" alt={myProjects} src={MyProjectsKI} data-alt-src={MyProjectsBI}/>
        {myProjects}
      </p>

      <p className="nav-item" id="user_assets" onClick={this.handleClick}>
        <img className="nav-icon" alt={userAssests} src={UserAssetsKI} data-alt-src={UserAssetsBI}/>
        {userAssests}
      </p>

      <p className="nav-item" id="my_composes" onClick={this.handleClick}>
        <img className="nav-icon" alt={myComposes} src={MyComposesKI} data-alt-src={MyComposesBI}/>
        {myComposes}
      </p>

      <p className="nav-item" id="access_logs" onClick={this.handleClick}>
        <img className="nav-icon" alt={accessLogs} src={AccessLogsKI} data-alt-src={AccessLogsBI}/>
        {accessLogs}
      </p>
    </>)
  }

//      <p className="nav-item" id="monitor" onClick={this.handleClick}>
//        <img className="nav-icon" alt="实时监控" src={MonitorKI} data-alt-src={MonitorBI} />
//        实时监控
//      </p>

  handleClick = (event) => {
    let id = event.target.id;
    this.setState({show: this.state.navMap[id]});

    let items = document.getElementsByClassName("main-nav")[0].childNodes;
    items.forEach(e => {
      let img = e.children[0];
      let src = img.getAttribute("src");
      let altSrc = img.getAttribute("data-alt-src");
      let m1 = e.id === id;
      let m2 = e.classList.contains("nav-item-selected");

      if (m1 && !m2) {
        e.classList.add("nav-item-selected");
        img.setAttribute("data-alt-src", src);
        img.setAttribute("src", altSrc);
      }

      if (!m1 && m2) {
        e.classList.remove("nav-item-selected");
        img.setAttribute("data-alt-src", src);
        img.setAttribute("src", altSrc);
      }
    })

    let path = newPath(`/manage/${id}`);
    if (document.location.search) path += document.location.search;
    window.history.pushState("", "", path);
  };

  render () {
    if (!getUserId()) {
      return (<></>);
    }

    return (
      <div className="wrapper" style={{backgroundColor: "#F1F1F1"}}>
        <header className="main-head"> <Head /> </header>

        <nav className="main-nav">
          {this.renderNavItems()}
        </nav>

        <article className="article">
          {this.state.show}
        </article>
      </div>
    );
  }
}

export default Manage;
