import React, { Component } from "react";
import { BiSort, BiSortDown, BiSortUp } from "react-icons/bi";
import { message } from "antd";
// import { AiOutlineCopy } from "react-icons/ai";
import copy from 'copy-to-clipboard';
import { sprintf } from "sprintf-js";

import Edit from "./edit.jsx";
import Prompt from "components/prompt.jsx";
import { getSet } from "locales/index.js";
import { getUserLevel, getUserRoles, getStaticAddr } from "js/base.js";
import { companyAssetUpdateStatus } from "js/api4artist.js";
import { toLocalDatetime, fixStrLen } from "js/utils.js";


class Table extends Component {
  constructor (props) {
    super(props); // items, updateItem, browseNodes

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("assets"),
      userRole: "",
      lastPacket: null,
      statusList: ["待打包", "预发布", "上架", "下架"],
      target: null,
      promptMsg: "", promptDelete: false, promptAlertStatus: false,
    };

    this.renderRows = this.renderRows.bind(this);
  }

  componentDidMount() {
    if (getUserLevel() === "admin") {
      this.setState({userRole: "admin"});
    } else {
      let levels = getUserRoles();
      if (levels.indexOf("引擎美术") > -1) {
        this.setState({userRole: "artist"});
      }
    }
  }

  display4admin() {
    return this.state.userRole === "admin" ? "": "none";
  }

  deleteAsset() {
    // if (!window.confirm("删除模型将无法恢复, 确认?")) {
    //   return;
    // }
    companyAssetUpdateStatus({asset_id: this.state.target.assetId, status: "删除"}, res => {
      if (res.code === 0) {
        if (this.props.afterDelete) this.props.afterDelete();
      } else {
        message.error("failed");
      }
    })
  }

  nextStatus (current) {
    if (this.state.userRole === "") {
      return "";
    }

//    if (this.state.userRole === "artist" && current === "待打包") {
//      return "";
//    }

    if (current === "待打包") {
      return "";
    }

    if (current === "预发布") {
      return  "上架";
    } else if (current === "上架") {
      return "下架";
    } else if (current === "下架") {
      return "上架";
    }

    let idx = this.state.statusList.indexOf(current);
    if (idx === -1 || idx === this.state.statusList.length - 1) {
      return "";
    }
    return this.state.statusList[idx+1];
  }

//  switchStatus(id, name, event) {
//    let elem = event.target;
//    let text = event.target.innerText;
//    let newStatus = text !== "上架" ? "上架" : "下架";
//    let className = "btn btn-sm query-button ";
//    className += text !== "上架" ? "btn-success": "btn-secondary";
//    if (!window.confirm(`确认修改 "${name}" 状态为 "${newStatus}"？`)) {
//      return;
//    }
//    companyAssetUpdateStatus({asset_id: id, status: newStatus}, (res => {
//      if(res.code === 0) {
//        elem.innerText = newStatus;
//        elem.className = className;
//      } else {
//        alert("修改状态失败！");
//      }
//    }))
//  }

  alertStatus(item, event) {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    let newStatus = this.nextStatus(item.status);
    if (!newStatus) {
      message.error(langCommon.tips["noOperationPermisson"]);
      return;
    }

    this.setState({
      target: item,
      promptMsg: sprintf(langPage["tipChangeStatusOfAsset"], item.assetId, newStatus),
      promptAlertStatus: true,
    });
  }

  renderSortIcon(name) {
    // console.log("--> renderOrder:", name, this.props.ascending);
    if (name !== this.props.query.orderBy || this.props.query.ascending === "") {
      return (<BiSort size={18} color="grey"/>);
    } else if (this.props.query.ascending === "true") {
      return (<BiSortUp size={18} color="grey"/>);
    } else {
      return (<BiSortDown size={18} color="grey"/>);
    }
  }

  // `删除模型 "${item.assetName}" 将无法回复!`
  renderRows() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return this.props.items.map((item, idx) => {
      // let className = "btn btn-sm query-button btn-success";
      // if (item.status === "下架") className = "btn btn-sm query-button btn-secondary";
      // "http://192.168.0.155:8100/Preview/ESM_safety_1000001_Thunmnail.png"
      let dir = ["待打包", "预发布"].indexOf(item.status) > -1 ?
        "/meshes_preview/Preview" : "/meshes/Preview";

      let thumbnail = getStaticAddr() + `${dir}/${item.assetId}_320x240.jpg`;

      let browseNodeIds = [], browseNodeNames = [];
      let browseNodes = item.subbrowseIds.map(e => this.props.subbrowseMap[e]);
      // console.log("~~~", this.props.subbrowseMap, browseNodes);
      browseNodes.forEach(e => browseNodeIds.push(e ? e.browseId + "/" + e.subbrowseId : "??"));
      browseNodes.forEach(e => browseNodeNames.push(
        e ? e.browseName + "/" + e.subbrowseName : "??"
      ));

//      // this doesn't work after react build, ??
//      const copyItem = async () => {
//        let text = JSON.stringify(item, "", "  ");
//        await navigator.clipboard.writeText(text);
//        console.log(`~~~ Copied item ${item.assetId} to clipboard!`);
//      }
      const copyItem = () => {
        let text = JSON.stringify(item, "", "  ");
        copy(text);
      }

      // <AiOutlineCopy />
      let checked = item._selected === 1;
      // let disabled = ["预发布", "上架", "下架"].indexOf(item.status) === -1;
      let disabled = item._selected === 0;
//      if (item.assetId === 'SM_other_type_1000017') {
//         console.log(`~~~ ${JSON.stringify(item)}, ${checked}, ${disabled}`);
//      }
// title={disabled ? "" : "选择重新打包模型"}

      return (
        <tr key={item.assetId} id={item.assetId} data-index={idx} name={item.assetName}>
          <td style={{cursor:'pointer'}} title="click to copy" onClick={copyItem}>
            <input type="checkbox"
              // defaultChecked
              name={item.assetId} checked={checked} disabled={disabled}
              onChange={(event) =>  {
                if (!disabled) { this.props.selectItem(event.target.name); }
              }}
            />
            {"  " + item.assetId}
          </td>
          <td> {item.assetName} </td>
          <td> <img alt="thumbnail" src={thumbnail} /> </td>
          <td title={browseNodeIds.join(", ") || ""}> {browseNodeNames.join(", ") || "-"} </td>
          <td title={langCommon.fields["updatedAt"] + 
            ": " + toLocalDatetime(item.updateTime)}
          >
            {toLocalDatetime(item.createTime)}
          </td>

          <td style={{display: this.display4admin()}} title={item.userId}>
            {item.userName || "-"}
          </td>


          <td>
            {langCommon.status[item.status]}
            <span style={{display:"none"}} className="span-button"
              onClick={this.alertStatus.bind(this, item)}
            >
              {item.status}
            </span>
          </td>

          <td>
            <Edit
              asset={item}
              updateItem={this.props.updateItem}
              subbrowseMap={this.props.subbrowseMap}
              browseNodes={this.props.browseNodes}
              commodityCategories={this.props.commodityCategories}
              commodityCategoryMap={this.props.commodityCategoryMap}
              // updateAreas={this.props.updateAreas}
            />

            <span className="span-seperator"> | </span>
            <span className="span-button"
              onClick={() => {
                this.setState({
                  promptDelete: true,
                  target: item,
                  promptMsg: sprintf(
                    langPage["tipDelete"], fixStrLen(item.assetName, 12),
                  ),
                });
              }}
            >
              {langCommon.actions["delete"]}
            </span>
          </td>
        </tr>
      )
    })
  }

  render() {
    let langCommon = this.state.langCommon;

    return (<>
      <table className="table-content">
        <thead>
          <tr>
            <th style={{cursor:'pointer'}}>
              <input type="checkbox" title="" name="_ALL"
                checked={this.props.selectAll === true}
                onChange={(event) => this.props.selectItem("_ALL")}
              />
              {"  " + langCommon.fields["assetId"]}
              <span onClick={() => this.props.updateOrders("assetId")}>
                {this.renderSortIcon("assetId")}
              </span>
            </th>
            <th>{langCommon.fields["name"]}</th>
            <th>{langCommon.fields["thumbnail"]}</th>
            <th>{langCommon.fields["browse"]}</th>

            <th style={{cursor:'pointer'}}>
              {langCommon.fields["createdAt"]}
              <span onClick={() => this.props.updateOrders("createTime")}>
                {this.renderSortIcon("createTime")}
              </span>
            </th>

            <th style={{display: this.display4admin()}}>
              {langCommon.fields["creator"]}
            </th>
            <th>{langCommon.fields["status"]}</th>
            <th>{langCommon.fields["action"]}</th>
          </tr>
        </thead>

        <tbody>
          {this.renderRows()}
        </tbody>
      </table>

      <Prompt
        show={this.state.promptDelete}
        message={this.state.promptMsg}
        close={() => this.setState({promptDelete: false})}
        ok={() => this.deleteAsset()}
        reject={() => {}}
      />

      <Prompt
        show={this.state.promptAlertStatus}
        message={this.state.promptMsg}
        close={() => this.setState({promptAlertStatus: false})}
        ok={() => {
          let newStatus = this.nextStatus(this.state.target.status);

          let data = {asset_id: this.state.target.assetId, status: newStatus}
          companyAssetUpdateStatus(data, (res => {
              if(res.code === 0) {
                this.props.updateItem({assetId: this.state.target.assetId, status: newStatus});
              } else {
                message.error(res.message);
              }
          }))
        }}
        reject={() => {}}
       />
    </>)
  }
}

export default Table;
