let records = {
  subtitle: "バッグエンド管理システム",
  changePassword: "パスワードの変更",
  logoff: "ログアウト",

  actions: {
    confirm: "確認",
    ok: "OK",
    cancel: "キャンセル",
    reset: "リセット",
    query: "クエリ",
    search: "サーチ",
    create: "新規作成",
    edit: "編集",
    delete: "削除",
    pleaseSet: "%1$s を設定してください",
    import: "インポート",
    browse: "閲覧",
    changePassword: "パスワードの変更",
  },

  placeholder: {
    enter: "入力してください",
    select: "選択ください",
    chars: "%1$s 文字",
    add: "追加",
  },

  status: {
    "on": "公開",
    "off": "非公開",
    "deleted": "削除",
    "上架": "公開",
    "下架": "非公開",
    "待打包": "パッケージ待ち",
    "预发布": "プリリリース",
  },

  fields: {
    beginning: "開始時間",
    end: "終了時間",
    username: "ユーザー名",
    name: "名前",
    email: "メール",
    phoneOrEmail: "携帯番号またはメールアドレス",
    phoneNumberOrEmailAddress: "携帯番号またはメールアドレス",
    phoneNumber: "携帯番号",
    emailAddress: "メールアドレス",
    password: "パスワード",
    role: "役",
    creator: "クリエイター",
    status: "ステータス",
    createdAt: "作成時間",
    updatedAt: "更新時間",
    thumbnail: "サムネイル",
    action: "操作",
    tips: "注意",
    warning: "警告",
    preview: "プレビュー",
    clickToUpload: "クリックしてアップロード",
    type: "タイプ",
    kind: "種類",
    filepath: "ファイルパス",
    description: "詳細",
    oldPassword: "現在のパスワード",
    newPassword: "新しいパスワード",
    passwordConfirmation: "パスワード再確認",
    browse: "ブラウズ",
    category: "カテゴリ",
    assetId: "アセットID",
    attribute: "属性",
  },

  page: {
    items: " %1$s-%2$s 条",
    total: "合計 %1$s",
    pageSize: "条/ページ"
  },

  assetKinds: {
    SM: "Static Mesh (SM)",
    M: "Material (M)",
    BSM: "Blueprint (BSM)",
    ESM: "Particles (ESM)",
    ASM: "Animation (ASM)",
    PL: "Point Light (PL)",
    SL: "Spot Light (SL)",
    RL: "Rect Light (RL)",
    RC: "Reflection Capture (RC)"
  },

  tips: {
    errGetSts: "STStokenの取得失敗",
    enterName: "名前を入力ください",
    invalidName: "名前は正しいではない",
    failedToUpload: "アップロード失敗",
    failedToUpdate: "アップデート失敗",
    failedToCreate: "作成失敗",
    failedToChangeStatus: "ステータス修正失敗",
    failedToDelete: "削除失敗",
    selectFile: "ファイルを選択ください",
    fileSizeLargerThan: "ファイルサイズは %1$s を超えてはいけません",
    emptyFile: "空のファイル",
    invalidFile: "正しいファイルではない",
    missingField: "フィールド %1$s がありません",
    notNumber: "%1$s は数値ではありません",
    passwordNotMatch: "パスワードが一致していません",
    invalidOldPassword: "現在のパスワードは無効です",
    validPassword: "パスワードには 0 ～ 9、a ～ z、A ～ Z のみ、長さの範囲は 6 ～ 20 です",
    relogin: "もう一度ログインしてください",
    currentLoggedIn: "現在ログインしているユーザーは %1$s です。3 秒後に再度ログインしてください…",
    separatedByComma: "カンマ区切り",
    noOperationPermisson: "操作権限なし",
  }
};

export default records;
