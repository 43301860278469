import React, { Component } from "react";
import { PlusSquareOutlined, DeleteOutlined } from "@ant-design/icons";
// import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

import { getSet } from "locales/index.js";
import "css/modal.css";


class Areas extends Component {
  constructor (props) {
    super(props); // assetId, areas, updateItem

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("assets"),
      areas: (this.props.areas || []).map(e => ({...e})),
    };
  }

  componentDidMount() {}
  componentDidUpdate() {}

  updateItem(idx, key, event) {
    let value = event.target.value.trim();
    let areas = this.state.areas;
    // console.log(`~~~ ${idx}, ${key}, ${event.target.value}`)

    if (key === "name") {
      areas[idx].name = value;
      this.setState({areas}, this.props.updateItem("areas", areas));
    } else if (key === "assetIds") {
      // .filter(x => x.length > 0)
      value = value.replaceAll(";", " ").replaceAll(",", " ").split(/\s+/);
      areas[idx].assetIds = value;
      this.setState({areas}, this.props.updateItem("areas", areas));
    } else if (key === "index") {
      value = value.replaceAll(";", " ").replaceAll(",", " ").split(/\s+/);
      areas[idx].index = value;
      this.setState({areas}, this.props.updateItem("areas", areas));
    }
  }

  renderArea(value, idx) {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    let index = value.isAdded ?
      <input maxLength="32" className="modal-input"
        placeholder={langCommon.placeholder["enter"]}
        value={value.index.join(", ")}
        onChange={(event) => this.updateItem(idx, "index", event)}
      /> : JSON.stringify(value.index);

    return(<>
      <tr>
        <td>
          <DeleteOutlined style={{color: "grey", fontSize:"120%"}}
            onClick={() => {
              let areas = this.state.areas;
              areas = [...areas.slice(0, idx), ...areas.slice(idx + 1)];
              this.setState({areas: areas}, this.props.updateItem("areas", areas));
            }}
          />
          {"  " + langPage["areaIndx"]}:
        </td>
        <td> {index} </td>
      </tr>

      <tr>
        <td>{langPage["areaName"]}:</td>
        <td>
          <input maxLength="32" placeholder={langCommon.placeholder["enter"]}
            className="modal-input"
            value={value.name} onChange={(event) => this.updateItem(idx, "name", event)}
          />
        </td>
      </tr>

      <tr>
        <td>
          <span title="assetIds" style={{color: 'black'}}>
            {langPage["variantId"]}:
          </span>
        </td>
        <td title={langCommon.tips["separatedByComma"]}>
          <input maxLength="128" className="modal-input"
            placeholder={langCommon.placeholder["enter"]}
            value={value.assetIds ? value.assetIds.join(", "): ""}
            onChange={(event) => this.updateItem(idx, "assetIds", event)}
          />
        </td>
      </tr>
    </>
    )
  }

  render() {
    let langPage = this.state.langPage;

    return (<>
      <tr>
        <td>
          <PlusSquareOutlined style={{color:"#1890FF", fontSize:"120%"}} onClick={() => {
            let area = {index: [], name: "", assetIds: [], isAdded: true};
            let areas = this.state.areas;
            areas.push(area);
            this.setState({areas: areas});
          }}/>
          <span style={{fontWeight:"bold"}}> {langPage["variant"]} </span>
        </td>
        <td> ({langPage["tip1"]})</td>
      </tr>

      {this.state.areas.map((value, idx) => this.renderArea(value, idx))}
    </>)
  }
}

export default Areas;
