import React, { Component } from "react";
// import DatePicker from 'react-date-picker';
// import { addDays } from 'date-fns';
// import Datetime from "react-datetime";
// import DatePicker from "react-datepicker";
import { Select, message } from "antd";
import { DatePicker, Space } from "antd";
import moment from "moment";
// import 'react-date-range/dist/styles.css'; // main css file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import "react-datepicker/dist/react-datepicker.css";

import Page from "components/page.jsx";
import New from "./new.jsx";
import Table from "./table.jsx";
import { getSet } from "locales/index.js";
import { time2DateStr,validUserTel,validUserEmail } from "js/utils.js";
import { queryUser } from "js/api4admin.js";
import 'css/content.css';

const { Option } = Select;
const { RangePicker } = DatePicker;


class Accounts extends Component {
  constructor (props) {
     super(props);

     this.state = {
       langCommon: getSet("common"),
       langPage: getSet("accounts"),
       query: {
         page: 1, pageSize: 10, name: "", telOrEmail:"",
         role: null, startDate: "", endDate: "",
         orderBy: "", ascending: "",
       },
       items: [], totalItems: 0,
       pageSizes: [10, 15, 20, 30],
       // addDays(new Date(), -7); new Date()

       updateItem: this.updateItem.bind(this),
       queryUsers: this.queryUsers.bind(this),
       updatePageNum: this.updatePageNum.bind(this),
       updatePageSize: this.updatePageSize.bind(this),
       updateOrders: this.updateOrders.bind(this),
     };
  }

  componentDidMount() {
    let langPage = this.state.langPage;
    document.title = langPage["sideNav"];

    /*
    let query = this.state.query;
    // console.log(window.location.search);
    // console.log(new URLSearchParams(window.location.search));
    // getItem
    */
    queryUser(this.state.query, res => {
      if (res.code !== 0)  {
        return;
      }
      this.setState({items: res.data.items, totalItems: res.data.totalItems});
    });
  }

  updatePageNum(num) {
    let query = {...this.state.query};
    query.page = num;
    queryUser(query, res => {
      if (res.code !== 0) {
        return;
      }
      this.setState({query: query, items: res.data.items}); // totalItems: res.data.totalItems,
    })
  }

  updatePageSize(size) {
    if (size <= 0 || size === this.state.query.pageSize) {
      return;
    }

    let query = {...this.state.query};
    query.pageSize = size;
    this.setState({query: query});

    queryUser(query, res => {
      if (res.code !== 0) {
        return;
      }
      this.setState({items: res.data.items}); // totalItems: res.data.totalItems,
    })
  }

  queryUsers() {
    let langPage = this.state.langPage;
    let query = {...this.state.query, page: 1}; // reset page
    query.name = query.name.trim();
    query.telOrEmail = query.telOrEmail.trim();
    this.setState({query: query});

    query.tel = null;
    query.email = null;
    if (query.telOrEmail !== "") {
      if (validUserTel(query.telOrEmail)) {
        query.tel = query.telOrEmail;
      } else if (validUserEmail(query.telOrEmail)) {
        query.email = query.telOrEmail;
      } else {
        message.warn(langPage["wrongPhoneOrEmail"]);
        return;
      }
    }

    if(query.startDate && query.endDate) {
      let t1 = new Date(Date.parse(query.startDate));
      let t2 = new Date(Date.parse(query.endDate));
      if (t1 > t2) {
        message.warn(langPage["wrongDateRange"]);
        return;
      }
    }

    queryUser(query, res => {
      this.setState({items: res.data.items});
      // 在 page > 1 时, 不需要后端提供 totalItems 的值
      if (res.data.items.length === 0 || res.data.totalItems > 0) {
        this.setState({totalItems: res.data.totalItems});
      }
    });
  }

  resetQuery() {
    let langCommon = this.state.langCommon;

    let query = {
      page: 1, pageSize: this.state.query.pageSize,
      name: "", telOrEmail: "",
      role: null, startDate: "", endDate: "",
      orderBy: "", ascending: "",
    };
    this.setState({ query: query});

    let picker = document.getElementById("datePicker");
    picker.setAttribute(
      "placeholder",
      `${langCommon.fields["beginning"]}-${langCommon.fields["end"]}`,
    );

    queryUser(query, res => {
       // 在 page > 1 时, 不需要后端提供 totalItems 的值
      this.setState({items: res.data.items});
      if (res.data.items.length === 0 || res.data.totalItems > 0) {
        this.setState({totalItems: res.data.totalItems});
      }
    });
  }

  updateQuery(event) {
    let query = {...this.state.query};
    query[event.target.name] = event.target.value;
    this.setState({query: query});
  }

  updateQuery2(value, name) {
    // console.log(value, name);
    let query = {...this.state.query};
    query[name] = value;
    this.setState({query: query});
  }

  onDateChange(id, value) {
    let query = {...this.state.query}
    let picker = document.getElementById(id);
    let date = time2DateStr(value);
    // console.log(id, picker.name, value);
    query[picker.name] = date;

    this.setState({query});
    picker.setAttribute("placeholder", date);
  }

  dateUpdate(id, update) {
    // console.log(update);
    let picker = document.getElementById(id);
    let query = {...this.state.query}

    if (!query.startDate || !query.endDate) {
      query.startDate = update[0];
      query.endDate = update[1]
    } else {
      let d1 = Math.abs(update[0] - query.startDate);
      let d2 = Math.abs(update[0]  - query.endDate);
      if (d1 < d2) {
        query.startDate = update[0];
      } else {
        query.endDate = update[0];
      }
    }

    this.setState({query: query});
    let d1 = query.startDate ? time2DateStr(query.startDate) : "";
    let d2 = query.endDate ? time2DateStr(query.endDate) : "";
    if (d1 || d2) picker.setAttribute("placeholder", `${d1} - ${d2}`);
  }

  updateItem(data) {
    let items = this.state.items;
    let idx = items.findIndex(e => e.id === data.id);
    if (idx === -1) {
      return;
    }
    items[idx] = {...items[idx], ...data};
    this.setState({items: items});
  }

  updateRangePicker = (dates, dateStrings) => {
    let query = {...this.state.query};
    if (!dateStrings) {
      query.startDate = "";
      query.endDate = "";
    } else {
      query.startDate = dateStrings[0];
      query.endDate = dateStrings[1];
    }
    this.setState({query: query});
  }

  updateOrders(name) {
    // console.log("~~~", name);
    if (name === "") {
      return
    }

    let query = {...this.state.query};

    // "" -> "true" -> "false" -> ""
    if (query["orderBy"] !== name) {
      query["orderBy"] = name;
      query["ascending"] = "true";
    } else if (query["ascending"] === "") {
      query["ascending"] = "true";
    } else if (query["ascending"] === "true") {
      query["ascending"] = "false";
    } else {
      query["ascending"] = "";
    }

    this.setState({query: query}, this.state.queryUsers);
  }

  /*
          <DatePicker
            id="datePicker"
            selectsRange={true}
            startDate={this.state.query.startDate}
            endDate={this.state.query.endDate}
            onChange={this.dateUpdate.bind(this, "datePicker")}
            isClearable
            placeholderText="开始时间 － 结束时间"
          />
  */

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return (<>
      <div className='user-query'>
        <div className="input-parameter">
          <span> {langCommon.fields["name"]}: </span>
          <input type="text" name="name" placeholder={langCommon.placeholder["enter"]}
            value={this.state.query.name}
            onChange={this.updateQuery.bind(this)}
          />
        </div>

        <div className="input-parameter">
          <span> {langCommon.fields["phoneOrEmail"]}: </span>
          <input type="text" name="telOrEmail" placeholder={langCommon.placeholder["enter"]}
            value={this.state.query.telOrEmail} onChange={this.updateQuery.bind(this)}
          />
        </div>

        <div className="input-parameter">
          <span> {langCommon.fields["role"]}: </span>

          <Select
            style={{ width: 180, textAlign: 'left' }}
            allowClear
            placeholder={langCommon.placeholder["select"]}
            value={this.state.query.role}
            onChange={(value) => this.updateQuery2(value, "role")}
          >
            {["引擎开发", "引擎美术", "引擎测试", "交付工程师"].map((e) => {
              return (<Option key={e}> {langPage[e]} </Option>)
            })}
          </Select>
        </div>

        <div className="input-parameter">
          <span> {langCommon.fields["createdAt"]}: </span>
          <Space direction="vertical" size={12}>
            <RangePicker
              id="datePicker"
              placeholder={[langCommon.fields["beginning"], langCommon.fields["end"]]}
              value={
                this.state.query.startDate !== "" ?
                [moment(this.state.query.startDate), moment(this.state.query.endDate)] :
                ["", ""]
              }
              onChange={this.updateRangePicker}
            />
          </Space>
        </div>

        <div className="div-button-to-right">
          <button className="btn btn-secondary btn-sm button-blank query-button"
            onClick={this.resetQuery.bind(this)}> {langCommon.actions["reset"]}
          </button>
          <button className="btn btn-primary btn-sm query-button button-blue"
            onClick={this.queryUsers.bind(this)}> {langCommon.actions["search"]}
          </button>
        </div>
      </div>

      <div className="content-area">
        <div className="content-top">
          <div>{langPage["tableTitle"]}</div>
          <div className="div-button-to-right">
            <New update={this.state.queryUsers} />
          </div>
        </div>

        <Table
          items={this.state.items}
          updateItem={this.state.updateItem}
          updateOrders={this.state.updateOrders}
          query={this.state.query}
          afterDelete={ () => {
            this.state.queryUsers();
            //!!! page > 1 时, 后端没有提供更新的 totalItems 值
            this.setState({totalItems: this.state.totalItems - 1});
          }}
        />

        <Page
          page={this.state.query.page}
          pageSize={this.state.query.pageSize}
          totalItems={this.state.totalItems}
          updatePageNum={this.state.updatePageNum}
          pageSizes={this.state.pageSizes}
          updatePageSize={this.state.updatePageSize}
        />
      </div>
    </>)
  }
}

export default Accounts;
