import React, { Component } from "react";
import { message } from "antd";
import { Modal } from "react-bootstrap";
import { sprintf } from "sprintf-js";

import path from "path";
import BrowseThumbnail from "components/browse_thumbnail.jsx";
import putObject from "components/put_object.jsx";
import { getUserId } from "js/base.js";
import { userAssetCreate } from "js/api4user.js";
import { randStr } from "js/utils.js";
import { getSet } from "locales/index.js";
import "css/modal.css";


class NewAsset extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("userAssets"),
      onSubmit: false,    show: false,
      targetFileName: "", assetSelected: false,   // 选择的文件名, 用户输入的模型名
      asset: { name: "", link: "", thumbnail: "" }, // packagePath: ""
      image: { file: null, ext: ""},

      client: null, expiration: "",
    };
  }

  componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("_action") === "import") {
      this.setState({show: true});
    }
  }

  clearInput = () => {
    if (this.state.onSubmit) {
       return;
    }

    this.setState({
      onSubmit: false,    show: false,
      targetFileName: "", assetSelected: false,
      asset: {name: "", link: "", thumbnail: ""}, // packagePath: ""
      image: { file: null, ext: ""},
    });

    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("_action") === "import") {
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }

  newAsset() {
    let langCommon = this.state.langCommon;

    let data = {
      name: this.state.asset.name,
      link: this.state.asset.link,
      // packagePath: this.state.asset.packagePath,
      thumbnail: this.state.asset.thumbnail,
    }

    if (data.name.length === 0 || data.name.length > 64) {
      message.warn(langCommon.tips["invalidName"]);
      return;
    }

    userAssetCreate(data,
      res => {
        this.setState({onSubmit: false});
        if (res.code === 0 ) {
          this.clearInput();
          if (this.props.update) this.props.update();
          // window.location.reload();
        }
      },
      () => this.setState({onSubmit: false}),
    )
  }

  // valid asset file -> upload asset -> upload thumbnail -> api request -> reload page show new asset
  handleSubmit = () => {
    let langCommon = this.state.langCommon;

    if (this.state.onSubmit) {
      return;
    }

    let files = document.getElementById("assetFile").files;
    if (!this.state.assetSelected || files.length === 0 ) {
      message.warn(langCommon.tips["selectFile"]);
      return;
    }
    let target = files[0];
    // console.log(target);

    if (this.state.asset.name.length === 0) {
      message.warn(langCommon.tips["enterName"]);
      return;
    }
//    if (!/^[\p{Script=Han}a-zA-Z0-9-_]+$/u.test(this.state.asset.name)) {
//      message.warn("模型名称只可包含汉字、字母、数字、下划线（_）、减号（-）！");
//      return;
//    }
    if (this.state.asset.name.length > 15) {
      message.warn(langCommon.tips["invalidName"]);
      return;
    }
    if (target.size > 300*1024*1024) {
      message.warn(sprintf(langCommon.tips["fileSizeLargerThan"], "300MB"));
      return;
    }

    let suffix = "." + target.name.split(".").pop();

    this.setState({ onSubmit: true }, () => {
      let key = `meshes/PrivateModels/${getUserId()}/` + randStr(32) + suffix;

      putObject("user_asset", key, target, link => {
        this.uploadImage(link);
      });
   });
  }

  uploadImage = (link) => {
    let asset = {...this.state.asset, link: link};

    if (!this.state.image.file) {
      this.setState({asset: asset}, this.newAsset);
      return;
    }

    let basename = path.basename(link).split(".")[0];
    let key = `meshes/PrivateModels/${getUserId()}/` + basename + `_${randStr(16)}.` +
      this.state.image.ext;

    putObject("user_asset_thumbnail", key, this.state.image.file, link => {
       asset.thumbnail = link;
       this.setState({onSubmit: false, asset: asset}, this.newAsset);
    });
  }

  handleAssetFile = (event) => {
    let langCommon = this.state.langCommon;

    let files = event.target.files;
    if (files.length === 0 ) {
      return;
    }

    let target = files[0];

    if (!target.name.endsWith(".zip")) {
      message.warn(langCommon.tips["invalidFile"]);
      return;
    }
    if (target.size === 0) {
      message.warn(langCommon.tips["emptyFile"]);
      return;
    }
    if (target.size > 300*1024*1024) {
      message.warn(sprintf(langCommon.tips["fileSizeLargerThan"], "300MB"));
      return;
    }

    let asset = {...this.state.asset};
    asset.name = target.name.split(".")[0].substring(0, 15).trim();

    this.setState({
      targetFileName: target.name,
      assetSelected: true,
      asset: asset,
    });
    // console.log(`>>> select asset file: ${target.name}`);
  }

  updateValue(event) {
    let elem = event.target;
    let asset = {...this.state.asset};
    asset[elem.name] = elem.value;
    this.setState({asset: asset});
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return(<>
      <div className="div-button-to-right">
        <button id="assetImportBtn"
          className="btn btn-primary btn-sm query-button button-blue"
          onClick={() => this.setState({show: true})}
        > {langCommon.actions["import"]}
        </button>
      </div>

      <Modal
        show={this.state.show}
        onHide={this.clearInput}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title>{langPage.importAsset}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="upload-field-title">
            <span style={{color: "red"}}> * </span> {langCommon.fields["filepath"]} (zip):
          </div>
          <div>
            <input className="modal-input" placeholder="max size 300MB"
              title={this.state.targetFileName}
              value={this.state.targetFileName}
            />
            <input style={{display:"none"}} id="assetFile" className="modal-input"
              type="file" onChange={this.handleAssetFile}
            />
            <button className="btn btn-primary btn-sm query-button button-blue"
              onClick={() => document.getElementById('assetFile').click()}
            >
              {langCommon.actions["browse"]}
            </button>
          </div>

          <br></br>
          <div className="upload-field-title">
            <span style={{color: "red"}}> * </span>{langCommon.fields["name"]}:
          </div>
          <div>
            <input type="text" placeholder={sprintf(langCommon.placeholder["chars"], 15)}
              maxLength="15"
              className="modal-input modal-input-fname"
              name="name"
              value={this.state.asset.name}
              title={this.state.asset.name}
              onChange={this.updateValue.bind(this)}
            />
          </div>

          <br></br>
          <div className="upload-field-title">
            {langCommon.fields["thumbnail"]} (support jpeg and png format, file size limit is 2MB):
          </div>

          <BrowseThumbnail
            thumbnail=""
            updateImage={(val) => this.setState({image: val})}
          />
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.clearInput}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm btn-blue button-blue"
            onClick={this.handleSubmit}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default NewAsset;
