let records = {
  sideNav: "Asset Management",
  tableTitle: "Assets",
  tipCreatePackage: "Are you sure to create a batch package?",
  changeStatus: "Change status",
  tipChangeStatusOfSelectedAsset: "Change %1$s selected asset(s) status from %2$s to:",
  tipChangeStatusOfAsset: "Are you sure you want to change the status of %1$s to %2$s?",
  areaIndx: "Area index",
  areaName: "Area name",
  variantId: "Variant ID",
  variant: "Variant",
  tip1: "use variation in the client",
  tip2: "show category in the client",
  tipSelectCategory: "Please select a category",
  tipInvalidAssetName: "invalid asset name",
  editAsset: "Edit asset",
  assetBasics: "Asset basics",
  assetBasicsDetail: "basic information required to create an asset",
  browseNode: "Browse node",
  tipEnterAssetInfo: "please enter asset information",
  createAsset: "Create asset",
  package: "Package",
  tipDelete: "Are you sure you want to permanently delete this asset: %1$s?",
};

export default records;
