import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Cascader, Select, message } from "antd";

import Areas from "./areas.jsx";
import { getSet } from "locales/index.js";
import { companyAssetUpdate } from "js/api4artist.js";
import { validAreas } from "js/assets.js";
import "css/modal.css";

const { Option } = Select;


class Edit extends Component {
  constructor (props) {
    super(props); // asset, browseNodes, subbrowseMap

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("assets"),
      onSubmit: false, show: false, errMsg: "",

      asset: {
        assetId: this.props.asset.assetId,
        assetName: this.props.asset.assetName,
        browseIds: this.props.asset.browseIds,
        subbrowseIds: this.props.asset.subbrowseIds,
        areas: this.props.asset.areas || [],
      },

      resetInput: this.resetInput.bind(this),
      updataBrowseNode: this.updataBrowseNode.bind(this),
      handleSubmit: this.handleSubmit.bind(this),
    };
  }

  componentDidMount() {
    // console.log("~~~", this.state.asset);
    // console.log(this.props.asset.subbrowses.map(e => e.id));
  }

  componentDidUpdate() {
  }

  resetInput() {
    this.setState({
      onSubmit: false, show: false, errMsg: "",

      // name: this.props.asset.name,
      asset: {
        assetId: this.props.asset.assetId,
        assetName: this.props.asset.assetName,
        browseIds: this.props.asset.browseIds,
        subbrowseIds: this.props.asset.subbrowseIds,
        areas: this.props.asset.areas || [],
      }
    })  
  }

  updataBrowseNode(value) {
    let asset = {...this.state.asset};
    let browseIds = value.map(
      e => this.props.subbrowseMap[e] ? this.props.subbrowseMap[e].browseId : "??"
    );

    asset.subbrowseIds = [...value];
    asset.browseIds = browseIds;
    this.setState({asset: asset});
  }

  updateAsset(event) {
    let elem = event.target;
    let asset = {...this.state.asset};
    asset[elem.name] = elem.value;
    this.setState({asset: asset});
  }

  updateItem = (key, val) => {
    // console.log(`~~~ ${key}, ${JSON.stringify(val)}`);
    let asset = {...this.state.asset};
    asset[key] = val;
    this.setState({asset});
  }

  handleSubmit() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;
    if (this.state.onSubmit) {
      return;
    }

    // console.log("--> handleSubmit", this.state.asset);
    let data = {...this.state.asset};
    if (data.subbrowseIds.length === 0) {
      message.warn(langCommon["tipSelectCategory"]);
      return;
    }

    data.assetName = data.assetName.trim();
    // if (!/^[\p{Script=Han}a-zA-Z0-9-_ ]{1,64}$/u.test(data.assetName)) {
    if (data.assetName.length === 0 || data.assetName.length > 64) {
      message.warn(langPage["tipInvalidAssetName"]);
      return;
    }

    let res = validAreas(this.state.asset.areas); // [err, p]
    if (res[0]) {
      // console.log(`!!! validAreas: ${err}, ${p}`);
      message.warn(res[0]);
      return;
    }

    this.setState({onSubmit: true});

    companyAssetUpdate(data,
      res => {
        this.setState({onSubmit: false});
        if (res.code === 0 ) {
          this.props.updateItem(data);
          this.resetInput();
        }
      },
      () => this.setState({onSubmit: false}),
    )
  }

  renderBrrowseNodes() {
    let langCommon = this.state.langCommon;

    let options = this.state.asset.subbrowseIds.map(e => {
      let node = this.props.subbrowseMap[e];
      let label = node ? node.browseName + " / " + node.subbrowseName : "?/?";
      return (<Option value={e} label={label}> {label} </Option>)
    })

    return (<tr>
      <td> </td>
      <td>
        <Select
          mode="multiple"
          style={{ width: '272px' }}
          allowClear
          placeholder={langCommon.placeholder["select"]}
          defaultValue={[]}
          value={this.state.asset.subbrowseIds}
          onChange={this.updataBrowseNode.bind(this)}
        >
          {options}
        </Select>
      </td>
    </tr>)
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return (<>
      <span className="span-button" onClick={()=> {
        this.setState({show: true});
      }}> {langCommon.actions["edit"]} </span>

      <Modal
        show={this.state.show}
        onHide={this.resetInput.bind(this)}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title>{langPage["editAsset"]}</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{overflowY: "scroll"}}>
          <table className="table-input">
            <tbody>
              <tr>
                <td colSpan="2" style={{textAlign:"left"}}>
                  <span style={{fontWeight:"bold"}} >
                  {langPage["assetBasics"]}
                  </span>
                  {" "}({langPage["assetBasicsDetail"]})
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'black'}}>
                    {langCommon.fields["assetId"]}:
                  </span>
                </td>
                <td> {this.props.asset.assetId} </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'back'}}>
                    {langCommon.fields["name"]}:
                  </span>
                </td>
                <td>
                 <input name="assetName" maxLength="64" placeholder="1~64 characters"
                    className="modal-input"
                    value={this.state.asset.assetName}
                    onChange={this.updateAsset.bind(this)}
                  />
                </td>
              </tr>

              <tr>
                <td colSpan="2" style={{textAlign:"left"}}>
                  <span style={{fontWeight:"bold"}} >
                    {langCommon.fields["browse"]}
                  </span>
                  {" "}({langPage["tip2"]})
                </td>
              </tr>

              <tr>
                <td>
                  <span style={{color: 'red', display: "none"}}> * </span>
                  <span>{langPage["browseNode"]}: </span>
                </td>

                <td>
                  <Cascader
                    // changeOnSelect
                    style={{width: "272px", color: "grey"}}
                    options={this.props.browseNodes}
                    placeholder={langCommon.placeholder["add"]}
                    onChange={value => {
                      // console.log("~~~", value); // [browseId, subbrowseId]
                      if (value.length === 0) {
                        return;
                      }
                      if (this.state.asset.subbrowseIds.indexOf(value[1]) !== -1) {
                        return;
                      }

                      let asset = {...this.state.asset};
                      asset.subbrowseIds.push(value[1]);
                      if (asset.browseIds.indexOf(value[0]) === -1) {
                        asset.browseIds.push(value[0]);
                      }
                      this.setState({asset: asset});
                    }}
                  />
                </td>
              </tr>

              {this.renderBrrowseNodes()}

              <Areas
                assetId={this.state.asset.assetId}
                areas={this.props.asset.areas}
                updateItem={this.updateItem}
              />

             <tfoot className="modal-tfoot">
               <tr> <td colSpan="2"> {this.state.errMsg} </td> </tr>
             </tfoot>
           </tbody>
         </table>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.resetInput.bind(this)}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm btn-blue button-blue"
            onClick={this.handleSubmit.bind(this)}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default Edit;
