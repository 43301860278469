import React, { Component } from "react";
// import Datetime from "react-datetime";
// import { Select } from "antd";

import Page from "components/page2.jsx";
import Table from "./table.jsx";
import { designQuery } from "js/api4user.js";
import { getSet } from "locales/index.js";
import "css/content.css";

// const { Option } = Select;


class MyProjects extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"), langPage: getSet("myProjects"),

      query: {
        page: 1, pageSize: 10, name: "", status: null,
        orderBy: "createTime", ascending: "false",
      },

      items: [], totalItems: 0, pageSizes: [10, 15, 20, 30],
    };
  }

  componentDidMount() {
    let langPage = this.state.langPage;
    document.title = langPage["sideNav"];

    this.doQuery();
  }

  doQuery = () => {
    let query = {...this.state.query, page: 1};
    query.name = query.name.trim();
    this.setState({query: query});

    designQuery(query, res => {
      if (res.code !== 0) {
        return;
      }

      this.setState({items: res.data.items});
      if (res.data.items.length === 0 || res.data.totalItems > 0) {
        this.setState({totalItems: res.data.totalItems});
      }
    });
  }

  resetQuery = () => {
    let query = {
      page: 1, pageSize: this.state.query.pageSize,
      name: "", status: null,
      orderBy: "createTime", ascending: "false",
    };
    this.setState({query: query});

    designQuery(query, res => {
      if (res.code === 0) {
        this.setState({items: res.data.items});
        if (res.data.items.length === 0 || res.data.totalItems > 0) {
          this.setState({totalItems: res.data.totalItems});
        }
      }
    })
  }

  updatePage = (item) => {
    let query = {...this.state.query};
    
    if (item.pageSize && item.pageSize > 0 && item.pageSize !== this.state.query.pageSize) {
      query.pageSize = item.pageSize;
    }
    if (item.page) {
      query.page = item.page;
    }

    designQuery(query, res => {
      if (res.code === 0) {
        this.setState({query: query, items: res.data.items});
      };
    });
  }

  updateQuery = (name, value) => {
    let query = {...this.state.query};
    query[name] = value;
    this.setState({query: query});
  }

  updateOrders = (name) => {
    // console.log("~~~", name);
    if (name === "") {
      return
    }

    let query = {...this.state.query};

    // "" -> "true" -> "false" -> ""
    if (query["orderBy"] !== name) {
      query["orderBy"] = name;
      query["ascending"] = "true";
    } else if (query["ascending"] === "") {
      query["ascending"] = "true";
    } else if (query["ascending"] === "true") {
      query["ascending"] = "false";
    } else {
      query["ascending"] = "";
    }

    this.setState({query: query}, this.doQuery);
  }

  updateItem = (data) => {
    let items = this.state.items;
    let idx = items.findIndex(e => e.id === data.id);
    if (idx === -1) {
      return;
    }

    items[idx] = {...items[idx], ...data};
    this.setState({items: items});
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return (<>
      <div className='user-query'>
        <div className="input-parameter">
          <span> {langCommon.fields["name"]}: </span>
          <input type="text" name="name" placeholder={langCommon.placeholder["enter"]}
            value={this.state.query.name}
            onChange={(event) => this.updateQuery("name", event.target.value)}
          />
        </div>

        {/*<div className="input-parameter">
          <span> {langCommon.fields["status"]}: </span>
          <Select
            style={{ width: 180, textAlign: 'left' }}
            name="status"
            allowClear
            placeholder={langCommon.placeholder["select"]}
            value={this.state.query.status}
            onChange={(value) => this.updateQuery("status", value)}
          >
            <Option value="上架">{langCommon.status["上架"]}</Option>
            <Option value="下架">{langCommon.status["下架"]}</Option>
          </Select>
        </div>*/}

        <div className="div-button-to-right">
          <button className="btn btn-secondary btn-sm button-blank query-button"
            onClick={this.resetQuery}> {langCommon.actions["reset"]}
          </button>

          <button className="btn btn-primary btn-sm query-button button-blue"
            onClick={this.doQuery} > {langCommon.actions["search"]}
          </button>
        </div>
      </div>

      <div className="content-area">
        <div className="content-top">
          <div>{langPage["tableTitle"]}</div>
        </div>

        <Table
          items={this.state.items}
          updateItem={this.updateItem}
          updateOrders={this.updateOrders}
          query={this.state.query}
          afterDelete={this.doQuery}
        />

        <Page
          page={this.state.query.page}
          pageSize={this.state.query.pageSize}
          totalItems={this.state.totalItems}
          pageSizes={this.state.pageSizes}
          updatePage={this.updatePage}
        />
      </div>
    </>)
  }
}

export default MyProjects;
