import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Select, message } from "antd";
import { sprintf } from "sprintf-js";

import { composePush2company } from "js/api4user.js";
import { getSet } from "locales/index.js";
import "css/modal.css";

const { Option } = Select;


class Push2Company extends Component {
  constructor (props) {
    super(props);

    this.state = {
      langCommon: getSet("common"),
      langPage: getSet("myComposes"),
      item: {...this.props.item, subbrowseId: ""},
    };
  }

  close = () => {
    this.setState({show: false});
  }

  update = (key, val) => {
    let item = this.state.item;
    item[key] = val;
    this.setState({item: item});
  }

  handleSubmit = () => {
    let langCommon = this.state.langCommon;

    if (!this.state.item.subbrowseId) {
      message.warn(sprintf(langCommon.actions["pleaseSet"], langCommon.fields["type"]));
      return;
    }
    composePush2company(this.state.item, res => {
      if (res.code === 0) {
        message.info("ok");
      } else {
        message.error(res.message);
      }
    });

    this.close()
  }

  render() {
    let langCommon = this.state.langCommon;
    let langPage = this.state.langPage;

    return(<>
      <span className="span-button" onClick={() => {
        this.setState({show: true, item: {...this.props.item, subbrowseId: ""}});
      }}>
        {langPage["push2company"]}
      </span>

      <Modal
        show={this.state.show}
        onHide={this.close}
        backdrop="static"
        keyboard={true}
        dialogClassName="modal-dialog-centered"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title>{langPage["push2company"]}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div> {langCommon.fields["name"]}:{"  "}
            <input name="name" maxLength="64" placeholder="64 characters"
              className="modal-input"
              value={this.state.item.name}
              onChange={(event) => this.update("name", event.target.value)}
            />
          </div>

          <br></br>
          <div>
            {langCommon.fields["type"] + ": "}
            <Select
              // mode="multiple"
              style={{ width: '272px' }}
              // allowClear
              placeholder="please select"
              defaultValue=""
              value={this.state.item.subbrowseId}
              onChange={v => this.update("subbrowseId", v)}
            >
            {this.props.composeTypeList.map(e => {
              return (<Option value={e.subbrowseId} label={e.subbrowseId}>
                  {e.subbrowseName}
                </Option>
              )
            })}
            </Select>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-outline-secondary btn-sm button-blank"
            onClick={this.close}
          > {langCommon.actions["cancel"]}
          </button>

          <button className="btn btn-primary btn-sm btn-blue button-blue"
            onClick={this.handleSubmit.bind(this)}
          > {langCommon.actions["confirm"]}
          </button>
        </Modal.Footer>
      </Modal>
    </>)
  }
}

export default Push2Company;
