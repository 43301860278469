let records = {
  sideNav: "Account Management",
  tableTitle: "Accounts",
  role: "Role",
  "引擎开发": "Software Developer",
  "引擎美术": "3D Artist",
  "引擎测试": "Test Engineer",
  "交付工程师": "Delivery Engineer",
  accountType: "Account type",
  editAccount: "Edit Account",
  createAccount: "Create Account",
  wrongPhoneOrEmail: "wrong phone or email",
  wrongDateRange: "wrong date range",
  editYes: "Edit",
  editNo: "Read",
  tipDelete: "Are you sure you want to permanently delete this account: %1$s?",
  permissions: "Permissions",
  tipEnterName: "please enter name",
  tipNameLength: "name length exceeds 30",
  tipInvalidPhone: "invalid phone number",
  tipInvalidEmail: "invalid email address",
  tipEnterPhoneOrEmail: "please enter phone number or email address",
  tipInvalidPassword: "password can only contain 0-9, a-z, A-Z, length range is 6-20",
  tipPasswordNotMatch: "passwords doesn't match",
  tipEnterRole: "please enter role",
  passwordConfirmation: "Password confirmation",
};

export default records;
