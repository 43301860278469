let records = {
  sideNav: "Product Information",
  tableTitle: "Products",
  tipHasNoAreas: "%1$s doesn't have areas",
  tipHasNoMaterials: "%1$s doesn't have alternative materials",
  "软装": "Decoration",
  "主材": "Fixtures",
  tipProductExists: "product already exists",
  relatedProduct: "Related product",
  tipDelete: "Are you sure you want to permanently delete this product: %1$s?",
  brand: "Brand",
  style: "Style",
  origin: "Origin",
  price: "Price",
  currency: "Currency",
  model: "Model",
  mainMaterial: "Main material",
  unit: "Unit",
  lossPerc: "Loss(%)",
  purchaseURL: "Purchase URL",
  materialAreas: "Material Area",
  tipMaterialReplace: "Material replacement (select material IDs for different areas)",
  tipProductInfo: "Model product information (information is displayed in the App)",
  unitsMap: {"个":"pcs", "棵":"pcs", "片":"pcs", "组": "sets", "m":"m", "㎡":"sq.m", "㎥":"㎥"},
  editProduct: "Edit product",
};

export default records;
