let records = {
  sideNav: "模型维护",
  tableTitle: "模型列表",
  tipCreatePackage: "确认创建批量打包?",
  changeStatus: "修改状态",
  tipChangeStatusOfSelectedAsset: "将 %1s 选中模型的状态由 %2$s 为:",
  tipChangeStatusOfAsset: "确定修改 %1$s 状态为 %2$s 吗?",
  areaIndx: "区域 index",
  areaName: "Area 名称",
  variantId: "可替换材质 ID",
  variant: "材质替换项",
  tip1: "在软件前端可点击替换",
  tip2: "模型呈现在模型库导航中的的选中类目下",
  tipSelectCategory: "请选择模型分类",
  tipInvalidAssetName: "模型名称不合法",
  editAsset: "编辑模型",
  assetBasics: "模型基本信息",
  assetBasicsDetail: "创建模型所需的基本信息",
  browseNode: "类目 1",
  tipEnterAssetInfo: "请完善模型信息",
  createAsset: "新建模型",
  package: "打包",
  tipDelete: "删除 %1$s 将无法恢复，确认要删除该模型吗?",
};

export default records;
