import { message } from "antd";

let ADDRESS = "";
let StaticAddr = "";
let initialized = false;
const RELOGIN_HttpStatusCode = 303; // http.StatusSeeOther, RFC 7231, 6.4.4
let _DefaultHeaders = { "X-Language": "" };

// const RELOGIN_ResCode = -100;
/* doc
  https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
  https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
*/
function setItem(name, value) {
  localStorage.setItem(name, value);
}

function getItem(name) {
  return localStorage.getItem(name);
}

function clearData() {
  localStorage.clear();
}

if (!initialized) {
    ADDRESS = process.env.REACT_APP_APIAddress;
    StaticAddr = process.env.REACT_APP_StaticAddr;
    console.log(`>>> Running in "${process.env.REACT_APP_ENV}", API address is ${ADDRESS}`);
    initialized = true;
}

export function getStaticAddr() { return StaticAddr; }

export function getUserId() { return getItem("user/id"); }
export function getUserTel() { return getItem("user/tel"); }
export function getUserEmail() { return getItem("user/email"); }
export function getUserName() { return getItem("user/name"); }
export function getUserLevel () { return getItem("user/level"); }
export function getUserRoles () { return getItem("user/roles"); }
export function getCompanyId () { return getItem("user/companyId"); }

export function setHeader(key, value) {
  if (key) {
    _DefaultHeaders[key] = value;
  }
}

//!! backend 返回的 json 数据 code, message, data 为必须包含字段
export function httpPOST(path, data=null, callback=null, onError=null) {
  let options = {method: "POST", headers: {..._DefaultHeaders}};
  options.headers["Content-Type"] = "application/json";

  if (data) options.body = JSON.stringify(data);

  request(path, options, callback, onError);
}

export function httpGET(path, data=null, callback=null, onError=null) {
  let options = {method: "GET", headers: {..._DefaultHeaders}};

  if (data) {
    let arr = [];
    for (const [key, value] of Object.entries(data)) {
      if (value) arr.push(`${key}=${value}`);
    }
    if (arr.length > 0) path += `?${arr.join("&")}`;
  }

  request(path, options, callback, onError);
}

export function reLogin(msg) {
  clearData();
  if (msg) message.warn(msg);

  window.location.href = `${process.env.PUBLIC_URL || ''}/login`;
}

export function newPath(p) { // p -> /path/to/...
  if (!p) {
    return `${process.env.PUBLIC_URL || ''}`;
  }
  return `${process.env.PUBLIC_URL || ''}${p}`;
}

export function redirectTo(p) { // p -> /path/to/...
  if (!p) {
    return;
  }
  window.location.href = newPath(p);
}

// onError 为发生异常情况时执行函数
function request(path, options, callback=null, onError=null) {
  let token = getItem("token");
  if (token) options.headers["Authorization"] = `Bearer ${token}`;
  // let now = datetime();
  // options.headers["X-TZ-Offset"] = now.getTimezoneOffset();

  fetch(`${ADDRESS}${path}`, options)
    .then(response => {
      // if (response.length === 0) {
      //   return null;
      // }
      let contentType = response.headers.get("Content-Type");
      if (!contentType || !contentType.startsWith("application/json")) {
        throw new TypeError("接口请求失败");
        // return;
      }

      if (response.status === RELOGIN_HttpStatusCode) {
        throw new Error("reLogin", {"httpStatusCode": RELOGIN_HttpStatusCode});
      }

      return response.json();
    }).then(res => {
      if (!res) {
        throw new TypeError("接口返回数据为空");
        // return; // typeof(res) !== "undefined" && res !== null
      }

      /*
      //!! 用户已经登录的情况下, 但用户被设置为禁止登录、或解析 token 失败
      if (res.code === RELOGIN_ResCode) {
        throw new Error("reLogin", {"resCode": RELOGIN_ResCode});
      }
      */

      if (res.code !== 0 && onError) onError(); // onError 一般为状态解除锁定

      if (res.code < 0) {
        // 当 code < 0 时, 为参数不合法或者业务冲突, 直接 promote, 不需要 callback
        // alert(res.message);
        message.error(res.message);
        return;
      }

      if (res.code === 0 && options.method === "GET" && res.data.hasOwnProperty("items")) {
        if (Array.isArray(res.data.items) && res.data.items.length === 0) {
          message.warn("no items!");
        }
      }

      if (callback) callback(res); // res.code >= 0, 大于 0 时由业务层代码处理
    })
    .catch(function (err) {
      console.error(`!!! http${options.method} ${path}: ${err}`);
      if (onError) onError();

      if (err.message === "reLogin") {
        reLogin(""); // 如果设置提示，一个页面多个请求,会提示多次
      } else if (err instanceof TypeError && err.message.startsWith("NetworkError")) {
        // alert("NetworkError: 网络请求失败");
        message.error("NetworkError: request failed");
        return;
      } else if (err instanceof TypeError)  {
        // 接口请求失败, response Content-Type 不包含 application/json
        // alert(`TypeError: ${err.message}`);
        message.error(`TypeError: ${err.message}`);
        return;
      } else if (err instanceof SyntaxError)  {
        // response.json() 错误
        // alert(`SyntaxError: 接口返回数据错误`);
        message.error(`SyntaxError: invalid response data`);
        return;
      } else {
        // alert(`UnexpectedError: ${err}`);
        message.error(`UnexpectedError: ${err}`);
      }
    });
}

export function login(data, callback, onError=null) {
  httpPOST(
    "/api/web/v1/open/login", data, // 请求路由, post 数据
    res => {
      if (res.code > 0) {
        // alert("登录失败！");
        // alert(res.message);
        message.error(res.message);
        return;
      }

      setItem("user/loginAt", new Date());
      setItem("user/name", res.data.username);
      setItem("user/id", res.data.userId);
      setItem("user/tel", data.tel);
      setItem("user/email", data.Email);
      setItem("user/level", res.data.userLevel);
      setItem("user/roles", res.data.roles);
      setItem("user/companyId", res.data.companyId);
      setItem("token", res.data.token);
      callback(res);
    }, onError
  );
}

export function logout(callback) {
  httpPOST(
    "/api/web/v1/auth/user/logout", null,
    res => reLogin(""),
    () => reLogin(""),
  );
}
