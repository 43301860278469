let records = {
  sideNav: "My Assets",
  tableTitle: "Assets",
  tipAlterStatus: "Are you sure you want to change the status of %1$s to %2$s?",
  tipDelete: "Are you sure you want to permanently delete this asset: %1$s?",
  editAsset: "Edit assset",
  importAsset: "Import asset",
};

export default records;
