import OSS from "ali-oss/dist/aliyun-oss-sdk.js";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { message } from "antd";

import { getSet } from "locales/index.js";
import { getSts } from "js/api4public.js";

function aliyunOssPut(request, sts, callback) {
  let langCommon = getSet("common");

  let client = new OSS({
    accessKeyId: sts.accessKeyId,
    accessKeySecret: sts.accessKeySecret,
    region: "oss-" + sts.regionId,
    bucket: sts.bucket,
    secure: true,
    stsToken: sts.securityToken,
  });

  client.put(request.key, request.target).then(res => {
    // console.log(`~~ aliyunOssPut: ${JSON.stringify(res)}`);
    callback(res.url);
  }).catch(err => {
    message.error(langCommon.tips["failedToUpload"]);
    console.log(`!!! aliyunOssPut: ${err}`);
  });
}

function awsS3Put(request, sts, callback) {
  let langCommon = getSet("common");

  let client = new S3Client({
    region: sts.regionId,
    credentials: {
      accessKeyId: sts.accessKeyId,
      secretAccessKey: sts.accessKeySecret,
      sessionToken: sts.securityToken,
    },
  });

  let command = new PutObjectCommand({
    Bucket: sts.bucket,
    Key: request.key,
    Body: request.target,
  });

  client.send(command).then(res => {
    // console.log(`~~ awsS3Put: ${JSON.stringify(res)}`);
    let link = `https://${sts.bucket}.s3.${sts.regionId}.amazonaws.com/${request.key}`;
    callback(link);
  }).catch(err => {
    message.error(langCommon.tips["failedToUpload"]);
    console.log(`!!! awsS3Put: ${err}`);
  });
}

function putObject(kind, key, target, callback) {
  let langCommon = getSet("common");

  getSts({kind: kind, key: key}, res => {
    if (res.code !== 0) {
      message.error(langCommon.tips.errGetSts);
      return;
    }

    let request = {kind, key, target};
    let {provider, sts} = res.data;

    if (provider === "aliyun_oss") {
      aliyunOssPut(request, sts, callback);
    } else if (provider === "aws_s3") {
      awsS3Put(request, sts, callback);
    } else {
      message.error(`unknown sts provider: ${provider}`);
    }
  });
}

export default putObject;
